<ng-container *ngIf="isOpenLoginModal">
  <div class="l-modal">
    <div class="c-modal">
      <div class="c-modal__login">
        <div class="p-signup">
          <div class="p-signup__head">
            <div class="p-signup__head__kv"></div>
          </div>
          <div class="p-signup__main">
            <div class="p-signup__main__title" translate>
              ログイン
            </div>
            <div class="p-signup__main__form">
              <div class="p-signup__main__form__input">
                <label>
                  <span translate>メールアドレス</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [formControl]="email"
                  type="text"
                  name="email"
                  id="email"
                  [placeholder]="'メールアドレス' | translate"
                >
              </div>
              <div class="p-signup__main__form__input">
                <label>
                  <span translate>パスワード</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  [formControl]="password"
                  name="password"
                  id="password"
                  [placeholder]="'パスワード6文字以上' | translate"
                >
                <div
                  class="p-signup__main__form__input__password"
                  [ngClass]="showPassword ? 'p-signup__main__form__input__password-hide':'p-signup__main__form__input__password-show'"
                  (click)="showPasswordToggle()">
                </div>
              </div>
              <div class="c-form-error" *ngIf="showError" translate>メールアドレスもしくはパスワード一致していない可能性があります。またはユーザー登録後に送信されるメールアドレスの確認が完了していません。</div>
            </div>
            <div class="p-signup__main__buttons">
              <div class="p-signup__main__buttons__inner">
                <div class="p-signup__main__buttons__inner__button">
                  <button type="button" class="c-button c-button-login" (click)="onSubmit()"><span translate>ログイン</span></button>
                </div>
                <div class="p-signup__main__link" routerLink="/password/reset" translate>
                  パスワードを忘れてしまった方はこちら
                </div>
              </div>
              <div *ngIf="enableSso" class="p-signup__main__buttons__inner">
                <div class="p-signup__main__buttons__inner__button">
                  <button type="button" class="c-button c-button-login-ms" routerLink="/login-by-entra"><span translate>Microsoft Entra IDでログイン</span></button>
                </div>
                <!--<div class="p-signup__main__notes">
                  <p translate>説明文テキスト説明文テキスト説明文テキスト</p>
                </div>-->
              </div>
              <div class="p-signup__main__buttons__inner">
                <div class="p-signup__main__buttons__inner__button">
                  <button type="button" class="c-button c-button-signUp" routerLink="/signup"><span translate>メールアドレスで新規登録する</span></button>
                </div>
              </div>
            </div>
            <div class="p-signup__main__link" (click)="closeModal()" translate>閉じる</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
