import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-my-page-password',
  templateUrl: './my-page-password.component.html',
  styleUrls: ['./my-page-password.component.scss']
})
export class MyPagePasswordComponent implements OnInit {
  password = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    // Validators.pattern(new RegExp(/^[0-9a-zA-Z]*$/))
    // [ Validators.pattern('^0\\d{9,11}$')]);
  ]);
  passwordRe = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    // Validators.pattern(new RegExp(/^[0-9a-zA-Z]*$/))
    // [ Validators.pattern('^0\\d{9,11}$')]);
  ]);
  changePassword = this.builder.group({
    passwordRe: this.passwordRe,
    password: this.password,
  });
  constructor(
    private builder: FormBuilder,
    private toaster: ToastrService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private translateService: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
  }
  change(): void {
    if (this.changePassword.valid && this.password.value === this.passwordRe.value) {
      this.uiStore.dispatch(switchTrueLoading());
      this.authService.changePassword(this.password.value)
        .then(() => {
          this.toaster.success(this.translateService.instant('パスワードを変更しました。'));
          this.password.setValue('');
          this.passwordRe.setValue('');
          this.uiStore.dispatch(switchFalseLoading());
        })
        .catch((e) => {
          this.toaster.error(
            this.translateService.instant('ログイン後一定時間が経過するとパスワードの変更が出来ない場合があります。\nこの場合は一度ログアウト後、再度ログインすることでパスワードの変更が行えるようになります。'),
            '',
            {disableTimeOut: true}
          );
          this.uiStore.dispatch(switchFalseLoading());
          console.log(e);
        })
    } else {
      this.toaster.warning(this.translateService.instant('パスワードが異なっているか、パスワードの形式が正しくありません。パスワードは6文字以上の英数字を入力してください。'));
    }
  }
  back(): void {
    history.back();
  }
}
