<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>アカウントの切替</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">

          <div class="p-templates__content">
            <div class="p-templates__content__list">
              <h3 translate>所属アカウント一覧</h3>
              <div class="c-list">
                <div class="c-list__row c-list__row-head">
                  <div class="c-list__row__item c-list__row__item-normal" translate>会社名</div>
                  <div class="c-list__row__item c-list__row__item-narrow" translate>id</div>
                  <div class="c-list__row__item c-list__row__item-w200" translate>登録日</div>
                  <div class="c-list__row__button-switch" translate>切替</div>
                </div>
                <ng-container *ngFor="let a of accounts">
                  <div class="c-list__row">
                    <div class="c-list__row__item c-list__row__item-normal">{{a?.companyName}}</div>
                    <div class="c-list__row__item c-list__row__item-narrow">{{a?.id}}</div>
                    <div class="c-list__row__item c-list__row__item-w200">{{a?.createdAt | dateFormatFirestore}}</div>
                    <div class="c-list__row__button-switch">
                      <button
                        *ngIf="currentUser.currentAccountId !== a?.id"
                        (click)="switch(a)"
                      ><span translate>切り替える</span></button>
                      <span *ngIf="currentUser.currentAccountId === a?.id" translate>使用中</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="p-templates__content">
            <div class="p-templates__content__list">
              <h3 translate>招待されたアカウント一覧</h3>
              <div class="c-list">
                <div class="c-list__row c-list__row-head">
                  <div class="c-list__row__item c-list__row__item-normal" translate>会社名</div>
                  <div class="c-list__row__item c-list__row__item-w200" translate>登録日</div>
                  <div class="c-list__row__button-switch" translate>承認</div>
                </div>
                <ng-container *ngFor="let a of invitedAccounts">
                  <div class="c-list__row">
                    <div class="c-list__row__item c-list__row__item-normal">{{a.companyName}}</div>
                    <div class="c-list__row__item c-list__row__item-w200">{{a.createdAt | dateFormatFirestore}}</div>
                    <div class="c-list__row__button-switch">
                      <button
                        (click)="join(a )"
                      ><span translate>招待を承認する</span></button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
