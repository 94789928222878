import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from "../../models/user";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BackendService} from "../../services/backend/backend.service";
import {Account} from "../../models/account";
import {Channel} from "../../models/channel";
import {CoursesService} from "../../services/courses/courses.service";
import {emailRegisterCompleteForOem, EmailSender, emailSignatureOem} from "../../models/emails";
import {environment} from "../../../environments/environment";
import {nanoid} from "nanoid";
import {AuthService} from "../../services/auth/auth.service";
import {counters} from "../../models/counter";
import {CountersService} from "../../services/counters/counters.service";
import {Chat, ChatThread, ChatWays, Content, ContentTypes} from "../../models/chat";
import {Unread, unreadCategories} from "../../models/unread";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-oem-user-modal',
  templateUrl: './add-oem-user-modal.component.html',
  styleUrls: ['./add-oem-user-modal.component.scss']
})
export class AddOemUserModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Input() account: Account;
  @Input() channel: Channel;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  // form
  familyName = new FormControl<string>('', {
    validators: [
      Validators.required,
    ]});
  familyNamePhonetic = new FormControl<string>('');
  givenName = new FormControl<string>('');
  givenNamPhonetic = new FormControl<string>('');
  companyName = new FormControl<string>('');
  branchName = new FormControl<string>('');
  divisionName = new FormControl<string>('');
  jobTitle = new FormControl<string>('');
  email = new FormControl<string>('', {
    validators: [
      Validators.required,
    ]});
  phoneNumber = new FormControl<string>('');
  formGroup = this.formBuilder.group({
    familyName: this.familyName,
    familyNamePhonetic: this.familyNamePhonetic,
    givenName: this.givenName,
    givenNamPhonetic: this.givenNamPhonetic,
    companyName: this.companyName,
    branchName: this.branchName,
    divisionName: this.divisionName,
    jobTitle: this.jobTitle,
    email: this.email,
    phoneNumber: this.phoneNumber,
  });
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private backendService: BackendService,
    private coursesService: CoursesService,
    private authService: AuthService,
    private counter: CountersService,
    private uiStore: Store<{ ui: UiState }>,
    private translateService: TranslateService,
  ) {}
  async ngOnInit(): Promise<void> {}
  resetForm(): void {
    this.familyName.setValue('');
    this.familyNamePhonetic.setValue('');
    this.givenName.setValue('');
    this.givenNamPhonetic.setValue('');
    this.companyName.setValue('');
    this.branchName.setValue('');
    this.divisionName.setValue('');
    this.jobTitle.setValue('');
    this.email.setValue('');
    this.phoneNumber.setValue('');
  }
  close(): void {
    if (confirm(this.translateService.instant('閉じてもよろしいですか？入力内容は破棄されます。'))) {
      this.isOpen = false;
      this.isOpenModalEmitter.emit(this.isOpen);
      this.resetForm();
    }
  }
  async save(): Promise<void> {
    if (!confirm(this.translateService.instant('登録を実行します。よろしいですか？'))) {
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    if (this.formGroup.invalid) {
      this.toasterService.warning(this.translateService.instant('お名前（漢字）姓とメールアドレスは必須です。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (!this.account.email || !this.account.emailSignature) {
      this.toasterService.warning(this.translateService.instant('アカウント情報ページでシステム設定＞「送信元メールアドレス」と「送信メール署名」を設定してください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const user = new User();
    user.isOem = true;
    user.oemAccountId = this.account.id;
    user.familyName = this.familyName.value;
    user.familyNamePhonetic = this.familyNamePhonetic.value;
    user.givenName = this.givenName.value;
    user.givenNamPhonetic = this.givenNamPhonetic.value;
    user.companyName = this.companyName.value;
    user.branchName = this.branchName.value;
    user.divisionName = this.divisionName.value;
    user.jobTitle = this.jobTitle.value;
    user.phoneNumber = this.phoneNumber.value;
    user.email = this.email.value;
    user.fullName = this.familyName.value + ' ' + this.givenName.value;
    await this.coursesService.fetchByChannelId(this.channel.id);
    const courses = this.coursesService.fetched;
    const courseIds: string[] = [];
    courses.map((c => {
      courseIds.push(c.id);
    }));
    const emailSenders: EmailSender[] = [];
    const defaultTemplate: string = emailRegisterCompleteForOem.body + emailSignatureOem.body;
    let subject = emailRegisterCompleteForOem.subject.replace('__%channel_name%__', this.channel.name);
    let body = defaultTemplate.replace('__%user_name%__', user.fullName);
    body = body.replace(/__%channel_name%__/, this.channel.name);
    body = body.replace(/__%url%__/, 'https://' + environment.hostName + '/channel/' +this.channel.slug);
    body = body.replace(/__%email%__/, user.email);
    body = body.replace(/__%oem_signature%__/, this.account.emailSignature);
    const sender = new EmailSender();
    sender.email = this.email.value;
    sender.body = body;
    sender.subject = subject;
    sender.customerId = nanoid();
    sender.fromAddress = this.account.email;
    sender.fromName = this.channel.name;
    emailSenders.push(sender);
    const data = {
      accountId: this.account.id,
      channelId: this.channel.id,
      courseIds: courseIds,
      user: user,
      emails: emailSenders,
    }
    this.backendService.setIdToken(this.authService.idToken);
    this.backendService.post('lms/channel-manager/add_user', data)
      .then((user) => {
        this.counter.inc(
          {
            accountId: this.account.id,
            countName: counters.joinChannelTerm,
            term: this.counter.formatter.getDateString(new Date()),
            addKey: this.channel.id,
            value: 1,
          }
        );
        this.counter.inc(
          {
            accountId: this.account.id,
            countName: counters.joinChannel,
            term: '',
            addKey: this.channel.id,
            value: 1,
          }
        );
        //　チャットスレッドを開始する
        const chat = new Chat(ChatWays.system);
        chat.content = new Content(ContentTypes.text);
        chat.accountId = this.channel.accountId;
        chat.channelId = this.channel.id;
        chat.uid = user.uid;
        chat.content.body = `チャンネル「${this.channel.name}」に登録しました。` ;
        chat.createdAt = new Date();
        chat.timestamp = chat.createdAt.getTime();
        const thread = new ChatThread();
        thread.accountId = this.channel.accountId;
        thread.channelId = this.channel.id;
        thread.courseId = null;
        thread.uid = user.uid;
        thread.body = chat.content.body;
        thread.postedAt = chat.createdAt;
        const unread = new Unread();
        unread.category = unreadCategories.chat;
        unread.accountId = this.channel.accountId;
        unread.channelId = this.channel.id;
        unread.uid = user.uid;
        unread.body = `${this.account.companyName}さんが${chat.content.body}`;
        unread.createdAt = chat.createdAt;
        const postData = {
          chat: chat,
          unread: unread,
          thread: thread,
        };
        this.backendService.post(
          'lms/chats/post_to_user',
          postData
        ).catch();
        this.resetForm();
        this.toasterService.success(this.translateService.instant('登録しました。対象ユーザーにログイン情報がEメールで送信しました。'));
        this.isOpenModalEmitter.emit(false);
        this.isOpen = false;
      })
      .catch((e: { error?: string }) => {
        console.log(e);
        this.toasterService.error(e?.error);
        this.toasterService.warning(this.translateService.instant('登録処理に失敗しました。'));
        this.uiStore.dispatch(switchFalseLoading());
      })
  }
}
