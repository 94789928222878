import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AuthService} from '../services/auth/auth.service';
import {map} from 'rxjs/operators';
import {AppConfigService} from "../services/app-config/app-config.service";
import {SessionState} from "../store/sessions/sessions.reducer";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  sessionsSubscription: Subscription;
  constructor(
    private authService: AuthService,
    private appConfigService: AppConfigService,
    private router: Router,
  ){}
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    return this.authService
      .checkLoginState()
      .pipe(
        map(  (auth) => {
          if (!auth) {
            console.log('not login');
            this.router.navigate(['/']);
            return false;
          }
          if (auth.isAnonymous) {
            console.log('isAnonymous');
            this.router.navigate(['/']);
            return false;
          }
          // ログインOK
          return true;
        })
      );
  }
}
