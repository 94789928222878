import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {PostalCode} from "../../models/postal-code";

@Injectable({
  providedIn: 'root'
})
export class PostalCodesService {
  apiUrl: string
  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.apiHost
    this.setAppId(environment.appId);
    this.setAppNameSpace(environment.appNameSpace);
    this.setAppToken(environment.appApiToken);
  }
  public httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  async fetch(postalCode: string): Promise<any> {
    return this.http.get(this.apiUrl + 'addresses/postal-codes/fetch?postalCode=' + postalCode, this.httpOptions)
      .toPromise()
      .then((res) => {
        const postalCodes = res as unknown as { postalCode: string, addresses: PostalCode[] };
        return postalCodes.addresses;
      })
      .catch((e) => {
        throw  e;
      });
  }
  public setAppId(appId: string = null): void {
    if (!appId) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appId', appId);
  }
  public setAppNameSpace(appNameSpace: string = null): void {
    if (!appNameSpace) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appNameSpace', appNameSpace);
  }
  public setAppToken(token: string = null): void {
    if (!token) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appToken', token);
  }
}
