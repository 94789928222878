import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BackendService {
  apiUrl: string
  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.apiHost
    this.setAppId(environment.appId);
    this.setAppNameSpace(environment.appNameSpace);
    this.setAppToken(environment.appApiToken);
  }
  public httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private httpOptionsDownload: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    responseType: 'blob'
  };
  public httpOptionsCredentials: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': environment.hostName,
    }),
    withCredentials: true,
  };
  public httpOptionsAuth: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: null
  };
  public httpOptionsApi: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    withCredentials: false,
  };
  public httpOptionData: any = {
    headers: new HttpHeaders(),
  }
  public httpOptionsForm: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
  };
  async getFullUrl(url: string, options = this.httpOptions): Promise<any> {
    return this.http.get( url, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async get(url: string, options = this.httpOptions): Promise<any> {
    return this.http.get(this.apiUrl + url, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async post(url: string, data: any, options = this.httpOptions): Promise<any> {
    return this.http.post(this.apiUrl + url, data, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async download(url: string, data: any, options = this.httpOptionsDownload): Promise<any> {
    return this.http.post(this.apiUrl + url, data, options)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async getDownload(url: string): Promise<any> {
    return this.http.get(url)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async postForm(url: string, data: any, options = this.httpOptionsForm): Promise<any> {
    return this.http.post(this.apiUrl + url, data, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async put(url: string, data: any, options = this.httpOptions): Promise<any> {
    return this.http.put(this.apiUrl + url, data, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async delete(url: string, data: any, options = this.httpOptions): Promise<any> {
    return this.http.delete(this.apiUrl + url, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  public setUid(uid: string = null): void {
    if (!uid) {
      return;
    }
    this.httpOptionsApi.headers = this.httpOptionsApi.headers.set('x-uid', uid);
  }
  public setIdToken(token: string = null): void {
    if (!token) {
      return;
    }
    const bearerToken: any = `${token}`;
    this.httpOptions.headers = this.httpOptions.headers.set('idToken', bearerToken);
    this.httpOptionsForm.headers = this.httpOptions.headers.set('idToken', bearerToken);
    this.httpOptionsAuth.headers = this.httpOptions.headers.set('idToken', bearerToken);
  }
  public setAppId(appId: string = null): void {
    if (!appId) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appId', appId);
    this.httpOptionsForm.headers = this.httpOptions.headers.set('x-appId', appId);
    this.httpOptionsAuth.headers = this.httpOptions.headers.set('x-appId', appId);
    this.httpOptionData.headers = this.httpOptions.headers.set('x-appId', appId);
  }
  public setAppNameSpace(appNameSpace: string = null): void {
    if (!appNameSpace) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appNameSpace', appNameSpace);
    this.httpOptionsForm.headers = this.httpOptions.headers.set('x-appNameSpace', appNameSpace);
    this.httpOptionsAuth.headers = this.httpOptions.headers.set('x-appNameSpace', appNameSpace);
    this.httpOptionData.headers = this.httpOptions.headers.set('x-appNameSpace', appNameSpace);

  }
  public setAppToken(token: string = null): void {
    if (!token) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appToken', token);
    this.httpOptionsForm.headers = this.httpOptions.headers.set('x-appToken', token);
    this.httpOptionsAuth.headers = this.httpOptions.headers.set('x-appToken', token);
    this.httpOptionData.headers = this.httpOptions.headers.set('x-appToken', token);
    this.httpOptionsDownload.headers = this.httpOptions.headers.set('x-appToken', token);
  }
  public setContentType(contentType: string = null): void {
    if (!contentType) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('Content-Type', contentType);
    this.httpOptionData.headers = this.httpOptions.headers.set('Content-Type', contentType);
  }
  public removeContentType(): void {
    this.httpOptions.headers = this.httpOptions.headers.delete('Content-Type');
    this.httpOptionData.headers = this.httpOptions.headers.delete('Content-Type');
  }
  public removeAuthorization(): void {
    this.httpOptions.headers = this.httpOptions.headers.delete('Authorization');
  }
}
