<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="l-signup">
      <div class="p-signup">
        <div class="p-signup__head">
          <div class="p-signup__head__kv"></div>
        </div>
        <div class="p-signup__main">
          <div class="p-signup__main__title" translate>メールアドレスの有効性確認</div>
          <ng-container *ngIf="!send">
            <div class="p-signup__main__buttons">
              <div class="p-signup__main__buttons__inner">
                <div class="p-signup__main__buttons__inner__button">
                  <button
                    type="button"
                    class="c-button c-button-login"
                    (click)="onSubmit()"
                  ><span translate>有効性確認メールを送信する</span></button>
                </div>
              </div>
            </div>
            <div class="p-signup__main__notes">
              <p translate>「有効性確認メールを送信する」ボタンを押すと、ご登録されたメールアドレス宛に認証リンクURLのご案内メールが送信されます。</p>
              <p translate>「fire-rocket.jp」ドメインより届きます。 メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
            </div>
          </ng-container>
          <ng-container *ngIf="send">
            <div class="p-signup__main__notes">
              <p translate>ご登録されたメールアドレス宛に認証リンクURLのご案内メールが送信されました。</p>
              <p translate>「fire-rocket.jp」ドメインより届きます。 メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
