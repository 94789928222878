<app-header-contents-public></app-header-contents-public>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-contracts">
      <div class="p-contracts__title">
        <h2 class="p-contracts__title__main" translate>特定商取引法に基づく表記</h2>
        <div class="p-contracts__title__sub" translate>
          「特定商取引に関する法律」第11条（通信販売についての広告）に基づき以下に明示いたします。
        </div>
      </div>

      <h4 translate>販売業者名</h4>
      <p translate>株式会社グラブデザイン</p><br>
      <h4 translate>代表責任者名</h4>
      <p translate>梅原卓也</p><br>
      <h4 translate>所在地</h4>
      <p translate>〒102-0073　東京都千代田区九段北1丁目2-1</p><br>
      <h4 translate>電話番号</h4>
      <p>03-6825-3901</p><br>
      <h4 translate>電話受付時間</h4>
      <p translate>平日10：00～17：00（土日祝日、GW、年末年始を除く）</p><br>
      <h4 translate>メールアドレス</h4>
      <p>info&#64;grab-design.com</p><br>
      <h4 translate>ホームページURL</h4>
      <p>https://www.grab-design.com/</p><br>
      <h4 translate>販売価格</h4>
      <p translate>各コース詳細ページをご参照ください。</p><br>
      <h4 translate>商品代金以外の必要料金</h4>
      <p translate>消費税</p><br>
      <h4 translate>お届け時期</h4>
      <p translate>入金確認後、3営業日以内にアクセスＵＲＬとＩＤを発行いたします。</p><br>
      <h4 translate>お支払方法</h4>
      <p translate>クレジットカード決済</p><br>
      <h4 translate>返品・交換・ キャンセル等</h4>
      <p translate>eラーニングのコース受講のキャンセルは、受講料のご入金前まで受付けます。また、当社のeラーニングは、特定商取引法に基づくクーリングオフ制度の適用対象となっておりません。ご入金後のキャンセル・返金はお受付できませんのでご注意ください。</p><br><br>
      <h4 translate>動作環境</h4>
      <p>
        <span translate>当社のeラーニングは当社が開発運用する学習プラットフォーム「FireRocket（ファイアロケット）」を使用いたします。</span><br>
        <span translate>FireRocket動作環境は次の通りです。</span><br><br>
        <span translate>ブラウザ　Microsoft Edge(最新版)， FireFox(最新版)、Chrome(最新版),Safari(最新版)</span><br>
        <span translate>※1 Android は機種が非常に多いため完全な動作保証はしておりません。動作に不具合が発見 された場合は調査し、対応可能な範囲で対応する方針としております。</span><br>
        <span translate>※2 動作環境外の OS・ブラウザの場合でも、サイト自体は基本的に見ることができますが、 一部の機能が利用できない・レイアウトのずれ等が発生する場合がございます。</span><br>
        <span translate>※3 ブラウザの JavaScript、Cookie、SSL の設定が有効である必要があります。</span><br>
        <span translate>※4 セキュリティソフトウェアまたは、アンチウイルスソフトウェアのセキュリティ機能によ って当サービスの機能が正しく利用出来ない場合があります。</span><br>
        <span translate>※5 e ラーニング講座を受講する場合は、e ラーニング講座とサイトの動作環境が異なる場合 がございます。</span></p>
    </div>
  </div>
</div>
<app-footer></app-footer>
