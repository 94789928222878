<app-forbidden *ngIf="forbidden"></app-forbidden>
<div class="l-main" *ngIf="!forbidden">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>アカウント所属ユーザー一覧</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-templates__content__createButton">
              <button
                (click)="openInviteModal()"
                class="c-button c-button-create"
              ><span translate>ユーザーを招待する</span></button>
            </div>
            <div class="p-templates__content__list">
              <div class="c-list">
                <div class="c-list__row c-list__row-head">
                  <div class="c-list__row__item c-list__row__item-normal" translate>ニックネーム・名前</div>
                  <div class="c-list__row__item c-list__row__item-normal" translate>メールアドレス</div>
                  <div class="c-list__row__item c-list__row__item-normal" translate>権限</div>
                  <div class="c-list__row__button-delete" translate>削除</div>
                </div>
                <ng-container *ngFor="let u of users; index as i">
                  <div class="c-list__row">
                    <div class="c-list__row__item c-list__row__item-normal">
                      {{u.displayName}}
                      <span
                        *ngIf="u.id === uid"
                        translate
                      >（ログイン中）</span>
                    </div>
                    <div class="c-list__row__item c-list__row__item-normal">{{u.email}}</div>
                    <div
                      class="c-list__row__item c-list__row__item-normal">
                      <select class="c-form-select"
                              *ngIf="u?.roles"
                              (change)="changeRole(u, i)"
                              [formControl]="roleName[i]"
                      >
                        <option
                          [selected]="u?.roles?.account?.roleName === roleNamesOptions.admin"
                          [value]="roleNamesOptions?.admin"
                          translate
                        >アドミニストレーター</option>
                        <option
                          [selected]="u?.roles?.account?.roleName === roleNamesOptions.manager"
                          [value]="roleNamesOptions?.manager"
                          translate
                        >マネジャー</option>
                        <option
                          [selected]="u?.roles?.account?.roleName === roleNamesOptions.creator"
                          [value]="roleNamesOptions?.creator"
                          translate
                        >クリエイター</option>
                        <option
                          [selected]="u?.roles?.account?.roleName === roleNamesOptions.educator"
                          [value]="roleNamesOptions?.educator"
                          translate
                        >エデュケーター</option>
                        <option
                          [selected]="u?.roles?.account?.roleName === roleNamesOptions.viewer"
                          [value]="roleNamesOptions?.viewer"
                          translate
                        >ビュワー</option>
                        <option
                          [selected]="u?.roles?.account?.roleName === roleNamesOptions.deny"
                          [value]="roleNamesOptions?.deny"
                          translate
                        >権限なし</option>
                      </select>
                    </div>
                    <div class="c-list__row__button-delete">
                      <button
                        (click)="remove(u)"
                      ><span translate>削除</span></button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="l-modal" *ngIf="isOpenInvitationModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>
        招待するユーザー
      </div>
      <div class="c-modal__head__closeButton" (click)="closeInviteModal()"></div>
    </div>
    <div class="c-modal__main">
        <div class="c-modal__main__form">
          <div class="c-modal__main__form__input">
            <label class="c-form-label" for="email">
              <span translate>招待するユーザーのメールアドレス</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <input
              [formControl]="email"
              class="c-form-input" type="text" name="slideTitle" id="email" value="">
            <div
              *ngIf="email.invalid"
              class="c-form-error"
              translate
            >メールアドレスを入力してください。</div>
          </div>
        </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__status"></div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button
              (click)="closeInviteModal()"
              class="c-button c-button-cancel"><span translate>キャンセル</span></button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button
              (click)="invite()"
              class="c-button c-button-primary"><span translate>招待する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>　
</section>
