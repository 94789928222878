import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {categories} from "../../models/categories";
import {Channel} from "../../models/channel";
import {ChannelsService} from "../../services/channels/channels.service";
import {AuthService} from "../../services/auth/auth.service";
import {getHeaderLogoSrc, getIsNotFoundState, getLoaderState, UiState} from "../../store/ui/ui.reducer";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {Account, AccountType} from "../../models/account";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Router} from "@angular/router";
import {User} from "../../models/user";
import {storeHeaderLogoSrc} from "../../store/ui/ui.actions";
import {CurrentUser, getIsAnonymous, SessionState, setSession} from "../../store/sessions/sessions.reducer";
import {FormControl} from "@angular/forms";
import {LanguagesService} from "../../services/languages/languages.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header-contents-channel',
  templateUrl: './header-contents-channel.component.html',
  styleUrls: ['./header-contents-channel.component.scss']
})
export class HeaderContentsChannelComponent implements OnInit, OnDestroy {
  private loadingSubscription: Subscription;
  private headerSubscription: Subscription;
  private isNotFoundSubscription: Subscription;
  private isAnonymousSubscription: Subscription;
  private sessionsSubscription: Subscription;
  isAnonymous = false;
  isShowHeader = true;
  isOpenSearchModal = false;
  isOpenLoginModal = false;
  user: User;
  protected readonly categories = categories;
  listOfChannels: Channel[] = [];
  src: string;
  isNotFoundState: boolean;
  ownerAccount: Account;
  isOem: boolean;
  displayName: string = '';
  protected readonly AccountType = AccountType;
  currentSession: SessionState | undefined = undefined;
  currentUser: CurrentUser = new CurrentUser();
  languages = new FormControl<'ja' | 'en'>(this.languagesService.getDefaultLanguage());
  @Output() currentLanguageEmitter = new EventEmitter<'ja' | 'en'>();
  constructor(
    private channelsService: ChannelsService,
    private authService: AuthService,
    private accountsService: AccountsService,
    private uiStore: Store<{ ui: UiState}>,
    private sessionStore: Store<{ session: UiState}>,
    private router: Router,
    private languagesService: LanguagesService,
    protected translateService: TranslateService,
  ) { }
  ngOnDestroy(): void {
    this.isNotFoundSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
    this.isAnonymousSubscription?.unsubscribe();
    this.headerSubscription?.unsubscribe();
    this.sessionsSubscription?.unsubscribe();
  }
  async ngOnInit(): Promise<void> {
    this.listOfChannels = [];
    this.isNotFoundSubscription = this.uiStore.select(getIsNotFoundState).subscribe((isNotFoundState) => {
      this.isNotFoundState = isNotFoundState;
    });
    this.loadingSubscription = this.uiStore.select(getLoaderState).subscribe((loadingState) => {
      this.isShowHeader = !loadingState;
    });
    this.isAnonymousSubscription = this.uiStore.select(getIsAnonymous).subscribe((isAnonymous) => {
      this.isAnonymous = isAnonymous;
    });
    this.headerSubscription = this.uiStore.select(getHeaderLogoSrc).subscribe((headerLogoSrcState) => {
      if (headerLogoSrcState) {
        this.src = headerLogoSrcState;
      }
    });
    // ログイン状態取得
    this.sessionsSubscription = this.sessionStore
      .select(setSession)
      .pipe()
      .subscribe(async (sessionState) => {
        this.currentSession = sessionState;
        this.currentUser = sessionState?.currentUser;
        // 言語
        if (this.currentUser?.language) {
          this.languages.setValue(this.currentUser?.language);
          this.currentLanguageEmitter.emit(this.currentUser?.language);
        } else {
          this.languages.setValue(this.languagesService.getCurrentLanguage());
          this.currentLanguageEmitter.emit(this.languagesService.getCurrentLanguage());
        }
        // 表示名設定
        this.user = this.authService.user;
        if (this.user?.displayName) {
          this.displayName = this.user.displayName;
        } else if (this.user?.fullName) {
          this.displayName = this.user.fullName;
        } else if (this.user?.familyName) {
          this.displayName = this.user.familyName;
        } else {
          this.displayName = '';
        }
        // LOGO
        if (this.currentSession?.isAnonymous && !this.isNotFoundState) {
          this.uiStore.dispatch(storeHeaderLogoSrc(
            environment.productMainLogoSrc
          ));
          this.isOpenLoginModal = true;
        } else if (this.isNotFoundState) {
          this.uiStore.dispatch(storeHeaderLogoSrc(
            environment.productMainLogoSrc
          ));
        }
        // チャンネルリスト取得
        this.channelsService.fetched = [];
        this.listOfChannels = [];
        if (this.currentUser?.currentAccountId) {
          await this.channelsService.fetchByAccountId(this.currentUser?.currentAccountId);
          this.channelsService.fetched.forEach(channel => {
            if (this.listOfChannels.findIndex(c => c.id === channel.id) === -1) {
              this.listOfChannels.push(channel);
            }
          })
        }
        // oem 版の対応
        if (this.user?.oemAccountId) {
          this.isOem = true;
          this.ownerAccount = await this.accountsService.fetchAccount(this.user?.oemAccountId);
          await this.channelsService.fetchByAccountId(this.user?.oemAccountId);
          this.listOfChannels = this.channelsService.fetched;
          this.uiStore.dispatch(storeHeaderLogoSrc(
            this.channelsService.getLogoImageUrl(this.ownerAccount.logoImage.src)
          ));
        } else {
          this.uiStore.dispatch(storeHeaderLogoSrc(
            environment.productMainLogoSrc
          ));
        }
      });
  }
  changeLoginModal(status: boolean): void {
    this.isOpenLoginModal = status;
  }
  changeSearchModal(status: boolean): void {
    this.isOpenSearchModal = !this.isOpenSearchModal;
  }
  getLogoStyle(): string {
    return `backgroundImage: url(${this.src}); width: 220px; height: 50px;`;
  }
  clickToTop(): void {
    if (this.ownerAccount?.accountType === AccountType.general) {
      this.router.navigate(['/']).catch();
      return;
    }
    if (this.listOfChannels.length > 0) {
      if (this.listOfChannels[0]?.isOem) {
        this.router.navigate([`/channel/${this.listOfChannels[0].slug}`]).catch();
        return;
      }
    }
    this.router.navigate(['/']).catch();
    return;
  }
  async changeLanguage(): Promise<void> {
    await this.languagesService.change(this.languages.value, this.user?.id);
    this.currentLanguageEmitter.emit(this.languages.value);
  }
}
