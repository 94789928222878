import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {SessionState} from "../../store/sessions/sessions.reducer";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {Channel} from "../../models/channel";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {CoursesService} from "../../services/courses/courses.service";
import {Course} from "../../models/course";
import {AccountsService} from "../../services/accounts/accounts.service";

@Component({
  selector: 'app-manager-channel-courses',
  templateUrl: './manager-channel-courses.component.html',
  styleUrls: ['./manager-channel-courses.component.scss']
})
export class ManagerChannelCoursesComponent implements OnInit, OnDestroy {
  private sessionsSubscription: Subscription;
  private channelSubscription: Subscription;
  forbidden: boolean = false;
  channelId: string = null;
  courseId: string = null;
  courses: Course[] = [];
  currentSession: SessionState | undefined;
  currentChannel: Channel = new Channel();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private accountService: AccountsService,
  ) {
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    urlState.courseId = this.courseId;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    if (this.courseId === 'all') {
      window.scrollTo(0, 0);
    }
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(
      storeUrlParams({ urlParams: [ this.channelId, this.courseId ]}
      ));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.user.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    await this.channelsService.connectSnapShot(this.channelId);
    this.channelSubscription = this.channelsService.currentChannel
      .pipe(
        map((channel: Channel) => channel)
      )
      .subscribe(async (channel) => {
        if (!channel.slug) {
          await this.router.navigate(['/manager/channels']);
          return;
        }
        this.currentChannel = channel;
      });
    this.coursesService.fetched = [];
    await this.coursesService.fetchByChannelIdAndAccountId(
      this.channelId,
      this.authService.user.currentAccountId,
    );
    this.courses = this.coursesService.fetched;
    this.uiStore.dispatch(switchFalseLoading());
  }
  ngOnDestroy(): void{
    this.sessionsSubscription?.unsubscribe();
    this.channelSubscription?.unsubscribe();
  }
  closeSubMenu(): void {
    // action-buttons-{{c.id}}
    // const elements = document.getElementsByClassName('c-card-courses__inner__right__buttons__opener');
    // const elementsArray = Array.from(elements);
    // console.log(elementsArray);
  }
}
