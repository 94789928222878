<ng-container *ngIf="showView">
  <div class="l-signup">
    <div class="p-signup">
      <div class="p-signup__head">
        <div routerLink="/" class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div class="p-signup__main__title" translate>シングルサインオン</div>
        <div class="p-signup__main__buttons">
          <div class="p-signup__main__buttons__inner">
            <div class="c-form-error" translate>
              Microsoft Entra IDを使ってログインします。
            </div>
            <div class="p-signup__main__buttons__inner__button">
              <button
                type="button"
                class="c-button c-button-login"
                (click)="login()"
              ><span translate>ログイン</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
