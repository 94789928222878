<app-forbidden *ngIf="forbidden"></app-forbidden>
<div *ngIf="account.id && !forbidden"
  class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>ビジネス情報編集</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__title" translate>事業者情報</div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="id" translate>アカウントID</label>
                  <input
                    readonly
                    id="id"
                    class="c-form-input"
                    [ngModel]="account?.id">
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="accountType" translate>契約種別</label>
                  <input
                    readonly
                    id="accountType"
                    class="c-form-input"
                    [ngModel]="account?.accountType">
                </div>
              </div>

              <ng-container *ngIf="account.accountType === accountType.oem">
                <div class="p-profile__row">
                  <label class="c-form-label" translate>ヘッダー用ロゴ画像（480px x 110px 固定）透過PNG推薦</label>
                  <div class="c-form-images">
                    <div class="c-form-images__image">
                      <img
                        *ngIf="!account?.logoImage?.src"
                        ngSrc="no-image"
                        width="240"
                        height="auto"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                      <div
                        *ngIf="account?.logoImage?.src"
                        (click)="removeLogoImage()"
                        class="c-form-images__image__remove"></div>
                      <img
                        *ngIf="account?.logoImage?.src"
                        ngSrc="{{account?.logoImage?.src}}"
                        width="480"
                        height="110"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                    </div>
                  </div>
                  <div
                    *ngIf="!account?.logoImage?.src"
                    class="c-form-error"
                    translate
                  >ロゴ画像をアップロードしてください。</div>
                  <div class="p-profile__row__buttons">
                    <div class="p-profile__row__buttons__button">
                      <button
                        *ngIf="!account?.logoImage?.src"
                        (click)="openLogoImageUploadModal()"
                        class="c-button c-button-primary"
                        id="eyeCatch"
                      ><spna translate>画像をアップロード</spna></button>
                      <button
                        *ngIf="account?.logoImage?.src"
                        (click)="removeLogoImage()"
                        class="c-button c-button-cancel"
                        type="button"
                      ><spna translate>ロゴ画像を削除する</spna></button>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="juridicalPersonality" translate>業種</label>
                  <select
                    [formControl]="typeOfBusiness"
                    class="c-form-input" name="typeOfBusiness" id="typeOfBusiness">
                    <option
                      *ngFor="let
                       option of typeOfBusinesses"
                      [value]="option.value"
                      translate
                    >{{option.name}}</option>
                  </select>
                  <p *ngIf="typeOfBusiness.invalid" class="c-form-error">選択してください。</p>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="juridicalPersonality" translate>法人種別</label>
                  <select
                    [formControl]="juridicalPersonality"
                    class="c-form-input" name="juridicalPersonality" id="juridicalPersonality">
                    <option
                      *ngFor="let option of juridicalPersonalities"
                      [value]="option.value"
                      translate
                    >{{option.name}}</option>
                  </select>
                  <p *ngIf="juridicalPersonality.invalid" class="c-form-error">選択してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="juridicalPersonalityPosition" translate>法人種別表示位置（個人事業主除く）</label>
                  <select
                    [formControl]="juridicalPersonalityPosition"
                    class="c-form-input" name="juridicalPersonalityPosition" id="juridicalPersonalityPosition">
                    <option
                      *ngFor="let option of juridicalPersonalityPositions"
                      [value]="option.value"
                      translate
                    >{{option.name}}</option>
                  </select>
                  <p *ngIf="juridicalPersonalityPosition.invalid" class="c-form-error">選択してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="companyName" translate>法人名/事業名</label>
                  <input
                    [formControl]="companyName"
                    class="c-form-input" type="text" name="companyName" id="companyName" placeholder="">
                  <p *ngIf="companyName.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="representativeTitle" translate>代表者役職名</label>
                  <input
                    [formControl]="representativeTitle"
                    class="c-form-input" type="text" name="representativeTitle" id="representativeTitle" placeholder="">
                  <p *ngIf="representativeTitle.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="representativeName" translate>代表者名</label>
                  <input
                    [formControl]="representativeName"
                    class="c-form-input" type="text" name="representativeName" id="representativeName" placeholder="">
                  <p *ngIf="representativeName.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="zip" translate>所在地 郵便番号</label>
                  <input
                    [formControl]="zip"
                    class="c-form-input" type="text" name="zip" id="zip" placeholder="">
                  <p *ngIf="zip.invalid" class="c-form-error">半角数字を入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="pref" translate>所在地 都道府県</label>
                  <input
                    [formControl]="pref"
                    class="c-form-input" type="text" name="pref" id="pref" placeholder="">
                  <p *ngIf="pref.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="address1" translate>所在地 市区町村</label>
                  <input
                    [formControl]="address1"
                    class="c-form-input" type="text" name="address1" id="address1" placeholder="">
                  <p *ngIf="address1.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="address2" translate>所在地 番地以降</label>
                  <input
                    [formControl]="address2"
                    class="c-form-input" type="text" name="address2" id="address2" placeholder="">
                  <p *ngIf="address2.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="address3" translate>所在地 建物名</label>
                  <input
                    [formControl]="address3"
                    class="c-form-input" type="text" name="address3" id="address3" placeholder="">
                  <p *ngIf="address3.invalid" class="c-form-error">エラー</p>
                </div>
              </div>
              <div class="p-profile__title" translate>連絡担当者情報</div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" translate>支店名</label>
                  <input
                    [formControl]="branchName"
                    class="c-form-input" type="text" name="branchName" placeholder="">
                  <p *ngIf="branchName.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="divisionName" translate>部署名</label>
                  <input
                    [formControl]="divisionName"
                    class="c-form-input" type="text" name="divisionName" id="divisionName" placeholder="">
                  <p *ngIf="divisionName.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="personInCharge" translate>担当者名</label>
                  <input
                    [formControl]="personInCharge"
                    class="c-form-input" type="text" name="personInCharge" id="personInCharge" placeholder="">
                  <p *ngIf="personInCharge.invalid" class="c-form-error">入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="personInChargePhonetic" translate>担当社名 ふりがな</label>
                  <input
                    [formControl]="personInChargePhonetic"
                    class="c-form-input" type="text" name="personInChargePhonetic" id="personInChargePhonetic" placeholder="">
                  <p *ngIf="personInChargePhonetic.invalid" class="c-form-error">全角のひらがなを入力してください。</p>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" translate>電話番号</label>
                  <input
                    [formControl]="phoneNumber"
                    class="c-form-input" type="text" name="name" placeholder="">
                  <p *ngIf="phoneNumber.invalid" class="c-form-error">半角数字を入力してください。</p>
                </div>
              </div>
              <ng-container *ngIf="account.accountType === accountType.general">
                <div class="p-profile__title" translate>銀行口座情報</div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" translate>銀行・金融機関コード（半角数字）</label>
                    <input
                      [formControl]="bankCode"
                      class="c-form-input" type="text" name="bankCode" placeholder="">
                    <p *ngIf="bankCode.invalid" class="c-form-error">半角数字を入力してください。</p>
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" translate>銀行・金融機関名（全角）</label>
                    <input
                      [formControl]="bankName"
                      class="c-form-input" type="text" name="bankName" placeholder="">
                    <p *ngIf="bankName.invalid" class="c-form-error">全角で入力してください。</p>
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" translate>支店コード（半角数字）</label>
                    <input
                      [formControl]="branchCode"
                      class="c-form-input" type="text" name="branchCode" placeholder="">
                    <p *ngIf="branchCode.invalid" class="c-form-error">半角数字を入力してください。</p>
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" translate>支店名（全角）</label>
                    <input
                      [formControl]="bankBranchName"
                      class="c-form-input" type="text" name="bankBranchName" placeholder="">
                    <p *ngIf="bankBranchName.invalid" class="c-form-error">全角で入力してください。</p>
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" for="bankType" translate>口座種別</label>
                    <select [formControl]="bankType" class="c-form-input" name="bankType" id="bankType">
                      <option value="" translate>選択してください</option>
                      <option [value]="BankAccountTypes.savings" translate>普通</option>
                      <option [value]="BankAccountTypes.checking" translate>当座</option>
                    </select>
                    <p *ngIf="bankType.invalid" class="c-form-error">選択してください。</p>
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" translate>口座番号（半角数字）</label>
                    <input
                      [formControl]="accountNumber"
                      class="c-form-input" type="text" name="accountNumber" placeholder="">
                    <p *ngIf="accountNumber.invalid" class="c-form-error">半角数字を入力してください。</p>
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" translate>口座名義（全角カナ）</label>
                    <input
                      [formControl]="transferTargetName"
                      class="c-form-input" type="text" name="transferTargetName" placeholder="">
                    <p *ngIf="transferTargetName.invalid" class="c-form-error">全角カタカナと全角記号「（）」が使用できます。</p>
                  </div>
                </div>
              </ng-container>

              <div class="p-profile__title" translate>システム設定</div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="email" translate>送信元メールアドレス</label>
                  <input
                    [formControl]="email"
                    class="c-form-input" type="text" name="email" id="email" placeholder="">
                  <p *ngIf="email.invalid" class="c-form-error">メールアドレスが正しいか確認してください。半角小文字で入力してください。</p>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="emailSignature" translate>送信メール署名</label>
                  <textarea
                    [formControl]="emailSignature"
                    class="c-form-textArea" type="text" name="emailSignature" id="emailSignature" placeholder=""></textarea>
                  <p *ngIf="emailSignature.invalid" class="c-form-error">error</p>
                </div>
              </div>


              <div class="p-profile__row">
                <label class="c-form-label" translate>管理画面のIPアドレス制限</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="protectByIpAddress"
                      [value]="true"
                      type="radio" name="published" id="published-true">
                    <label class="c-form-label" for="published-true" translate>制限をする</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="protectByIpAddress"
                      [value]="false"
                      type="radio" name="published" id="published-false">
                    <label class="c-form-label" for="published-false" translate>制限をしない</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="acceptedIpAddresses" translate>アクセスを許可するIPアドレス</label>
                  <textarea
                    [formControl]="acceptedIpAddresses"
                    class="c-form-textArea"
                    type="text"
                    name="acceptedIpAddresses"
                    id="acceptedIpAddresses"
                    [placeholder]="'IPアドレスを改行区切りで入力' | translate"
                  ></textarea>
                  <p *ngIf="acceptedIpAddresses.invalid" class="c-form-error">IPv4形式の半角数字と半角の.（ドット）を入力してください。</p>
                </div>
              </div>


              <div class="p-profile__row">
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="save()"
                      class="c-button c-button-primary"
                    ><span translate>保存する</span></button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="l-modal" *ngIf="isOpenLogoImageModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>ロゴ画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeLogoImageModalModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadLogoImage($event)"
        type="file" id="uploadLogoImage">
      <label
        for="uploadLogoImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadLogoImage')"
          type="button"
          class="c-button c-button-primary"
        ><span translate>アップロードする</span></button>
      </div>
    </div>
  </div>
</section>
