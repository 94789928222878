import { Injectable } from '@angular/core';
import {BackendService} from "../backend/backend.service";
import {environment} from "../../../environments/environment";
import {VideoUpload} from "../../models/video-upload";
import {FileUpload} from "../../models/file-upload";
import {VideoFrame} from "../../models/course-frames";
import {nanoid} from "nanoid";
import {PublicImage} from "../../models/public-image";
import {FileUploaderToStorageService} from "../file-uploader-to-storage/file-uploader-to-storage.service";
import {AuthService} from "../auth/auth.service";
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class VideoUploaderService {
  videoStatus: VideoUpload;
  fileSize: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  bytesTransferred: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor(
    private backendService: BackendService,
    private fileUploaderToStorageService: FileUploaderToStorageService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) { }
  async uploadVideo(
    anyId: string,
    accountId: string,
    dirName: string,
    file: File,
  ): Promise<VideoFrame>
  {
    const fileInfo = new FileUpload(file);
    this.fileSize.next(fileInfo.file.size);
    this.bytesTransferred.next(0);
    const processSubscription = this.fileUploaderToStorageService
      .process$
      .subscribe((process) => {
        this.bytesTransferred.next(process?.bytesTransferred);
      });
    const tmpPath = 'tmp';
    const tmpDestinationPath = `${tmpPath}/${fileInfo.file.name}`;
    await this.fileUploaderToStorageService.upload(
      tmpDestinationPath,
      fileInfo,
    );
    processSubscription.unsubscribe();
    const contentKey = nanoid();
    const newVideoFrame = new VideoFrame();
    newVideoFrame.eyeCatchImage = new PublicImage();
    newVideoFrame.eyeCatchImage.contentKey = nanoid();
    newVideoFrame.eyeCatchImage.src = `${environment.appId}/${accountId}/${anyId}/${contentKey}`;
    newVideoFrame.eyeCatchImage.uploadedBy = this.authService.uid;
    newVideoFrame.eyeCatchImage.uploadedAt = new Date;
    await this.convertRequest(
      accountId,
      this.authService.idToken,
      contentKey,
      fileInfo.file.name,
      tmpDestinationPath,
      `channel-videos/${accountId}/${anyId}/${dirName}/${contentKey}`,
      newVideoFrame.eyeCatchImage.widths,
      newVideoFrame.eyeCatchImage.ratio,
      environment.firebase.storageBucket,
      newVideoFrame.eyeCatchImage.src
  );
    console.log(this.videoStatus);
    newVideoFrame.contentKey = contentKey;
    newVideoFrame.name = fileInfo.file.name;
    newVideoFrame.description = '';
    newVideoFrame.m3u8ObjectName = this.videoStatus.m3u8ObjectName;
    newVideoFrame.duration = this.videoStatus.duration;
    newVideoFrame.numOfFragments = this.videoStatus.numOfFragments;
    newVideoFrame.width = this.videoStatus.width;
    newVideoFrame.height = this.videoStatus.height;
    newVideoFrame.note = '';
    newVideoFrame.createdAt = new Date();
    newVideoFrame.updatedAt = new Date();
    newVideoFrame.createdBy = this.authService.uid;
    newVideoFrame.updatedBy = this.authService.uid;
    return newVideoFrame;
  }
  async removeSampleVideo(
    anyId: string,
    accountId: string,
    videoFrame: VideoFrame

  ): Promise<void> {
    await this.removeVideo(
      accountId,
      this.authService.idToken,
      videoFrame,
      `channel-videos/${accountId}/${anyId}/samples/${videoFrame.contentKey}`,
    );
  }
  async convertRequest(
    accountId: string,
    idToken: string,
    contentKey: string,
    fileName: string,
    objectNamePath: string,
    destinationPath: string,
    widths: string[],
    ratio: string,
    tmbDestinationBucketName: string,
    tmbDestinationPath: string
  ): Promise<void> {
    this.videoStatus = new VideoUpload();
    this.backendService.setIdToken(idToken);
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      contentKey: contentKey,
      fileName: fileName,
      filePath: objectNamePath,
      destinationPath: destinationPath,
      widths: widths,
      ratio: ratio,
      tmbDestinationBucketName: tmbDestinationBucketName,
      tmbDestinationPath: tmbDestinationPath,
    }
    try {
      this.videoStatus = await this.backendService.post('converter/video/to_hls', data)
    } catch (e) {
      console.log(e);
      alert(this.translateService.instant('通信エラーが発生しました。画面を再読み込みします。'));
      location.reload();
    }
  }
  async removeVideo(
    accountId: string,
    idToken: string,
    frame: VideoFrame,
    storagePath: string,
  ): Promise<void> {
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      frame: frame,
      storagePath: storagePath,
    }
    return new Promise((async (resolve, reject) => {
      try {
        this.backendService.setIdToken(idToken);
        await this.backendService.post('converter/video/remove', data);
        resolve();
      } catch (e) {
        alert(this.translateService.instant('通信エラーが発生しました。画面を再読み込みします。'));
        location.reload();
        reject();
      }
    }));
  }
}
