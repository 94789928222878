import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-file-download-modal',
  templateUrl: './file-download-modal.component.html',
  styleUrls: ['./file-download-modal.component.scss']
})
export class FileDownloadModalComponent {
  @Input() isOpenModal = false;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  downloadJobSelector = new FormControl<null | 'csv'>(null, {
    validators: [
      Validators.required,
    ]
  });
  downloadTypeSelector = new FormControl<null | 'surveyAnswer' | 'examAnswer'>(null, {
    validators: [
      Validators.required,
    ]
  });
  surveyAnswerSelector = new FormControl<string>(null, {
    validators: [
      Validators.required,
    ]
  });
  examAnswerSelector = new FormControl<string>(null, {
    validators: [
      Validators.required,
    ]
  });

  formGroup = this.formBuilder.group({
    downloadJobSelector: this.downloadJobSelector,
    downloadTypeSelector: this.downloadTypeSelector,
    surveyAnswerSelector: this.surveyAnswerSelector,
    examAnswerSelector: this.examAnswerSelector
  });


  constructor(
    private formBuilder: FormBuilder,
  ) {}

  download(): void {}

  closeModal(): void {
    this.isOpenModal = false;
    this.isOpenModalEmitter.emit(false);
  }
}
