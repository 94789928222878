import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard  {
  sessionsSubscription: Subscription;
  constructor(
    private authService: AuthService,
  ){}
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean>
  {
    return this.authService
      .checkLoginState()
      .pipe(
        map(  (auth) => {
          if (!auth) {
            return true;
          }
          if (auth?.isAnonymous) {
            console.log('isAnonymous');
            return true;
          }
          // ログインOK
          return true;
        })
      );
  }
}
