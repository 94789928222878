import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {SessionState, setSession} from "../../store/sessions/sessions.reducer";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UnreadUserService} from "../../services/unread-user/unread-user.service";
import {HistoriesService} from "../../services/histories/histories.service";
import {FavoriteService} from "../../services/favorite/favorite.service";
import {BookmarkService} from "../../services/bookmark/bookmark.service";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {ChatThreadsService} from "../../services/chat-threads/chat-threads.service";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {Unread} from "../../models/unread";
import {Bookmark} from "../../models/bookmark";
import {ChatThread} from "../../models/chat";
import {Channel} from "../../models/channel";
import {Course} from "../../models/course";
import {CourseSubscription} from "../../models/course-subscription";
import {ChannelSubscription} from "../../models/channel-subscription";
import {map} from "rxjs/operators";
import {User} from "../../models/user";
import {Account} from "../../models/account";
import {AccountsService} from "../../services/accounts/accounts.service";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {CourseProgress} from "../../models/course-progress";
import {LoadingFrames} from "../../models/course-part";
import {CourseIndexPartService} from "../../services/course-index/course-index-part.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-my-page-top',
  templateUrl: './my-page-top.component.html',
  styleUrls: ['./my-page-top.component.scss']
})
export class MyPageTopComponent implements OnInit, OnDestroy {
  sessionsSubscription: Subscription;
  unreadUserSubscription: Subscription;
  historiesSubscription: Subscription;
  favoriteSubscription: Subscription;
  bookmarkSubscription: Subscription;
  chatThreadsSubscription: Subscription;
  channelSubsSubscription: Subscription;
  courseSubsSubscription: Subscription;
  currentSession: SessionState | undefined;
  unreadPosts: Unread[] =[];
  histories: Bookmark[] = [];
  favorites: Bookmark[] = [];
  bookmarks: Bookmark[] = [];
  chatThreads: ChatThread[] = [];
  channels: Channel[] = [];
  courses: Course[] = [];
  channelSubs: ChannelSubscription[] = [];
  courseSubs: CourseSubscription[] = [];
  isOpenChatModal = false;
  user: User = this.authService.user;
  chatAccount: Account;
  chatChannel: Channel;
  courseProgresses: CourseProgress[] = [];
  frames: { courseId: string, frames: LoadingFrames[] }[] = [];
  displayName: string = '';
  constructor(
    private authService: AuthService,
    private sessionsStore: Store<{ sessionsStore: SessionState }>,
    private unreadUserService: UnreadUserService,
    private chatThreadsService: ChatThreadsService,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private courseProgressesService: CourseProgressesService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private accountsService: AccountsService,
  ) {
    window.scrollTo(0, 0);
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    this.sessionsSubscription = this.sessionsStore
      .select(setSession)
      .pipe()
      .subscribe(async (sessionState) => {
        this.currentSession = sessionState;
      })
    this.unreadUserService.connectSnapShots(this.authService.uid,);
    this.unreadUserSubscription = this.unreadUserService.docs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as Unread;
          if (this.unreadPosts.findIndex(c => c.id === newDoc.id) === -1) {
            this.unreadPosts.push(newDoc as Unread);
          }
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                this.channels.push(c);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (!newDoc?.courseId) {
            return;
          }
          if (this.courses.findIndex(c => c.id === newDoc.courseId) === -1) {
            this.coursesService.fetch(newDoc.courseId)
              .then((c) => {
                this.courses.push(c);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
      });
    this.courseSubscriptionsService.connectSnapShotsMyAll(this.authService.uid,);
    this.courseSubsSubscription = this.courseSubscriptionsService.myDocs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as CourseSubscription;
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
                  this.channels.push(c);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (this.courses.findIndex(c => c.id === newDoc.courseId) === -1) {
            this.coursesService.fetch(newDoc.courseId)
              .then((c) => {
                this.courses.push(c);
                if (!newDoc.channelId) {
                  newDoc.channelId = c.channelId;
                  newDoc.updatedAt = new Date();
                  newDoc.updatedBy = this.authService.uid;
                  this.courseSubscriptionsService.update(
                    this.authService.uid,
                    newDoc,
                  );
                }
                if (this.courseSubs.findIndex(c => c.id === newDoc.id) === -1) {
                  this.courseSubs.push(newDoc as CourseSubscription);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
      });
    /*this.favoriteService.connectSnapShots(this.currentSession.currentUser.id,);
    this.favoriteSubscription = this.favoriteService.docs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as Bookmark;
          if (this.favorites.findIndex(c => c.id === newDoc.id) === -1) {
            this.favorites.push(newDoc as Bookmark);
          }
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                this.channels.push(c);
              });
          }
          if (this.courses.findIndex(c => c.id === newDoc.courseId) === -1) {
            this.coursesService.fetch(newDoc.courseId)
              .then((c) => {
                this.courses.push(c);
              });
          }
        });
      });
    this.bookmarkService.connectSnapShots(this.currentSession.currentUser.id,);
    this.bookmarkSubscription = this.bookmarkService.docs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as Bookmark;
          if (this.bookmarks.findIndex(c => c.id === newDoc.id) === -1) {
            this.bookmarks.push(newDoc as Bookmark);
          }
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                this.channels.push(c);
              });
          }
          if (this.courses.findIndex(c => c.id === newDoc.courseId) === -1) {
            this.coursesService.fetch(newDoc.courseId)
              .then((c) => {
                this.courses.push(c);
              });
          }
        });
      });
    this.historiesService.connectSnapShots(this.currentSession.currentUser.id,);
    this.historiesSubscription = this.historiesService.docs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as Bookmark;
          if (this.histories.findIndex(c => c.id === newDoc.id) === -1) {
            this.histories.push(newDoc as Bookmark);
          }
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                this.channels.push(c);
              });
          }
          if (this.courses.findIndex(c => c.id === newDoc.courseId) === -1) {
            this.coursesService.fetch(newDoc.courseId)
              .then((c) => {
                this.courses.push(c);
              });
          }
        });
      });*/

    this.channelSubscriptionsService.connectSnapShotsMyAll(this.authService.uid,);
    this.channelSubsSubscription = this.channelSubscriptionsService.myDocs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as ChannelSubscription;
          if (this.channelSubs.findIndex(c => c.id === newDoc.id) === -1) {
            this.channelSubs.push(newDoc as ChannelSubscription);
          }
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                this.channels.push(c);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
      });
    this.chatThreadsService.connectSnapShots(this.authService.uid,);
    this.chatThreadsSubscription = this.chatThreadsService.docs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as ChatThread;
          if (this.chatThreads.findIndex(c => c.id === newDoc.id) === -1) {
            this.chatThreads.push(newDoc as ChatThread);
          }
          if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
            this.channelsService.fetch(newDoc.channelId)
              .then((c) => {
                if (this.channels.findIndex(c => c.id === newDoc.channelId) === -1) {
                  this.channels.push(c);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (!newDoc?.courseId) {
            return;
          }
          if (this.courses.findIndex(c => c.id === newDoc.courseId) === -1) {
            this.coursesService.fetch(newDoc.courseId)
              .then((c) => {
                this.courses.push(c);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
      });
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.loadCourseProgresses().then();
  }
  afterRender(): void {
    // 表示名設定
    if (this.user?.displayName) {
      this.displayName = this.user.displayName;
    } else if (this.user?.fullName) {
      this.displayName = this.user.fullName;
    } else if (this.user?.familyName) {
      this.displayName = this.user.familyName;
    } else {
      this.displayName = '';
    }
  }
  async loadCourseProgresses(): Promise<void> {
    for (const course of this.courses) {
      const p = await this.courseProgressesService.fetchByMy(
        course.id,
        this.authService.uid
      )
      if (!p) {
        return;
      }
      p.courseId = course.id;
      this.courseProgresses.push(p);
    }
  }
  ngOnDestroy(): void {
    this.sessionsSubscription?.unsubscribe();
    this.unreadUserSubscription?.unsubscribe();
    this.historiesSubscription?.unsubscribe();
    this.favoriteSubscription?.unsubscribe();
    this.bookmarkSubscription?.unsubscribe();
    this.chatThreadsSubscription?.unsubscribe();
    this.channelSubsSubscription?.unsubscribe();
    this.courseSubsSubscription?.unsubscribe();
  }
  getChannelName(channelId: string): string {
    if (!channelId) {
      return '';
    }
    const target = this.channels.find(c => c.id === channelId);
    if (!target) {
      return '';
    }
    return target.name;
  }
  getChannelSlug(channelId: string): string {
    if (!channelId) {
      return '';
    }
    const target = this.channels.find(c => c.id === channelId);
    if (!target) {
      return '';
    }
    return target.slug;
  }
  getChannelImageSrc(channelId: string): string {
    if (!channelId) {
      return null;
    }
    const target = this.channels.find(c => c.id === channelId);
    if (!target) {
      return null;
    }
    return target?.profileImage?.src;
  }
  getChannelEyeCatchImageSrc(channelId: string): string {
    if (!channelId) {
      return null;
    }
    const target = this.channels.find(c => c.id === channelId);
    if (!target) {
      return null;
    }
    return target?.eyeCatchImage?.src;
  }
  getCourseName(courseId: string): string {
    if (!courseId) {
      return '';
    }
    const target = this.courses.find(c => c.id === courseId);
    if (!target) {
      return '';
    }
    return target.name;
  }
  getCourseImageSrc(courseId: string): string {
    if (!courseId) {
      return null;
    }
    const target = this.courses.find(c => c.id === courseId);
    if (!target) {
      return null;
    }
    return target?.eyeCatchImage?.src;
  }
  getCourseProgress(courseId: string): string {
    const progress = this.courseProgresses.find(p => p.courseId === courseId);
    if (!progress) {
      return '--';
    }
    return (Math.round(progress.all * 100)).toString();
  }
  getCourseProgressStyle(courseId: string): string {
    const result = this.getCourseProgress(courseId);
    return `width:${result}%`;
  }
  getProgress(courseId: string): void {}
  getProgressStyle(courseId: string): void {}
  getFlameTypeName(partId: string): void {}
  canShowCourse(courseSubscription: CourseSubscription): boolean {
    const channelSubscription = this.channelSubs.find(s => s.channelId === courseSubscription.channelId);
    const course = this.courses.find(c => c?.id === courseSubscription.courseId);
    if (!course) {
      return false;
    }
    const channel = this.channels.find(c => c?.id === course.channelId);
    if (!channel) {
      return false;
    }
    return this.coursesService.canShow(
      channel,
      channelSubscription,
      course,
      courseSubscription,
      this.currentSession?.currentUser
    )
  }
  // chat
  async clickUnread(u: Unread): Promise<void> {
    this.unreadUserService.remove(
      u.uid,
      u.id
    )
    await this.openChatModal(u.accountId, u.channelId);
  }
  async openChatModal(
    accountId: string,
    channelId: string,
  ): Promise<void> {
    if (!accountId) {
      return;
    }
    if (!channelId) {
      return;
    }
    this.chatAccount = await this.accountsService.fetchAccount(accountId);
    this.chatChannel = this.channels.find(c => c.id === channelId);
    if (!this.chatAccount) {
      return;
    }
    if (!this.chatChannel) {
      return;
    }
    this.isOpenChatModal = true;
  }
  changeOpenChatModal($event: boolean): void {
    this.isOpenChatModal = $event;
  }
}
