import {Component, OnDestroy, OnInit} from '@angular/core';
import {Channel} from "../../models/channel";
import {Course} from "../../models/course";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {CourseProgress, Progress} from "../../models/course-progress";
import {MonetizeMethods} from "../../models/monetize-methods";
import {ChannelSubscription} from "../../models/channel-subscription";
import {ChannelInvitation} from "../../models/channel-invitation";
import {CourseSubscription} from "../../models/course-subscription";
import {Tag} from "../../models/tag";
import {LoadingFrames, LoadingPart, LoadingSection} from "../../models/course-part";
import {CourseIndexPartService} from "../../services/course-index/course-index-part.service";
import {FrameTypes} from "../../models/course-frames";
import {FileUploaderToStorageService} from "../../services/file-uploader-to-storage/file-uploader-to-storage.service";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Account, AccountType} from "../../models/account";

@Component({
  selector: 'app-channel-course-index',
  templateUrl: './channel-course-index.component.html',
  styleUrls: ['./channel-course-index.component.scss']
})
export class ChannelCourseIndexComponent implements OnInit, OnDestroy {
  isNotFound = false;
  isMyChannel = false;
  channelSubscriptionStatus: ChannelSubscription;
  channelInvitationStatus: ChannelInvitation;
  courseSubscriptionStatus: CourseSubscription;
  channelId: string;
  slug: string;
  courseId: string;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  currentCourse: Course = new Course();
  courseProgress: CourseProgress;
  monetizeMethods = MonetizeMethods;
  tagPayload: Tag[] = [];
  MonetizeMethods = MonetizeMethods;
  frames: LoadingFrames[] = [];
  frameTypes = FrameTypes;
  chapterProgresses:[] = [];
  accountType = AccountType;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountsService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private courseProgressesService: CourseProgressesService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private parts: CourseIndexPartService,
    private fileUploaderToStorageService: FileUploaderToStorageService
  ) {
    window.scrollTo(0, 0)
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.uiStore.dispatch(switchTrueLoading());
  }
  ngOnDestroy(): void {}
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    // 未ログイン時は見えない
    if (this.authService.isAnonymous) {
      console.log('404 isAnonymous');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return ;
    }
    this.currentChannel = await this.channelsService.fetchBySlug(this.slug);
    this.channelId = this.currentChannel?.id;
    this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel?.accountId);
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(this.currentChannel?.id, this.authService.uid);
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    this.courseSubscriptionStatus = await this.courseSubscriptionsService.fetch(this.currentCourse?.id, this.authService.uid);
    // コース権限チェック
    if (!this.coursesService.canShow(
      this.currentChannel,
      this.channelSubscriptionStatus,
      this.currentCourse,
      this.courseSubscriptionStatus,
      this.authService.user
    )) {
      console.log('404 コースを閲覧できません。');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return ;
    }
    this.uiStore.dispatch(switchFalseLoading());
    // 各フレームのロード
    await this.loadAllFrames();
    this.courseProgressesService.loadAllFrames(this.currentCourse, this.currentCourse.accountId).then();
    // コース進捗
    this.courseProgress = await this.courseProgressesService.fetchByMy(
      this.currentCourse.id,
      this.authService.uid
    );
  }
  async loadAllFrames(): Promise<void> {
    const loader: {
      chapterIndex: number,
      sectionIndex: number,
      partIndex: number,
      partId: string,
      frameType: FrameTypes,
    }[] = [];
    this.frames = [];
    let chapterIndex = 0;
    for (const chapter of this.currentCourse.courseIndex.chapters) {
      this.frames[chapterIndex] = new LoadingFrames();
      this.frames[chapterIndex].id = chapter.id;
      let sectionIndex = 0;
      for (const section of chapter.sections) {
        this.frames[chapterIndex].sections[sectionIndex] = new LoadingSection();
        this.frames[chapterIndex].sections[sectionIndex].id = section.id;
        let partIndex = 0;
        for (const part of section.parts ) {
          this.frames[chapterIndex].sections[sectionIndex].parts[partIndex] = new LoadingPart(part.frameType);
          this.frames[chapterIndex].sections[sectionIndex].parts[partIndex].id = part.id;
          loader.push({
            chapterIndex: chapterIndex,
            sectionIndex: sectionIndex,
            partIndex: partIndex,
            partId: part.id,
            frameType: part.frameType,
          });
          partIndex++;
        }
        sectionIndex++;
      }
      chapterIndex++;
    }
    loader.map((load => {
      this.parts.loadFrameBody(
        this.currentChannel.accountId,
        this.currentCourse.id,
        load.partId,
        load.frameType
      ).then((res) => {
        this.frames[load.chapterIndex].sections[load.sectionIndex].parts[load.partIndex].frame = res;
      });
    }));
  }
  hasPermission(): boolean {
    if (this.currentCourse.monetized) {
      // TODO 課金状況を取得
      return  true;
    } else if (this.currentCourse.monetizeMethod === MonetizeMethods.freeTrial && this.courseSubscriptionStatus.activated) {
      return  true;
    } else {
      return  false;
    }
  }
  openAccordion(chapterIndex: number, sectionIndex: number): void {
    const check = document.getElementById(`toggle-c${chapterIndex}-s${sectionIndex}`);
    check.click();
  }
  async moveContent(part: LoadingPart): Promise<void> {
    const base = `/channel/course/frameType/${this.slug}/${this.courseId}`
    switch (part.frameType) {
      case this.frameTypes.CoverFrame:
        break;
      case this.frameTypes.VideoFrame:
        await this.router.navigate([base.replace('frameType', 'video') + `/${part.frame.id}`]);
        break;
      case this.frameTypes.SlideFrame:
        await this.router.navigate([base.replace('frameType', 'slide') + `/${part.frame.id}`]);
        return;
      case this.frameTypes.OnlineFrame:
        break;
      case this.frameTypes.LiveFrame:
        break;
      case this.frameTypes.ExaminationFrame:
        await this.router.navigate([base.replace('frameType', 'examination') + `/${part.frame.id}/start`]);
        break;
      case this.frameTypes.FileFrame:
        break;
      case this.frameTypes.SurveyFrame:
        await this.router.navigate([base.replace('frameType', 'survey') + `/${part.frame.id}`]);
        break;
      default:
        break;
    }
  }
  getCourseProgress(): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.courseProgressesService.calculateCourseProgress(
      this.courseProgress,
      this.frames,
    )
    return (Math.round(result * 100)).toString();
  }
  getChapterProgress(index: number): string {
    if (index === undefined ) {
      return '--';
    }
    if (index === null ) {
      return '--';
    }
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.courseProgressesService.calculateChapterProgress(
      this.courseProgress,
      this.frames,
      index
    )
    return (Math.round(result * 100)).toPrecision();
  }
  getCourseProgressStyle(): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.getCourseProgress();
    return `width:${result}%`;
  }
  getChapterProgressStyle(index: number): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.getChapterProgress(index);
    return `width:${result}%`;
  }
  getSectionProgress(chapterIndex: number, sectionIndex: number): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.courseProgressesService.calculateSectionProgress(
      this.courseProgress,
      this.frames,
      chapterIndex,
      sectionIndex
    )
    return (Math.round(result * 100)).toString();
  }
  getSectionProgressStyle(chapterIndex: number, sectionIndex: number): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.getSectionProgress(chapterIndex, sectionIndex);
    return `width:${result}%`;
  }
  getPartProgress(contentKey: string): string {
    const seq = this.courseProgressesService.indexSeq.find(f => f.partId === contentKey);
    if (!seq) {
      return '--';
    }
    const frame = this.frames[seq.chapterIndex].sections[seq.sectionIndex].parts[seq.partIndex];
    if (!frame) {
      return '--';
    }
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const progress = this.courseProgress.progresses.find(p => p.contentKey === contentKey);
    if (!progress) {
      return '--';
    }
    if (!progress.progress) {
      return '--';
    }
    if (frame.frameType === this.frameTypes.ExaminationFrame) {
      if (Math.round( progress.progress * 100) >= frame.frame.examinationFrame.passThreshold) {
        return '100';
      }
    }
    return (Math.round( progress.progress * 100)).toString();
  }
  getPartProgressForBar(contentKey: string): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const progress = this.courseProgress.progresses.find(p => p.contentKey === contentKey);
    if (!progress) {
      return '--';
    }
    if (!progress.progress) {
      return '--';
    }
    return (Math.round( progress.progress * 100)).toString();
  }
  getPartProgressStyle(contentKey: string): string {
    if (!this.courseProgress) {
      return '--';
    }
    if (!this.frames) {
      return '--';
    }
    const result = this.getPartProgressForBar(contentKey);
    return `width:${result}%`;
  }
  async downloadFile(part: LoadingPart): Promise<void> {
    // ダウンロードして
    const signedUrl = await this.fileUploaderToStorageService.getDownloadUrl(part.frame.fileFrame.files[0].objectName);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.download = part.frame.fileFrame.files[0].name;
    a.href = signedUrl;
    a.target = '_blank';
    a.click();
    a.remove();
    // 進捗を更新する
    let index = this.courseProgress.progresses.findIndex(p => p.contentKey === part.id);
    if (index === -1 ) {
      index = this.courseProgress.progresses.length;
      this.courseProgress.progresses[index] = new Progress();
      this.courseProgress.progresses[index].contentKey = part.id;
      this.courseProgress.progresses[index].progress = 1;
    }
    this.courseProgress.progresses[index].progress = 1;
    this.saveProgress();
  }
  saveProgress(): void {
    this.courseProgressesService.update(this.courseId,
      this.authService.uid,
      this.courseProgress
    )
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  }
  scrollPageAnker(htmlId: string): void {
    const target =  document.getElementById(htmlId.replace('#', ''));
    const contentBodyElem = document.getElementById('contentBody');
    if (contentBodyElem) {
      contentBodyElem.scrollTo(0, target.offsetTop.valueOf());
    }
    const contentBodyInnerElem = document.getElementById('contentBodyInner');
    if (contentBodyInnerElem) {
      contentBodyInnerElem.scrollTo(0, target.offsetTop.valueOf());
    }
  }
  isValidFrame(frame: LoadingPart): boolean {
    if (!frame) {
      return false;
    }
    switch (frame.frameType) {
      case FrameTypes.CoverFrame:
        return false;
      case FrameTypes.FileFrame:
        return !!frame.frame?.fileFrame?.files[0]?.objectName;
      case FrameTypes.VideoFrame:
        return !!frame.frame?.videoFlame?.m3u8ObjectName;
      case FrameTypes.SlideFrame:
        return !!frame.frame?.slideFlame?.slideObjectNames;
      case FrameTypes.SurveyFrame:
        return frame.frame?.surveyFrame?.questions?.length > 0;
      case FrameTypes.ExaminationFrame:
        return frame.frame?.examinationFrame?.questions?.length > 0;
      default:
        return false;
    }
  }
}
