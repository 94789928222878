<app-header-contents-channel></app-header-contents-channel>
<app-not-found *ngIf="isNotFound"></app-not-found>
<app-blank *ngIf="!isPublished && !isNotFound"></app-blank>
<div
  *ngIf="isPublished"
  class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <app-notification></app-notification>
  <div class="l-content-front__head">
    <div class="l-content-front__head__kv">
      <img
        *ngIf="!currentChannel?.eyeCatchImage?.src"
        ngSrc="no-image"
        width="1280"
        height="720"
        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
        alt="">
      <img
        *ngIf="currentChannel?.eyeCatchImage?.src"
        ngSrc="{{currentChannel?.eyeCatchImage?.src}}"
        width="1280"
        height="720"
        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
        alt="">
    </div>
  </div>
  <ng-container *ngIf="currentChannel.isOem">
    <div class="l-content-front__inner">
      <div class="p-channelTop">
        <div class="c-courseList-2410">
          <div class="c-courseList-2410__head">
            <div class="c-courseList-2410__head__title" translate>受講中のコース</div>
            <!--<div class="c-courseList-2410__head__more">すべてを見る</div>-->
          </div>

          <div class="c-courseList-2410__inner" id="swipePanel3">

            <ng-container *ngFor="let course of courses">
              <div
                *ngIf="canShowCourse(course)"
                routerLink="/channel/course/index/{{currentChannel.slug}}/{{course.id}}"
                class="c-courseList-2410__inner__item">
                <div class="c-courseList-2410__inner__item__thumb">

                  <img
                    *ngIf="!course?.eyeCatchImage?.src"
                    ngSrc="no-image"
                    width="280"
                    height="157"
                    ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                    alt="">
                  <img
                    *ngIf="course?.eyeCatchImage?.src"
                    ngSrc="{{course?.eyeCatchImage?.src}}"
                    width="280"
                    height="157"
                    ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                    alt="">

                </div>
                <div class="c-courseList-2410__inner__item__title">
                  {{course.name}}
                </div>
                <div class="c-courseList-2410__inner__item__channel">
                  <div class="c-courseList-2410__inner__item__channel__name">
                    {{currentChannel.name}}
                  </div>
                  <div class="c-courseList-2410__inner__item__channel__size"></div>
                </div>

                <ul class="c-courseList-2410__inner__item__tags">
                  <ng-container *ngFor="let tag of course?.tagPayloads">
                    <li class="c-courseList-2410__inner__item__tags__item">
                      {{tag.name}}
                    </li>
                  </ng-container>
                </ul>
                <div class="c-courseList-2410__inner__item__progress">
                  <div class="c-courseList-2410__inner__item__progress__head">
                    <div class="c-courseList-2410__inner__item__progress__head__status" translate>
                      開催中
                    </div>
                    <div class="c-courseList-2410__inner__item__progress__head__value">
                      <span translate>受講完了率</span>{{getCourseProgress(course?.id)}}%
                    </div>
                  </div>
                  <div class="p-progress">
                    <div class="p-progress__bar">
                      <div class="p-progress__bar__bg"></div>
                      <div class="p-progress__bar__body" [style]="getCourseProgressStyle(course.id)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div (click)="scrollPanelBack('swipePanel3')" class="c-courseList-2410__inner__button c-courseList-2410__inner__button-back"></div>
          <div (click)="scrollPanelNext('swipePanel3')"class="c-courseList-2410__inner__button c-courseList-2410__inner__button-next"></div>

        </div>
      </div>
    </div>

  </ng-container>




  <ng-container *ngIf="!currentChannel.isOem">
    <div class="l-content-front__inner">
      <div class="p-channelTop">
        <div class="p-channelTop__head">
          <div class="p-channelTop__promotion">
            <div *ngIf="courseSubs?.length > 0" class="c-courseList-2410">
              <div class="c-courseList-2410__head__title" translate>受講中のコース</div>
              <div class="c-courseList-2410__inner" id="swipePanel1">
                <ng-container *ngFor="let course of courses">
                  <div
                    *ngIf="canShowAndHasSubsCourse(course)"
                    routerLink="/channel/course/index/{{currentChannel.slug}}/{{course.id}}"
                    class="c-courseList-2410__inner__item">
                    <div class="c-courseList-2410__inner__item__thumb">

                      <img
                        *ngIf="!course?.eyeCatchImage?.src"
                        ngSrc="no-image"
                        width="280"
                        height="157"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">
                      <img
                        *ngIf="course?.eyeCatchImage?.src"
                        ngSrc="{{course?.eyeCatchImage?.src}}"
                        width="280"
                        height="157"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">

                    </div>
                    <div class="c-courseList-2410__inner__item__title">
                      {{course.name}}
                    </div>
                    <div class="c-courseList-2410__inner__item__channel">
                      <div class="c-courseList-2410__inner__item__channel__name">
                        {{currentChannel.name}}
                      </div>
                    </div>

                    <ul class="c-courseList-2410__inner__item__tags">
                      <ng-container *ngFor="let tag of course?.tagPayloads">
                        <li class="c-courseList-2410__inner__item__tags__item">
                          {{tag.name}}
                        </li>
                      </ng-container>
                    </ul>
                    <div class="c-courseList-2410__inner__item__progress">
                      <div class="c-courseList-2410__inner__item__progress__head">
                        <div class="c-courseList-2410__inner__item__progress__head__value">
                          <span translate>進捗完了率</span>{{getCourseProgress(course.id)}}%
                        </div>
                      </div>
                      <div class="p-progress">
                        <div class="p-progress__bar">
                          <div class="p-progress__bar__bg"></div>
                          <div class="p-progress__bar__body" [style]="getCourseProgressStyle(course.id)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div (click)="scrollPanelBack('swipePanel1')" class="c-courseList-2410__inner__button c-courseList-2410__inner__button-back"></div>
              <div (click)="scrollPanelNext('swipePanel1')"class="c-courseList-2410__inner__button c-courseList-2410__inner__button-next"></div>

            </div>
            <div *ngIf="courses?.length > 0"  class="c-courseList-2410">
              <div class="c-courseList-2410__head__title" translate>すべてのコース</div>
              <div class="c-courseList-2410__inner" id="swipePanel2">
                <ng-container *ngFor="let course of courses">
                  <div
                    *ngIf="course?.published"
                    (click)="moveToCourse(course?.id)"
                    class="c-courseList-2410__inner__item">
                    <div class="c-courseList-2410__inner__item__thumb">

                      <img
                        *ngIf="!course?.eyeCatchImage?.src"
                        ngSrc="no-image"
                        width="280"
                        height="157"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">
                      <img
                        *ngIf="course?.eyeCatchImage?.src"
                        ngSrc="{{course?.eyeCatchImage?.src}}"
                        width="280"
                        height="157"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">

                    </div>
                    <div class="c-courseList-2410__inner__item__title">
                      {{course.name}}
                    </div>
                    <div class="c-courseList-2410__inner__item__channel">
                      <div class="c-courseList-2410__inner__item__channel__name">
                        {{currentChannel.name}}
                      </div>
                    </div>

                    <ul class="c-courseList-2410__inner__item__tags">
                      <ng-container *ngFor="let tag of course?.tagPayloads">
                        <li class="c-courseList-2410__inner__item__tags__item">
                          {{tag.name}}
                        </li>
                      </ng-container>
                    </ul>
                    <div class="c-courseList-2410__inner__item__progress">
                      <div class="c-courseList-2410__inner__item__progress__head">
                        <div class="c-courseList-2410__inner__item__progress__head__status" translate>
                          開催中
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div (click)="scrollPanelBack('swipePanel2')" class="c-courseList-2410__inner__button c-courseList-2410__inner__button-back"></div>
              <div (click)="scrollPanelNext('swipePanel2')"class="c-courseList-2410__inner__button c-courseList-2410__inner__button-next"></div>

            </div>
          </div>

          <div class="p-channelTop__head__title">
            <div class="p-channelTop__head__title__channelName" translate>チャンネル名</div>
            <div class="p-channelTop__head__title__courseName">
              {{currentChannel?.name}}
            </div>
          </div>
          <div
            *ngIf="tagPayload.length > 0"
            class="p-channelTop__head__tags">
            <div *ngFor="let tag of tagPayload"
                 routerLink="/tag/{{tag.name}}"
                 class="p-channelTop__head__tags__item"><span>#{{tag.name}}</span></div>
          </div>
          <div
            *ngIf="!isMyChannel && !channelSubscriptionStatus?.activated && currentChannel.monetized"
            class="p-channelTop__head__button">
            <button
              (click)="openModal()"
              type="button"
              class="c-button c-button-primary"
            ><span translate>このチャンネルを受講する</span></button>
          </div>
          <div
            *ngIf="!isMyChannel && !channelSubscriptionStatus?.activated && !currentChannel.monetized"
            class="p-channelTop__head__button">
            <button
              (click)="openModal()"
              type="button"
              class="c-button c-button-primary"
            ><span translate>このチャンネルを見る</span></button>
          </div>
          <div
            *ngIf="!isMyChannel && !channelSubscriptionStatus?.activated"
            class="p-channelTop__head__cancelPolicy" translate>キャンセルポリシー</div>
          <div
            *ngIf="!isMyChannel && channelSubscriptionStatus?.activated && currentChannel.monetized"
            class="p-channelTop__head__button">
            <button
              type="button"
              class="c-button c-button-secondary"
            ><span translate>受講中</span></button>
          </div>
          <div
            *ngIf="!isMyChannel && channelSubscriptionStatus?.activated && !currentChannel.monetized"
            class="p-channelTop__head__button">
            <button
              type="button"
              class="c-button c-button-secondary"
            ><span translate>登録済み</span></button>
          </div>
          <div
            *ngIf="isMyChannel"
            routerLink="/manager/channel/{{channelId}}"
            class="p-channelTop__head__button">
            <button
              type="button"
              class="c-button c-button-secondary"
            ><span translate>管理中のチャンネル</span></button>
          </div>
        </div>
        <!--<div
          class="p-channelTop__suggestButton">
          <button
            type="button"
            class="c-button c-button-disable"
          ><span translate>今すぐ受講する</span></button>
        </div>-->
        <dl class="p-channelTop__main">
          <dt class="p-channelTop__main__title" translate>チャンネル概要</dt>
          <dd class="p-channelTop__main__body">
            <div class="p-channelTop__main__body__content">
              <p class="p-channelTop__main__body__content__description" [innerHTML]="currentChannel?.description | nl2br"></p>
            </div>
          </dd>
          <dt class="p-channelTop__main__title" translate>提供者</dt>
          <dd class="p-channelTop__main__body">
            <div class="p-channelTop__main__body__wrap">
              <div class="p-user">
                <div class="p-user__avatar">
                  <img
                    *ngIf="!currentChannel.profileImage?.src"
                    ngSrc="no-image"
                    width="240"
                    height="135"
                    ngSrcset="160w,240w,320w"
                    alt="">
                  <img
                    *ngIf="currentChannel.profileImage?.src"
                    [ngSrc]="currentChannel.profileImage.src"
                    width="240"
                    height="135"
                    ngSrcset="160w,240w,320w"
                    alt="">
                </div>
                <div class="p-user__profile">
                  <div class="p-user__profile__name">
                    {{currentChannel.name}}
                  </div>
                  <div class="p-user__profile__id">
                    &#64;{{currentChannel.slug}}
                  </div>
                </div>
              </div>
            </div>
          </dd>
          <dt
            *ngIf="currentChannel.monetized"
            class="p-channelTop__main__title" translate>チャンネルの価格</dt>
          <dd
            *ngIf="currentChannel.monetized"
            class="p-channelTop__main__body">

            <div
              *ngIf="currentChannel.monetized && currentChannel.monetizeMethod === monetizeMethods.channelSubscription"
              class="p-channelTop__main__body__content">
              <dl class="p-channelTop__main__body__content__plans">
                <dt class="p-channelTop__main__body__content__plans__name" translate>チャンネルメンバーシップ</dt>
                <dd class="p-channelTop__main__body__content__plans__price">
                  ¥{{currentChannel.channelSubscriptionPrice}}<span translate>/月</span>
                </dd>
                <dt class="p-channelTop__main__body__content__plans__name" translate>プレミアムメンバーシップ</dt>
                <dd class="p-channelTop__main__body__content__plans__price">
                  ¥{{currentChannel.channelSubscriptionPriceForPremium}}<span translate>/月</span>
                </dd>
              </dl>
              <p
                class="p-channelTop__main__body__content__notes">
                <span translate>この講座チャンネルは月額固定料金のサブスクリプションメンバーシップ制です。メンバーシップに参加すると全ての一般コースを受講できます。</span><br>
                <span translate>プレミアム受講にアップグレードすると限定講座を含む全ての講座を受講できます。</span><br><br>
                <span translate>一般メンバーとプレミアムメンバーの違いは「チャンネル概要」をお読みください。</span>
              </p>
            </div>

          </dd>

        </dl>

        <ng-container *ngIf="currentChannel?.samples?.length > 0">
          <div class="p-channelTop__promotion">
            <div class="c-courseList-2410">
              <div class="c-courseList-2410__head">
                <div class="c-courseList-2410__head__title" translate>サンプル動画</div>
              </div>
              <div class="c-courseList-2410__inner">
                <ng-container *ngFor="let sample of currentChannel.samples;index as i">
                  <div class="c-courseList-2410__inner__item"
                       (click)="changeSampleVideoModal($event, i )">
                    <div class="c-courseList-2410__inner__item__thumb">
                      <img
                        *ngIf="!sample?.eyeCatchImage?.src"
                        ngSrc="no-image"
                        width="240"
                        height="135"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                      <img
                        *ngIf="sample?.eyeCatchImage?.src"
                        [ngSrc]="sample?.eyeCatchImage?.src"
                        ngSrc="no-image"
                        width="240"
                        height="135"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                    </div>
                    <div class="c-courseList-2410__inner__item__title">
                      {{sample.name}}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="p-channelTop__promotion">
          <div class="c-courseList-2410">
            <div class="c-courseList-2410__head">
              <div class="c-courseList-2410__head__title" translate>人気のコース</div>
              <div class="c-courseList-2410__head__more" translate>すべてを見る</div>
            </div>
            <app-favorites></app-favorites>
          </div>
        </div>
      </div>

    </div>
  </ng-container>



</div>
<app-footer *ngIf="channelOwnerAccount?.accountType === accountType.general"></app-footer>
<app-footer-business *ngIf="channelOwnerAccount?.accountType === accountType.oem"></app-footer-business>



<section class="l-modal" *ngIf="isOpenModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>チャンネル登録</div>
      <div class="c-modal__head__closeButton" (click)="closeModal()"></div>
    </div>

    <div class="c-modal__main">
      <ng-container *ngIf="!currentChannel.monetized">
        <div class="c-modal__main__description">
          <div class="c-modal__main__description__title" translate>チャンネル購読の価格</div>
          <div class="c-modal__main__description__body">
            <p translate>このチャンネルは無料で登録出来ます。チャンネル内のコース毎に受講料金が発生する場合があります。チャンネルを登録したあと、無料でどのようなコースがあるのかを確認することが出来ます。</p>
            <p>
              <span translate>チャンネル閲覧</span><br>
              <strong>¥0<span translate>/月</span></strong>
            </p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentChannel.monetized">
        <div class="c-modal__main__description">
          <div class="c-modal__main__description__title" translate>チャンネル購読の価格</div>
          <div class="c-modal__main__description__body">
            <p translate>この講座はプレミアムメンバーシップの対象です。いますぐ受講を開始できます。</p>
            <p>
              <span translate>メンバーシップ</span><br>
              <strong>¥{{currentChannel.channelSubscriptionPrice}}<span translate>/月</span></strong>
            </p>
            <p>
              <span translate>プレミアムメンバーシップ</span><br>
              <strong>¥{{currentChannel.channelSubscriptionPriceForPremium}}<span translate>/月</span></strong>
            </p>
            <div class="c-modal__main__description__body__caption">
              <span translate>この講座は月額固定料金のサブスクリプションメンバーシップ制です。</span>
              <span translate>メンバーシップに参加すると全ての一般コースを受講できます。</span><br>
              <span translate>プレミアム受講にアップグレードすると限定講座を含む全ての講座を受講できます。</span></div>
          </div>
        </div>

      </ng-container>


      <div class="c-modal__main__subscription">
        <div class="c-modal__main__subscription__buttons">
          <div class="c-modal__main__subscription__buttons__button"
               *ngIf="!currentChannel.monetized"
               (click)="applySubscriptionFree()">
            <button class="c-button c-button-primary"
            ><span translate>チャンネル登録をする（無料）</span></button>
          </div>
          <div
            (click)="applySubscriptionMembership()"
            *ngIf="currentChannel.monetized"
            class="c-modal__main__subscription__buttons__button">
            <button class="c-button c-button-primary"
            ><span translate>メンバーシップに入会する</span></button>
          </div>
          <div
            (click)="applySubscriptionPremiumMembership()"
            *ngIf="currentChannel.monetized"
            class="c-modal__main__subscription__buttons__button">
            <button class="c-button c-button-primary"
            ><span translate>プレミアムメンバーシップに入会する</span></button>
          </div>
          <div
            (click)="closeModal()"
            *ngIf="currentChannel.monetized"
            class="c-modal__main__subscription__buttons__button">
            <button class="c-button c-button-cancel"
            ><span translate>閉じる</span></button>
          </div>
        </div>
        <div
          class="c-modal__main__subscription__policy" translate>キャンセルポリシー</div>
      </div>
    </div>
  </div>
</section>
<app-modal-login (isOpenLoginModalEmitter)="changeLoginModal($event)"  [isOpenLoginModal]="isOpenLoginModal"></app-modal-login>
<app-modal-payment-credit [isOpenCreditModal]="isOpenCreditModal" ></app-modal-payment-credit>

<app-video-player
  *ngIf="isOpenVideoModal"
  (isOpenVideoModalEmitter)="changeSampleVideoModal($event, 0)"
  [idToken]="idToken"
  [contentKey]="currentChannel.samples[sampleIndex].contentKey"
  [path]="samplePath"
  [numOfFragments]="numOfFragmentsString"
  [m3u8ObjectName]="currentChannel.samples[sampleIndex].m3u8ObjectName"
  [isOpenVideoModal]="isOpenVideoModal"
></app-video-player>

