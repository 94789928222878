import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-login-by-entra',
  templateUrl: './login-by-entra.component.html',
  styleUrls: ['./login-by-entra.component.scss']
})
export class LoginByEntraComponent implements OnInit {
  fireAuthSubscription: Subscription;
  constructor(
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState}>,
    private router: Router,
    private toasterService: ToastrService,
  ) {}
  async ngOnInit(): Promise<void> {
    try {
      this.uiStore.dispatch(switchTrueLoading());
      await this.authService.loginWithSamlAfterRedirect();
      this.uiStore.dispatch(switchFalseLoading());
      this.router.navigate(['/']).then();
      return;
    } catch (error) {
      console.log(error);
      if (error !== 'not-login') {
        this.toasterService.warning('ログイン認証に失敗しました。');
      }
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
  }
  async login(): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    try {
      await this.authService.beginLoginSaml();
    } catch (error) {
      console.error(error);
      this.uiStore.dispatch(switchFalseLoading());
      this.toasterService.warning('ログイン認証に失敗しました。');
      return;
    }
  }
}
