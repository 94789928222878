import {Injectable, inject} from '@angular/core';
import {Auth, authState, User, idToken, signInAnonymously, updatePassword} from '@angular/fire/auth';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Store} from '@ngrx/store';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs';
import {
  UserCredential,
  linkWithCredential,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  setPersistence,
  browserLocalPersistence,
  getRedirectResult,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithRedirect,
  sendEmailVerification,
} from "firebase/auth";
import {storeIdToken, storeIsAnonymous, storeIsOem, storeUser} from "../../store/sessions/sessions.actions";
import {map} from "rxjs/operators";
import {User as FireRocketUser} from "../../models/user";
import {CurrentUser, SessionState} from "../../store/sessions/sessions.reducer";
import {LanguagesService} from "../languages/languages.service";
import {environment} from "../../../environments/environment";
import {BackendService} from "../backend/backend.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  fireAuth: Auth = inject(Auth);
  sessionsSubscription: Subscription;
  fireAuthSubscription: Subscription;
  idTokenSubscription: Subscription;
  userCredential: UserCredential;
  authState$: Observable<User>;
  authUser: User;
  idToken$: Observable<string>;
  idToken: string;
  uid: string;
  idTokenExpireAt: number | null;
  ssoAccessToken: string;
  user: FireRocketUser | null;
  claims: any;
  loggedIn$: BehaviorSubject<boolean>;
  isAnonymous$: BehaviorSubject<boolean>;
  isAnonymous: boolean = false;
  authProviderId: string;
  constructor(
    private fireStore: AngularFirestore,
    private sessionsStore: Store<{ sessions: SessionState}>,
    private languagesService: LanguagesService,
    private backendService: BackendService,
  ) {
    // this.fireAuth = inject(Auth);
    this.loggedIn$ = new BehaviorSubject<boolean>(false);
    this.isAnonymous$ = new BehaviorSubject<boolean>(false);
    this.authState$ = authState(this.fireAuth);
    this.idToken$ = idToken(this.fireAuth);
    this.fireAuthSubscription = this.authState$
      .subscribe(async (user: User) => {
        if (user) {
          this.authUser = user;
          this.uid = user.uid;
        }
      });
    this.idTokenSubscription = this.idToken$.subscribe(async (token) => {
      if (token) {
        this.idToken = token;
        this.idTokenExpireAt = (new Date().getTime()) + (3600*1000);
      }
    });
  }
  async checkIdTokenExpire (): Promise<void> {
    if (this.idTokenExpireAt < new Date().getTime()) {
      await this.refresh();
    }
  }
  async refresh(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.fireAuthSubscription = this.authState$.pipe().subscribe(async (user) => {
        // ログインしてない場合
        if (!user) {
          await this.loginAnonymous();
          console.log('now logged in Anonymous');
          const currentUser: CurrentUser = {
            id: this.authUser?.uid,
            language: 'ja',
            displayName: 'ログインしていません',
          };
          this.sessionsStore.dispatch(storeIsOem({isOem: false}));
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.authUser?.isAnonymous}));
          this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
          this.isAnonymous = true;
          this.isAnonymous$.next(true);
          resolve();
        }
        // 匿名ログイン済の場合
        if (user?.isAnonymous) {
          console.log('user data nothing');
          console.log('loginAnonymous');
          const currentUser: CurrentUser = {
            id: this.authUser?.uid,
            language: 'ja',
            displayName: 'ログインしていません',
          };
          this.sessionsStore.dispatch(storeIsOem({isOem: false}));
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.authUser?.isAnonymous}));
          this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
          this.isAnonymous = true;
          this.isAnonymous$.next(true);
          resolve();
        }
        // 匿名ではない場合ログイン済の処理
        const fireRocketUser: FireRocketUser = await this.fetchUser(this.authUser?.uid);
        if (!user.isAnonymous && fireRocketUser) {
          console.log('fire rocket user already logged in.');
          this.authState$.pipe().subscribe(async (user) => {
            user.getIdToken()
            .then(idToken => { this.idToken = idToken });
          })
          this.user = fireRocketUser;
          this.sessionsStore.dispatch(storeIsOem({isOem: fireRocketUser?.isOem}));
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.authUser?.isAnonymous}));
          const currentUser: CurrentUser = {
            displayName: fireRocketUser?.displayName,
            language: fireRocketUser?.language,
            photoUrl: fireRocketUser?.profileImage?.src,
            id: fireRocketUser?.id,
            currentAccountId: fireRocketUser?.currentAccountId,
            roles: null,
            emailValid: this.authUser?.emailVerified,
          }
          this.authProviderId = this.authUser.providerId;
          this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
          if (fireRocketUser?.language) {
            await this.languagesService.change(fireRocketUser?.language, fireRocketUser?.id);
          } else {
            await this.languagesService.change('ja', fireRocketUser?.id);
          }
          this.isAnonymous = false;
          this.isAnonymous$.next(false);
          resolve();
        }
      });
    });
  }
  loginAnonymous(): Promise<void> {
    // 匿名ログイン
    return new Promise( (resolve, reject) => {
      signInAnonymously(this.fireAuth)
        .then((currentUserCredential) => {
          // this.userCredential$.next(currentUserCredential);
          this.authUser = currentUserCredential.user;
          const user: CurrentUser = {
            id: this.authUser.uid,
            displayName: 'ログインしていません',
          };
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: true}));
          this.sessionsStore.dispatch(storeUser({ currentUser: user}));
          resolve();
        })
        .catch((e) => {
          // 匿名ログイン出来ない場合
          console.log('can not login with Anonymous');
          reject(e);
        })
    });
  }
  login(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setPersistence(this.fireAuth, browserLocalPersistence)
        .then(async () => {
          try {
            const userCredential = await signInWithEmailAndPassword(this.fireAuth,email, password);
            this.loggedIn$.next(true);
            this.authProviderId = userCredential.user.providerId;
            this.authUser = userCredential.user;
            const idToken = await userCredential.user.getIdToken();
            // Verify
            this.backendService.setIdToken(idToken);
            const result = await this.backendService.post(
              'auth/saml/saml-verify',
              {
                env: environment.nodeEnv
              }
            )
            sessionStorage.setItem('verified', result);
            this.sessionsStore.dispatch(storeIdToken({idToken: result?.idToken}));
            const user = await this.fetchUser(this.authUser.uid);
            this.sessionsStore.dispatch(storeIsOem({isOem: user?.isOem}));
            this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.authUser?.isAnonymous}));
            const currentUser: CurrentUser = {
              displayName: user?.displayName,
              language: user?.language,
              photoUrl: user?.profileImage?.src,
              id: user?.id,
              currentAccountId: user?.currentAccountId,
              roles: null,
              emailValid: this.authUser?.emailVerified,
            };
            this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
            resolve();
          } catch (err) {
            console.log(err);
            reject();
          }
        })
    })
  }
  async beginLoginSaml(): Promise<void> {
    const provider = new SAMLAuthProvider(environment.ssoProviderName);
    // console.log('beginLoginSaml', provider);
    // console.log('beginLoginSaml', this.fireAuth);
    await signInWithRedirect(this.fireAuth, provider);
  }
  loginWithSamlAfterRedirect(): Promise<void> {
    // console.log('loginWithSamlAfterRedirect');
    // return Promise.resolve();
    const provider = new SAMLAuthProvider(environment.ssoProviderName);
    return new Promise(async (resolve, reject) => {
      // await setPersistence(this.fireAuth, browserLocalPersistence);
      console.log(this.fireAuth);
      const userCredential = await getRedirectResult(this.fireAuth);
      if (!userCredential) {
        reject('not-login');
        return;
      }
      if (!userCredential?.user) {
        reject('not-login');
        return;
      }
      const samlCredential = OAuthProvider?.credentialFromResult(userCredential);
      // console.log(samlCredential);
      // console.log('-- Microsoftから戻ったあとのデータ取得 --');
      // console.log(userCredential);
      // @ts-ignore
      // console.log('accessToken: ' + userCredential?.user?.reloadUserInfo?.accessToken);
      // @ts-ignore
      // console.log('email: ' + userCredential?.user?.reloadUserInfo?.providerUserInfo[0]?.email);
      // console.log('idToken(credential): ' + samlCredential?.idToken);
      const idToken = await userCredential.user.getIdToken();
      // console.log('ID Token(point2): ', idToken);
      this.backendService.setIdToken(idToken);
      const verified: any = await this.backendService.post(
        'auth/saml/saml-verify',
        {
          env: environment.nodeEnv,
          // @ts-ignore
          email: samlCredential?.user?.reloadUserInfo[0]?.email,
        }
      )
      // console.log('verified:', verified);
      // @ts-ignore
      // console.log('rawUserInfo:', samlCredential?._tokenResponse?.rawUserInfo);
      sessionStorage.setItem('verified', verified);
      this.sessionsStore.dispatch(storeIdToken({idToken: verified?.idToken}));
      this.idToken = verified?.idToken;
      // @ts-ignore
      const email = samlCredential?.user?.reloadUserInfo?.providerUserInfo[0]?.email;
      this.uid = verified?.uid;
      const displayName = email;
      let fireRocketUser: FireRocketUser = undefined;
      try {
        fireRocketUser = await this.fetchUser(verified?.uid);
      } catch (e) {
        console.error(e);
      }
      if (fireRocketUser) {
        // console.log('既存ユーザー:', fireRocketUser);
        this.sessionsStore.dispatch(storeIsOem({isOem: fireRocketUser?.isOem}));
        this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: false}));
        const currentUser: CurrentUser = {
          displayName: fireRocketUser?.displayName,
          language: fireRocketUser?.language,
          photoUrl: fireRocketUser?.profileImage?.src,
          id: fireRocketUser?.id,
          currentAccountId: fireRocketUser?.currentAccountId,
          roles: null,
          emailValid: this.authUser?.emailVerified,
        };
        this.user = fireRocketUser;
        this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
        await this.refresh();
        await this.languagesService.change(this.user?.language, this.uid);
        resolve();
      } else {
        // console.log('新規ユーザー:');
        this.backendService.setIdToken(verified?.idToken);
        await this.backendService.post(
          'accounts/user/sign-up',
          {
            accountId: environment.baseAccountId,
            displayName: displayName,
            email: email,
          });
        // const newUser: FireRocketUser = await this.fetchUser(verified?.uid);
        // this.user = newUser;
        await this.refresh();
        resolve();
      }
    });
  }
  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireAuth
        .signOut()
        .then(() => {
          this.loggedIn$.next(false);
          this.authUser = undefined;
          this.uid = undefined;
          this.user = undefined;
          this.idToken = undefined;
          resolve();
        })
        .catch( (e) => {
          this.loggedIn$.next(false);
          this.authUser = undefined;
          this.uid = undefined;
          this.user = undefined;
          this.idToken = undefined;
          reject();
        });
    })
  }
  signup(
    email: string,
    password: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const credential = EmailAuthProvider.credential(email, password);
      const auth = getAuth();
      linkWithCredential(auth.currentUser, credential)
        .then(async (userCred) => {
          const user = userCred.user;
          const idTokenResult = await user.getIdTokenResult();
          this.idToken = idTokenResult.token;
          this.uid = userCred.user.uid;
          console.log("Anonymous account successfully upgraded", user);
          resolve();
        }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
        reject();
      });
    });
  }
  checkLoginState(): Observable<User> {
    return this.authState$
      .pipe(
        map((authUser) => {
          if (authUser) {
            this.uid = authUser.uid;
            this.authUser = authUser;
            return authUser;
          } else {
            this.uid = null;
            this.authUser = null;
            return authUser;
          }
        })
      );
  }
  changePassword(password: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await updatePassword(this.authUser, password)
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  async beginEmailVerification(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await sendEmailVerification(this.authUser);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  async fetchUser(id: string): Promise<FireRocketUser | null> {
    const doc = this.fireStore
      .collection('users')
      .doc(id)
      .get();
    const data = await firstValueFrom(doc);
    if (!data.data()) {
      return null;
    }
    const user = data.data() as FireRocketUser;
    user.id = data.id as string;
    return user;
  }
  async updateUser(user: FireRocketUser): Promise<void> {
    const userJson = JSON.stringify(user);
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('users')
        .doc(user.id)
        .set(JSON.parse(userJson))
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
}
