import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Channel} from "../../models/channel";
import {Course} from "../../models/course";
import {ContentFrame, ExamAnswerTypes} from "../../models/course-frames";
import {ContentFragment, CourseProgress, Progress} from "../../models/course-progress";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {storeHeaderLogoSrc, switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {FormControl} from "@angular/forms";
import {nanoid} from "nanoid";
import {DateFormatFirestorePipe} from "../../pipes/date-format-firestore/date-format-firestore.pipe";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Account, AccountType} from "../../models/account";
import {environment} from "../../../environments/environment";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {ChannelSubscription} from "../../models/channel-subscription";
import {CourseExamAnswersService} from "../../services/course-exam-answers/course-exam-answers.service";
import {ExamAnswer, ExamAnswers, ExamAnswerStatus} from "../../models/answer-exam";
import {
  CourseExaminationFramesService
} from "../../services/course-examination-frames/course-examination-frames.service";
import {registerables} from "chart.js";
import Chart from 'chart.js/auto';
Chart.register(...registerables);

@Component({
  selector: 'app-manager-channel-course-survey-view',
  templateUrl: './manager-channel-course-examination-view.component.html',
  styleUrls: ['./manager-channel-course-examination-view.component.scss']
})
export class ManagerChannelCourseExaminationViewComponent implements OnInit{
  forbidden: boolean = false;
  channelId: string;
  slug: string;
  courseId: string;
  partId: string;
  userId: string;
  sessionsSubscription: Subscription;
  channelSubscriptionStatus: ChannelSubscription;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  currentCourse: Course = new Course();
  examFrames: ContentFrame;
  currentProgress: CourseProgress;
  isNotFound = false;
  fragmentIndex: number;
  surveyFragmentLength: number;
  surveyFragments: ContentFragment[] = [];
  examAnswers: ExamAnswers[] = [];
  latestAnswers: ExamAnswers;
  forms: FormControl[] = [];
  isOpenHistoryModal = false;
  isHistoryMode = true;
  accountType = AccountType;
  // チャート
  chart: any;
  colors: string[] = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 205, 86)'
  ];
  protected readonly examType = ExamAnswerTypes;
  protected readonly answerTypes = ExamAnswerTypes;
  protected readonly answerStatus = ExamAnswerStatus;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private accountService: AccountsService,
    private courseProgressesService: CourseProgressesService,
    private courseExaminationFramesService: CourseExaminationFramesService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private courseExamAnswersService: CourseExamAnswersService,
    private channelSubscriptionsService: ChannelSubscriptionsService,
  ) {
    window.scrollTo(0, 0)
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.partId = this.route.snapshot.paramMap.get('partId');
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnDestroy(): Promise<void> {}
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.user.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    this.currentChannel = await this.channelsService.fetchBySlug(this.slug);
    this.channelId = this.currentChannel?.id;
    this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel?.accountId);
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(this.currentChannel?.id, this.authService.uid);
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    // 権限を確認
    if (this.currentChannel.accountId !== this.authService.user.currentAccountId) {
      console.log('404 権限無し');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // OEMロゴ対応
    if (this.channelOwnerAccount.accountType === this.accountType.oem && this.channelOwnerAccount?.logoImage?.src
    ) {
      this.uiStore.dispatch(storeHeaderLogoSrc(this.channelsService.getLogoImageUrl(this.channelOwnerAccount.logoImage.src)));
    } else {
      this.uiStore.dispatch(storeHeaderLogoSrc(environment.productMainLogoSrc));
    }
    // コンテンツデータ読み込み
    this.examFrames = await this.courseExaminationFramesService.fetch(
      this.currentChannel.accountId,
      this.courseId,
      this.partId
    );
    if (!this.examFrames) {
      console.log('404 コンテンツデータが読み込めない（存在しない）');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (this.examFrames.examinationFrame.questions.length === 0) {
      console.log('404 設問が未作成');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    this.buildForm();
    // 回答データを取得
    this.examAnswers = await this.courseExamAnswersService.fetchByUser(
      this.courseId,
      this.examFrames.contentKey,
      this.userId
    );
    const datePipe = new DateFormatFirestorePipe();
    const today = new Date();
    if (datePipe.transform(this.examAnswers[0].updatedAt) === datePipe.transform(today)) {
      this.latestAnswers = JSON.parse(JSON.stringify(this.examAnswers[0]));
      this.fillFormValue();
    } else {
      this.latestAnswers = JSON.parse(JSON.stringify(this.examAnswers[0]));
      this.latestAnswers.id = nanoid();
      this.fillFormValue();
    }
    // コース進捗データ取得
    this.courseProgressesService.fetchByMy(
      this.courseId,
      this.authService.uid
    )
      .then(async (courseProgress) => {
        if (courseProgress && courseProgress.progresses) {
          this.currentProgress = courseProgress;
          const index = courseProgress.progresses.findIndex(item => item.contentKey === this.partId);
          let currentIndex = index;
          if (index === -1) {
            currentIndex = this.currentProgress.progresses.length;
            this.currentProgress.progresses[currentIndex] = new Progress();
            this.currentProgress.progresses[currentIndex].contentKey = this.partId;
            this.currentProgress.progresses[currentIndex] = this.fixProgress(courseProgress.progresses[currentIndex]);
          } else {
            if (this.examFrames.examinationFrame.questions.length !== courseProgress.progresses[currentIndex].fragment.length) {
              this.currentProgress.progresses[currentIndex] = this.fixProgress(courseProgress.progresses[currentIndex]);
            }
          }
          this.surveyFragments = this.currentProgress.progresses[currentIndex].fragment;
          this.surveyFragmentLength = this.currentProgress.progresses[currentIndex].fragment.length;
          this.fragmentIndex = currentIndex;
        }
      })
    this.uiStore.dispatch(switchFalseLoading());
    this.buildChart('resultChart').then();
  }
  fixProgress(progress: Progress): Progress {
    progress.fragment = [];
    let c = 0;
    for (let i = 0; i < this.examFrames.examinationFrame.questions.length; i++) {
      const fragment = new ContentFragment();
      fragment.pointSec = 0;
      fragment.viewed = false;
      progress.fragment.push(fragment);
    }
    return progress;
  }
  async backToUsers(): Promise<void> {
    await this.router.navigate([`/manager/channel/course/users/${this.currentChannel.id}/${this.currentCourse.id}`]);
    return;
  }
  buildForm(): void {
    this.forms = [];
    for (const q of this.examFrames.examinationFrame.questions) {
      const form = new FormControl<string|null>(null,[]);
      this.forms.push(form);
    }
  }
  fillFormValue(): void {
    let c = 0;
    for (const q of this.examFrames.examinationFrame.questions) {
      switch (q.type) {
        case this.answerTypes.radio:
          this.forms[c].setValue(this.latestAnswers.answers[c].answer);
          break;
        case this.answerTypes.check:
          break;
      }
      c++;
    }
  }
  buildAnswers(): void {
    for (const q of this.examFrames.examinationFrame.questions) {
      const answer = new ExamAnswer();
      answer.contentKey = q.contentKey;
      answer.type = q.type;
      answer.status = this.answerStatus.noAnswer;
      this.latestAnswers.answers.push(answer);
    }
  }
  checkboxIsChecked(index: number, value: string): boolean {
    if (!this.latestAnswers) {
      return false;
    }
    return this.latestAnswers.answers[index].answer.findIndex(a => a === value) !== -1;
  }
  countNumOfAnswered(): number {
    if (!this.latestAnswers) {
      return 0;
    }
    let c = 0;
    for (const a of this.latestAnswers.answers) {
      if (a.status !== this.answerStatus.noAnswer) {
        c++
      }
    }
    return c;
  }
  countNumOfAnsweredForHistory(index: number): number {
    if (!this.examAnswers[index]) {
      return 0;
    }
    let c = 0;
    for (const a of this.examAnswers[index].answers) {
      if (a.status !== this.answerStatus.noAnswer) {
        c++
      }
    }
    return c;
  }
  openHistoryModal(): void {
    this.isOpenHistoryModal = true;
  }
  closeHistoryModal(): void {
    this.isOpenHistoryModal = false;
  }
  async showHistory(index: number): Promise<void> {
    this.latestAnswers = JSON.parse(JSON.stringify(this.examAnswers[index]));
    this.closeHistoryModal();
  }
  answerUpdate(contentKey: string, value: string, e: any): void {
    const aIndex = this.latestAnswers.answers.findIndex(a => a.contentKey === contentKey);
    this.latestAnswers.answers[aIndex].answer[0] = this.latestAnswers.answers[aIndex].answer[0];
    if (this.examFrames.examinationFrame.questions[aIndex].type === this.answerTypes.check) {
      e.currentTarget.checked = this.latestAnswers.answers[aIndex].answer.findIndex(a => a === value) !== -1;
      return;
    } else {
      this.forms[aIndex].setValue(this.latestAnswers.answers[aIndex].answer[0]);
      return;
    }
  }
  getCorrectRate(): number {
    return Math.round((this.getNumOfCorrect() / this.examFrames.examinationFrame.questions.length) * 100);
  }
  getNumOfCorrect(): number {
    return  this.latestAnswers?.answers.filter(a => a.isCorrect === true).length;
  }
  async buildChart(id: string): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, 1000));
    const element = document.getElementById(id) as HTMLCanvasElement;
    if (!element) {
      return;
    }
    const contextRadio = element.getContext('2d');
    const params: { labels: string[], counter: number[] } = {
      labels: ['正解','不正解','未回答'],
      counter: [
        this.latestAnswers.answers.filter(a => a.isCorrect === true).length,
        this.latestAnswers.answers.filter(a => (a.isCorrect === false && a.answer.length > 0)).length,
        this.latestAnswers.answers.filter(a => (a.isCorrect === false && a.answer.length === 0)).length,
      ]
    };
    this.chart = new Chart(
      contextRadio,
      {
        type: 'doughnut',
        data: {
          labels: params.labels,
          datasets: [{
            label: '',
            data: params.counter,
            backgroundColor: this.colors,
            hoverOffset: 4
          }]
        },
        options: {},
        plugins: []
      }
    );
  }

}
