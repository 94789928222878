<app-header-contents-channel></app-header-contents-channel>
<app-not-found *ngIf="isNotFound"></app-not-found>
<div
  *ngIf="!isNotFound"
  class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <app-notification></app-notification>
  <div class="l-content-front__inner">
    <div class="l-top">
      <div class="p-courseTop">
        <h1 class="p-courseTop__subTitle">{{currentCourse?.name}}</h1>
        <div class="p-courseTop__head">
          <div class="p-courseTop__head__kv">
            <img
              *ngIf="!currentCourse?.eyeCatchImage?.src"
              ngSrc="no-image"
              width="480"
              height="270"
              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
              alt="">
            <img
              *ngIf="currentCourse?.eyeCatchImage?.src"
              ngSrc="{{currentCourse?.eyeCatchImage?.src}}"
              width="480"
              height="270"
              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
              alt="">
          </div>
          <div class="p-courseTop__head__meta">
            <div class="p-courseTop__head__meta__progress">
              <div class="p-courseTop__head__meta__progress__subTitle" translate>受講完了率</div>
              <div class="p-courseTop__head__meta__progress__value">{{getCourseProgress()}}<span>%</span></div>
              <div class="p-progress p-progress-h12px">
                <div class="p-progress__bar">
                  <div class="p-progress__bar__bg"></div>
                  <div class="p-progress__bar__body" [style]="getCourseProgressStyle()"></div>
                </div>
              </div>
            </div>
            <div class="p-courseTop__head__meta__actionButtons">
              <!--<button class="p-courseTop__head__meta__actionButtons__good">123</button>-->
              <!--<button class="p-courseTop__head__meta__actionButtons__bookMark">ブックマーク</button>-->
              <!--<button class="p-courseTop__head__meta__actionButtons__share">共有</button>-->
            </div>
            <ul class="p-courseTop__head__meta__notes">
              <!--<li>代理申込済</li>
              <li>キャンセル不可</li>-->
            </ul>
            <div class="p-courseTop__head__meta__user">
              <div class="p-user">
                <div class="p-user__avatar p-user__avatar-middle">
                  <img
                    *ngIf="!currentChannel?.profileImage?.src"
                    ngSrc="no-image"
                    width="160"
                    height="90"
                    ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                    alt="">
                  <img
                    *ngIf="currentChannel?.profileImage?.src"
                    ngSrc="{{currentChannel?.profileImage?.src}}"
                    width="160"
                    height="90"
                    ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                    alt="">
                </div>
                <div class="p-user__profile p-user__profile-courseTop">
                  <div class="p-user__profile__name">
                    {{currentChannel?.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="tagPayload?.length > 0" class="p-courseTop__head__tags">
            <ng-container *ngFor="let tag of tagPayload">
              <div routerLink="/tag/{{tag?.name}}" class="p-courseTop__head__tags__item"><span>#{{tag?.name}}</span></div>
            </ng-container>
          </div>
          <div class="p-courseTop__head__detail">
            <div class="p-courseTop__head__detail__text" [innerHTML]="currentCourse?.description | nl2br">
            </div>
            <!--<button class="p-courseTop__head__detail__moreButton">もっと見る</button>-->
          </div>
          <div class="p-courseTop__button">
            <button routerLink="/channel/{{slug}}" class="c-button c-button-primary"><span translate>チャンネルトップへ</span></button>
          </div>
        </div>
        <h1 class="p-courseTop__title" translate>目次</h1>
        <ng-container *ngFor="let chapter of currentCourse?.courseIndex?.chapters; index as chapterIndex">
          <div id="chapter-{{chapterIndex + 11}}" class="p-courseTop__lectureList">
            <div class="p-courseTop__lectureList__section">

              <div class="p-courseTop__lectureList__section__head">
                <div class="p-courseTop__lectureList__section__head__title">{{chapter.name}}</div>
                <div
                  *ngIf="getChapterProgress(chapterIndex) === '100'"
                  class="p-courseTop__lectureList__section__head__badge p-courseTop__lectureList__section__head__badge-gold"></div>
              </div>
              <ng-container *ngFor="let section of chapter?.sections;index as sectionIndex">
                <div class="p-courseTop__lectureList__section__lectures">
                  <input
                    *ngIf="section?.parts?.length > 1"
                    type="checkbox"
                    id="toggle-c{{chapterIndex}}-s{{sectionIndex}}"
                    class="p-courseTop__lectureList__section__lectures__opener"
                  >
                  <label
                    *ngIf="section?.parts?.length > 1"
                    for="toggle-c{{chapterIndex}}-s{{sectionIndex}}"
                    class="p-courseTop__lectureList__section__lectures__toggle"
                  ></label>
                  <div class="p-courseTop__lectureList__section__lectures__top" [ngClass]="section?.parts.length === 1 ? 'p-courseTop__lectureList__section__lectures__withoutToggle' : '' ">
                    <div class="p-lecture">
                      <div class="p-lecture__body">
                        <div class="p-lecture__body__thumb">
                          <img
                            *ngIf="!frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frame?.eyeCatchImage?.src"
                            ngSrc="no-image"
                            width="160"
                            height="90"
                            ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                            alt="">
                          <img
                            *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frame?.eyeCatchImage?.src"
                            ngSrc="{{frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frame?.eyeCatchImage?.src}}"
                            width="160"
                            height="90"
                            ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                            alt="">
                          <!--                      <div class="p-lecture__body__thumb__icon p-lecture__body__thumb__icon-file"></div>-->
                          <!--                      <div class="p-lecture__body__thumb__icon p-lecture__body__thumb__icon-clock"></div>-->
                          <div class="p-lecture__body__thumb__status"></div>

                          <div *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.CoverFrame && getSectionProgress(chapterIndex, sectionIndex) === '100'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-completed" translate>完了</div>
                          <div *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.CoverFrame && getPartProgress(frames[chapterIndex]?.sections[sectionIndex]?.parts[0].id) === '100'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-completed" translate>完了</div>

                          <div *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.CoverFrame && getSectionProgress(chapterIndex, sectionIndex) !== '100'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-started" translate>受講途中</div>
                          <div *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.CoverFrame && getPartProgress(frames[chapterIndex]?.sections[sectionIndex]?.parts[0].id) !== '100'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-started" translate>受講途中</div>

                          <div *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.CoverFrame && getSectionProgress(chapterIndex, sectionIndex) === '0'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-notStarted" translate>未受講</div>
                          <div *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.CoverFrame && getPartProgress(frames[chapterIndex]?.sections[sectionIndex]?.parts[0].id) === '--'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-notStarted" translate>未受講</div>

                        </div>
                        <div class="p-lecture__body__main">
                          <div class="p-lecture__body__main__head">
                            <!--<div class="p-lecture__body__main__head__notice">
                              <div class="p-lecture__body__main__head__notice__heading">提出期限</div>
                              <div class="p-lecture__body__main__head__notice__body">2023年6月30日</div>
                            </div>-->
                            <div
                              *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.CoverFrame && getSectionProgress(chapterIndex, sectionIndex) === '100'"
                              class="p-lecture__body__main__head__badge p-lecture__body__main__head__badge-gold"></div>
                            <div
                              *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.CoverFrame && getPartProgress(frames[chapterIndex]?.sections[sectionIndex]?.parts[0].id) === '100'"
                              class="p-lecture__body__main__head__badge p-lecture__body__main__head__badge-gold"></div>
                          </div>
                          <div class="p-lecture__body__main__title">{{frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frame?.name}}</div>
                          <div class="p-lecture__body__main__progress">
                            <div class="p-progress">
                              <div class="p-progress__bar">
                                <div class="p-progress__bar__bg"></div>
                                <div
                                  *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frameType === frameTypes.CoverFrame"
                                  class="p-progress__bar__body" [style]="getSectionProgressStyle(chapterIndex, sectionIndex)"></div>
                                <div
                                  *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frameType !== frameTypes.CoverFrame"
                                  class="p-progress__bar__body" [style]="getPartProgressStyle(frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.id)"></div>
                              </div>
                              <div
                                *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frameType === frameTypes.CoverFrame"
                                class="p-progress__value">{{getSectionProgress(chapterIndex, sectionIndex)}}%</div>
                              <div
                                *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0]?.frameType !== frameTypes.CoverFrame"
                                class="p-progress__value">{{getPartProgress(frames[chapterIndex]?.sections[sectionIndex]?.parts[0].id)}}%</div>
                            </div>
                          </div>
                        </div>
                        <div class="p-lecture__body__button">
                          <div class="p-lecture__body__button__inner">
                            <ng-container *ngIf="section?.parts?.length">
                              <!--<button
                                *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.CoverFrame"
                                (click)="openAccordion(chapterIndex, sectionIndex)"
                                type="button" class="c-button c-button-openChapter">開く</button>-->
                              <ng-container *ngIf="isValidFrame(frames[chapterIndex]?.sections[sectionIndex]?.parts[0])">
                                <button
                                  *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.FileFrame
                                && frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.SurveyFrame
                                && frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType !== frameTypes.CoverFrame"
                                  (click)="moveContent(frames[chapterIndex]?.sections[sectionIndex].parts[0])"
                                  type="button"
                                >
                                  <div class="p-lecture__body__button__inner__dummy">
                                    <div class="c-button c-button-play"><span translate>受講する</span></div>
                                  </div>
                                </button>
                                <button
                                  *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.SurveyFrame"
                                  (click)="moveContent(frames[chapterIndex]?.sections[sectionIndex].parts[0])"
                                  type="button"
                                >
                                  <div class="p-lecture__body__button__inner__dummy">
                                    <div class="c-button c-button-play"><span translate>回答する</span></div>
                                  </div>
                                </button>
                                <button
                                  *ngIf="frames[chapterIndex]?.sections[sectionIndex]?.parts[0].frameType === frameTypes.FileFrame"
                                  (click)="downloadFile(frames[chapterIndex]?.sections[sectionIndex].parts[0])"
                                  type="button"
                                >
                                  <div class="p-lecture__body__button__inner__dummy">
                                    <div class="c-button c-button-primary"><span translate>ダウンロード</span></div>
                                  </div>
                                </button>
                              </ng-container>


                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <!--                <div class="p-lecture__msg">-->
                      <!--                  <div class="p-lecture__msg__type">回答</div>-->
                      <!--                  <div class="p-lecture__msg__subject">ケンコー先生からメッセージが届いています。</div>-->
                      <!--                  <div class="p-lecture__msg__status">不受理</div>-->
                      <!--                  <div class="p-lecture__msg__button">-->
                      <!--                    <button type="button" class="c-button c-button-primary">確認する</button>-->
                      <!--                  </div>-->
                      <!--                </div>-->
                    </div>
                  </div>
                  <div class="p-courseTop__lectureList__section__lectures__contents">
                    <ng-container *ngFor="let frame of frames[chapterIndex]?.sections[sectionIndex]?.parts;index as partIndex">
                      <ng-container *ngIf="partIndex !== 0">
                        <div class="p-lecture">
                          <div class="p-lecture__body">
                            <div class="p-lecture__body__thumb">

                              <img
                                *ngIf="!frame?.frame?.eyeCatchImage?.src"
                                ngSrc="no-image"
                                width="160"
                                height="90"
                                ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                                alt="">
                              <img
                                *ngIf="frame?.frame?.eyeCatchImage?.src"
                                ngSrc="{{frame?.frame?.eyeCatchImage?.src}}"
                                width="160"
                                height="90"
                                ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                                alt="">
                              <!--                      <div class="p-lecture__body__thumb__icon p-lecture__body__thumb__icon-file"></div>-->
                              <!--                      <div class="p-lecture__body__thumb__icon p-lecture__body__thumb__icon-clock"></div>-->

                              <div *ngIf="getPartProgress(frame.id) === '100'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-completed" translate>完了</div>
                              <div *ngIf="getPartProgress(frame.id) !== '100'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-started" translate>受講途中</div>
                              <div *ngIf="getPartProgress(frame.id) === '--'" class="p-lecture__body__thumb__status p-lecture__body__thumb__status-notStarted" translate>未受講</div>
                            </div>
                            <div class="p-lecture__body__main  p-lecture__body__main-child">
                              <div class="p-lecture__body__main__head">
                                <div
                                  *ngIf="getPartProgress(frame.id) === '100'"
                                  class="p-lecture__body__main__head__badge p-lecture__body__main__head__badge-gold"></div>
                                <!--                      <div class="p-lecture__body__main__head__notice">-->
                                <!--                        <div class="p-lecture__body__main__head__notice__heading">提出期限</div>-->
                                <!--                        <div class="p-lecture__body__main__head__notice__body">2023年6月30日</div>-->
                                <!--                      </div>-->
                              </div>
                              <div class="p-lecture__body__main__title">{{frame?.frame?.name}}</div>
                              <div class="p-lecture__body__main__progress">
                                <div class="p-progress">
                                  <div class="p-progress__bar">
                                    <div class="p-progress__bar__bg"></div>
                                    <div class="p-progress__bar__body" [style]="getPartProgressStyle(frame?.id)"></div>
                                  </div>
                                  <div class="p-progress__value">{{getPartProgressForBar(frame?.id)}}%</div>
                                </div>
                              </div>
                            </div>
                            <div class="p-lecture__body__button">
                              <div class="p-lecture__body__button__inner">
                                <ng-container *ngIf="isValidFrame(frame)">
                                  <button
                                    *ngIf="frame.frameType !== frameTypes.CoverFrame && frame.frameType !== frameTypes.SurveyFrame && frame.frameType !== frameTypes.FileFrame"
                                    (click)="moveContent(frame)"
                                    type="button"
                                  >
                                    <div class="p-lecture__body__button__inner__dummy">
                                      <div class="c-button c-button-play"><span translate>受講する</span></div>
                                    </div>
                                  </button>
                                  <button
                                    *ngIf="frame.frameType === frameTypes.SurveyFrame"
                                    (click)="moveContent(frame)"
                                    type="button"
                                  >
                                    <div class="p-lecture__body__button__inner__dummy">
                                      <div class="c-button c-button-play"><span translate>回答する</span></div>
                                    </div>
                                  </button>
                                  <button
                                    *ngIf="frames[chapterIndex]?.sections[sectionIndex].parts[partIndex].frameType === 'fileFrame'"
                                    (click)="downloadFile(frame)"
                                    type="button"
                                  >
                                    <div class="p-lecture__body__button__inner__dummy">
                                      <div class="c-button c-button-primary"><span translate>ダウンロード</span></div>
                                    </div>
                                  </button>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <!--                <div class="p-lecture__msg">-->
                          <!--                  <div class="p-lecture__msg__type">回答</div>-->
                          <!--                  <div class="p-lecture__msg__subject">ケンコー先生からメッセージが届いています。</div>-->
                          <!--                  <div class="p-lecture__msg__status">不受理</div>-->
                          <!--                  <div class="p-lecture__msg__button">-->
                          <!--                    <button type="button" class="c-button c-button-primary">確認する</button>-->
                          <!--                  </div>-->
                          <!--                </div>-->
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <!--<div class="p-courseTop__lectureList__section__button">
                  <button type="button" class="c-button c-button-primary">認定書PDF提出</button>
                </div>-->

              </ng-container>


            </div>

          </div>


        </ng-container>
        <div class="p-courseTop__button">
          <button routerLink="/channel/{{slug}}" class="c-button c-button-primary"><span translate>チャンネルトップへ</span></button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="channelOwnerAccount?.accountType === accountType.general"></app-footer>
<app-footer-business *ngIf="channelOwnerAccount?.accountType === accountType.oem"></app-footer-business>
