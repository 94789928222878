import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {categories, Category} from "../../models/categories";
import {Course} from "../../models/course";
import {MonetizeMethods, monetizeMethods} from "../../models/monetize-methods";
import {publishRanges, PublishRangesKeys} from "../../models/publish-range";
import {map} from "rxjs/operators";
import {Channel} from "../../models/channel";
import {
  storeUrlState,
  switchFalseLoading, switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {CoursesService} from "../../services/courses/courses.service";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {environment} from "../../../environments/environment";
import {ImageConverterService} from "../../services/image-converter/image-converter.service";
import {Tag} from "../../models/tag";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {VideoUploaderService} from "../../services/video-uploader/video-uploader.service";
import {ContentTagsService} from "../../services/content-tags/content.tags.service";
import {PublicImage} from "../../models/public-image";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-course-meta',
  templateUrl: './manager-channel-course-meta.component.html',
  styleUrls: ['./manager-channel-course-meta.component.scss']
})
export class ManagerChannelCourseMetaComponent implements OnInit {
  forbidden: boolean = false;
  isNotFound = false;
  private channelSubscription: Subscription;
  private courseSubscription: Subscription;
  private tagsSubscription: Subscription;
  channelId: string;
  courseId: string;
  edited: boolean = false;
  formCategories: Category[]  = categories;
  formMonetizeMethods = monetizeMethods;
  formPublishRanges = publishRanges;
  currentChannel: Channel = new Channel();
  currentCourse: Course = new Course();
  baseUrl: string = environment.hostName;
  prImagesIndex = 0;
  sampleVideoIndex = 0;
  isOpenTagModal = false;
  isOpenEyeCatchImageUploadModal = false;
  isOpenPrImageImageUploadModal = false;
  isOpenSampleVideoModal = false;
  isOpenSampleVideoUploadModal = false;
  isOpenSampleVideoReplacedModal = false;
  isOpenSampleVideoEyeCatchImageUploadModal = false;
  tagListAsync = this.contentTagsService.tags;
  tagList: Tag[] = [];
  tagPayload: Tag[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private toasterService: ToastrService,
    private authService: AuthService,
    private imageConverterService: ImageConverterService,
    private videoUploaderService: VideoUploaderService,
    private contentTagsService: ContentTagsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId, this.courseId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
    this.uiStore.dispatch(switchTrueLoading());
  }
  categoryId = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  name = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.maxLength(100),
      Validators.minLength(4),
    ]
  });
  description = new FormControl<string|null>(null, {
    validators: [
      Validators.maxLength(2000),
      Validators.minLength(8),
    ]
  });
  tags = new FormControl<string[]>([], {
    validators: []
  });
  tagKeyword = new FormControl<string|null>(null, {
    validators: []
  });
  published = new FormControl<boolean>(false, {
    validators: []
  });
  monetized = new FormControl<boolean>(false, {
    validators: []
  });
  monetizeMethod = new FormControl<MonetizeMethods|null>(null, {
    validators: []
  });
  unitPrice = new FormControl<number>(0, {
    validators: [
      Validators.min(0),
      Validators.max(999999)
    ]
  });
  courseSubscriptionPrice = new FormControl<number>(0, {
    validators: [
      Validators.min(0),
      Validators.max(999999)
    ]
  });
  publishRange = new FormControl<PublishRangesKeys>(null, {
    validators: []
  });
  useReminder = new FormControl<boolean>(false, {
    validators: []
  });
  protected readonly publishRanges = publishRanges;
  form = this.formBuilder.group({
    categoryId: this.categoryId,
    name: this.name,
    description: this.description,
    tags: this.tags,
    published: this.published,
    monetized: this.monetized,
    monetizeMethod: this.monetizeMethod,
    unitPrice: this.unitPrice,
    courseSubscriptionPrice: this.courseSubscriptionPrice,
    publishRange: this.publishRange,
    reminder: this.useReminder,
  });
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await this.channelsService.connectSnapShot(this.channelId);
    this.channelSubscription = this.channelsService.currentChannel
      .pipe(
        map((channel: Channel) => channel)
      )
      .subscribe(async (channel) => {
        if (!channel.slug) {
          await this.router.navigate(['/manager/channels']);
          return;
        }
        this.currentChannel = channel;
        if (this.currentChannel.accountId !== this.authService.user.currentAccountId) {
          this.isNotFound = true;
          this.uiStore.dispatch(switchFalseLoading());
          return ;
        }
        const urlState = new UrlState();
        urlState.channelId = channel.id;
        urlState.slug = channel.slug;
        urlState.courseId = this.courseId;
        this.uiStore.dispatch(storeUrlState({ urlState: urlState}));

      });
    this.coursesService.fetched = [];
    await this.coursesService.connectSnapShot(
      this.courseId,
    );
    this.courseSubscription = this.coursesService.current
      .pipe(
        map((course: Course) => course)
      )
      .subscribe(async (course) => {
        if (!course.name) {
          await this.router.navigate(['/manager/channels']);
          return;
        }
        this.currentCourse = course;
        this.tagPayload = await this.contentTagsService.gatheringTags(course.tags);
        this.formReset();
        this.uiStore.dispatch(switchFalseLoading());
      });
    this.contentTagsService.tags
      .subscribe((tags => {
        this.tagList = tags;
      }))
  }
  ngOnDestroy(): void{
    this.channelSubscription?.unsubscribe();
    this.courseSubscription?.unsubscribe();
    this.tagsSubscription?.unsubscribe();
  }
  formReset(): void {
    this.categoryId.setValue(this.currentCourse.categoryId);
    this.name.setValue(this.currentCourse.name);
    this.description.setValue(this.currentCourse.description);
    this.tags.setValue(this.currentCourse.tags);
    this.published.setValue(this.currentCourse.published);
    this.monetized.setValue(this.currentCourse.monetized);
    this.monetizeMethod.setValue(this.currentCourse.monetizeMethod);
    this.unitPrice.setValue(this.currentCourse.unitPrice);
    this.courseSubscriptionPrice.setValue(this.currentCourse.courseSubscriptionPrice);
    this.publishRange.setValue(this.currentCourse.publishRange);
    this.useReminder.setValue(this.currentCourse?.useReminder);
  }
  async uploadImageEyeCatch(evt: any): Promise<void> {
    const file: File = evt.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toasterService.warning(this.translateService.instant('jpg形式、またはpng形式の画像をアップロードしてください。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.currentCourse.eyeCatchImage = await this.imageConverterService.uploadPublicImage(
      this.courseId,
      this.currentCourse.accountId,
      file,
      this.authService.uid,
      this.authService.idToken
    );
    await this.save();
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    this.closeEyeCatchImageUploadModal();
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeImageEyeCatch(): Promise<void> {
    if (!window.confirm(this.translateService.instant('画像を削除します。よろしいですか？'))) {
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    await this.imageConverterService.removePublicImage(
      this.currentCourse.eyeCatchImage,
      this.authService.idToken
    );
    this.currentCourse.eyeCatchImage = new PublicImage();
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeImage(prImagesIndex: number): Promise<void> {
    if (!window.confirm(this.translateService.instant('画像を削除します。よろしいですか？'))) {
      return;
    }
    this.prImagesIndex = prImagesIndex;
    this.uiStore.dispatch(switchTrueLoading());
    await this.authService.checkIdTokenExpire();
    await this.imageConverterService.removePublicImage(
      this.currentCourse.prImages[this.prImagesIndex],
      this.authService.idToken
    );
    this.currentCourse.prImages = this.currentCourse
      .prImages
      .filter(p => p !== this.currentCourse.prImages[this.prImagesIndex]);
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async uploadImagePrImages(evt: any): Promise<void> {
    const file: File = evt.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toasterService.warning(this.translateService.instant('jpg形式、またはpng形式の画像をアップロードしてください。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    if (!this.currentCourse?.prImages?.length) {
      this.currentCourse.prImages = [];
    }
    this.prImagesIndex = this.currentCourse.prImages.length;
    const files: PublicImage[] = [];
    for (const image of this.currentCourse.prImages) {
      if (image?.src) {
        files.push(image);
      }
    }
    this.currentCourse.prImages = files;
    const newImage = await this.imageConverterService.uploadPublicImage(
      this.courseId,
      this.currentCourse.accountId,
      file,
      this.authService.uid,
      this.authService.idToken
    );
    this.currentCourse.prImages.push(newImage);
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.closePrImageUploadModal();
    this.uiStore.dispatch(switchFalseLoading());
  }
  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.toasterService.warning(this.translateService.instant('未入力または不足している項目があります。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.currentCourse.categoryId = this.categoryId.value;
    this.currentCourse.name = this.name.value;
    this.currentCourse.description = this.description.value;
    this.currentCourse.tags = this.tags.value;
    this.currentCourse.published = this.published.value;
    this.currentCourse.monetized = this.monetized.value;
    this.currentCourse.monetizeMethod = this.monetizeMethod.value;
    this.currentCourse.unitPrice = this.unitPrice.value;
    this.currentCourse.courseSubscriptionPrice = this.courseSubscriptionPrice.value;
    this.currentCourse.publishRange = this.publishRange.value;
    this.currentCourse.useReminder = this.useReminder.value;
    await this.save();
    this.uiStore.dispatch(switchFalseLoading());
    this.toasterService.success(this.translateService.instant('保存しました。', {}));
  }
  async back(): Promise<void> {
    if (this.form.dirty) {
      if (!window.confirm(this.translateService.instant('編集内容を破棄して、前の画面に戻ります。よろしいですか？'))) {
        return;
      }
    }
    await this.router.navigate([`/manager/channel/courses/${this.channelId}/${this.courseId}`]);
    return;
  }
  async save(): Promise<void> {
    await this.coursesService.update(this.currentCourse);
  }
  async uploadSampleVideo(evt: any): Promise<void> {
    // TODO 一般講師コースはコースによりアップ出来る本数に制限を設ける
    if (!this.currentCourse?.samples?.length) {
      this.currentCourse.samples = [];
    }
    this.sampleVideoIndex = this.currentCourse.samples.length + 1;
    this.uiStore.dispatch(switchTrueLoading());
    const file: File = evt.target.files[0];
    if (file && file.size > 100000000) {
      this.toasterService.warning(this.translateService.instant('ファイルのサイズは最大100MBです。長さは60秒程度で解像度は720pを目安にしてください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const acceptMedias = ['video/mp4', 'video/m4v'];
    if (acceptMedias.indexOf(file.type) === -1) {
      this.toasterService.warning(this.translateService.instant('mp4またはm4vファイルをUPLOADしてください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const newVideoFrame = await this.videoUploaderService.uploadVideo(
      this.channelId,
      this.currentCourse.accountId,
      'sample',
      file,
    );
    newVideoFrame.eyeCatchImage = null;
    this.currentCourse.samples.push(newVideoFrame);
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenSampleVideoUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async uploadSampleVideoReplace(evt: any): Promise<void> {
    if (!this.currentCourse?.samples?.length) {
      this.currentCourse.samples = [];
    }
    this.sampleVideoIndex = this.currentCourse.samples.length + 1;
    this.uiStore.dispatch(switchTrueLoading());
    const file: File = evt.target.files[0];
    if (file && file.size > 100000000) {
      this.toasterService.warning(this.translateService.instant('ファイルのサイズは最大100MBです。長さは60秒程度で解像度は720pを目安にしてください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const acceptMedias = ['video/mp4', 'video/m4v'];
    if (acceptMedias.indexOf(file.type) === -1) {
      this.toasterService.warning(this.translateService.instant('mp4またはm4vファイルをUPLOADしてください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const newVideoFrame = await this.videoUploaderService.uploadVideo(
      this.channelId,
      this.currentCourse.accountId,
      'sample',
      file,
    );
    this.currentCourse.samples.push(newVideoFrame);
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenSampleVideoUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeSampleVideo(targetIndex: number): Promise<void> {
    if (!window.confirm(this.translateService.instant('サンプル動画を削除します。よろしいですか？'))) {
      return;
    }
    this.sampleVideoIndex = targetIndex;
    this.uiStore.dispatch(switchTrueLoading());

    await this.imageConverterService.removePublicImage(
      this.currentCourse.samples[targetIndex].eyeCatchImage,
      this.authService.idToken
    );
    await this.videoUploaderService.removeSampleVideo(
      this.channelId,
      this.currentCourse.accountId,
      this.currentCourse.samples[targetIndex]
    );
    this.currentCourse.samples = this.currentCourse.samples.filter(v => v.contentKey !== this.currentCourse.samples[this.sampleVideoIndex].contentKey);
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async uploadSampleVideoImageEyeCatch(evt: any): Promise<void> {
    const file: File = evt.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toasterService.warning(this.translateService.instant('jpg形式、またはpng形式の画像をアップロードしてください。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.currentCourse.samples[this.sampleVideoIndex].eyeCatchImage = await this.imageConverterService.uploadPublicImage(
      this.courseId,
      this.currentCourse.accountId,
      file,
      this.authService.uid,
      this.authService.idToken
    );
    await this.save();
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    this.closeSampleVideoEyeCatchModal();
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeSampleEyeCatchImage(): Promise<void> {
    if (!window.confirm(this.translateService.instant('画像を削除します。よろしいですか？'))) {
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    await this.imageConverterService.removePublicImage(
      this.currentCourse.samples[this.sampleVideoIndex].eyeCatchImage,
      this.authService.idToken
    );
    this.currentCourse.samples[this.sampleVideoIndex].eyeCatchImage = new PublicImage();
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async searchTags(): Promise<void> {
    if (!this.tagKeyword.value) {
      this.tagList = [];
      this.contentTagsService.tagList = [];
      return;
    }
    this.tagList = [];
    this.contentTagsService.tags = new BehaviorSubject<Tag[] | undefined>([]);
    await this.contentTagsService.searchTagsByKeyword(this.tagKeyword.value);
    this.tagList = this.contentTagsService.tagList;
  }
  canAddTag(): boolean {
    if (!this.tagKeyword.value) {
      return false;
    }
    if (!this.tagList) {
      return false;
    }
    const i = this.tagList.findIndex( t => t.name === this.tagKeyword.value);
    const n = this.tagPayload.findIndex( t => t.name === this.tagKeyword.value);
    return i == -1 && n == -1 && this.tagKeyword.value.length > 4;
  }
  closeTagModal(): void {
    this.tagList = [];
  }
  async addContentTag(): Promise<void> {
    if (!window.confirm(`${this.translateService.instant('ハッシュタグ')} ${this.tagKeyword.value} ${this.translateService.instant('を追加しますか？')}`)) {
      return;
    }
    const newTag = new Tag();
    newTag.name = this.tagKeyword.value;
    newTag.createdBy = this.authService.uid;
    const id = await this.contentTagsService.create(newTag);
    newTag.id = id;
    const tags = this.tags.value;
    tags.push(id);
    this.tags.setValue(tags);
    this.tagPayload.push(newTag);
  }
  async pushContentTag(tag: Tag): Promise<void> {
    const tags = this.tags.value;
    if (tags.indexOf(tag.id) !== -1) {
      return;
    }
    tags.push(tag.id);
    this.tags.setValue(tags);
    this.tagPayload.push(tag);
  }
  async removeContentTag(tag: Tag): Promise<void> {
    const tags = this.tags.value;
    const removed = tags.filter(t => t !== tag.id);
    this.tags.setValue(removed);
    const removed2 = this.tagPayload.filter(t => t !== tag);
    this.tagPayload = removed2;
  }
  openEyeCatchImageUploadModal(): void {
    this.isOpenEyeCatchImageUploadModal = true;
  }
  closeEyeCatchImageUploadModal(): void {
    this.isOpenEyeCatchImageUploadModal = false;
  }
  openPrImageUploadModal(): void {
    this.isOpenPrImageImageUploadModal = true;
  }
  closePrImageUploadModal(): void {
    this.isOpenPrImageImageUploadModal = false;
  }
  openVideoUploadModal(): void {
    this.isOpenSampleVideoUploadModal = true;
  }
  closeVideoUploadModal(): void {
    this.isOpenSampleVideoUploadModal = false;
  }
  openSampleVideoUploadModal(): void {
    this.isOpenSampleVideoUploadModal = true;
  }
  openSampleEyeCatchImageUploadModal(): void {
    this.isOpenSampleVideoEyeCatchImageUploadModal = true;
  }
  closeSampleVideoEyeCatchModal(): void {
    this.isOpenSampleVideoEyeCatchImageUploadModal = false;
  }
  openSampleVideoReplacedModal(): void {
    this.isOpenSampleVideoReplacedModal = true;
  }
  openSampleVideoModal(index: number): void {
    this.sampleVideoIndex = index;
    this.isOpenSampleVideoModal = true;
  }
  closeSampleVideoModal(): void {
    this.isOpenSampleVideoModal = false;
  }
  clickUploadFile(domId: string): void {
    const file = document
      .getElementById(domId);
    file.click();
  }
  dropSamples(event: CdkDragDrop<string[]>): void {
    console.log(event);
  }
}
