<app-not-found *ngIf="isNotFound"></app-not-found>
<ng-container *ngIf="!isNotFound && isReady">
  <app-header-contents-public></app-header-contents-public>
  <div class="l-top l-top-headerFixed">
    <div class="p-top">
      <div class="p-top__head">
        <div
          class="p-top__head__title"
          [innerHTML]="'学びを\n加速させる！' | translate | nl2br"
        ></div>
      </div>
    </div>

    <div class="p-contentsTop">


      <div class="p-contentsTop__title" translate>今おすすめの講座</div>
      <div class="p-contentsTop__recommend">
        <div class="c-courseList-horizontal">
          <div class="c-courseList-horizontal__head">
            <!--<div class="c-courseList-horizontal__head__more">すべてを見る</div>-->
          </div>
          <app-favorites></app-favorites>
        </div>
      </div>


      <!--<div class="p-contentsTop__info">知識・スキルをシェアするためのLMSです。楽しみながら教材を作成し、販売できます。</div>
      <div class="p-contentsTop__search">
        <div class="p-contentsTop__search__form">
          <input type="text" class="c-form-input" placeholder="コースを検索する">
        </div>
        <div class="p-contentsTop__search__category">
          <div class="p-contentsTop__search__category__title">PICK UP カテゴリー</div>
          <div class="p-contentsTop__search__category__list">
            <div class="p-contentsTop__search__category__list__item">デジタルリテラシー</div>
            <div class="p-contentsTop__search__category__list__item">ビジネス基礎</div>
            <div class="p-contentsTop__search__category__list__item">マネジメント・リーダーシップ</div>
            <div class="p-contentsTop__search__category__list__item">起業・複業・キャリア</div>
            <div class="p-contentsTop__search__category__list__all">すべてのカテゴリー</div>
          </div>
        </div>
      </div>-->
      <div class="p-contentsTop__title" translate>FireRocketの5つの特徴</div>
      <div class="p-contentsTop__features">
        <div class="p-contentsTop__features__item">
          <img src="assets/images/contentsTop_features_01.png" alt="" class="p-contentsTop__features__item__thumb">
          <div
            class="p-contentsTop__features__item__title"
            [innerHTML]="'誰でも講師になれる\n販売システム' | translate | nl2br"
          ></div>
          <div class="p-contentsTop__features__item__textBody" translate>
            専門知識を持つあなた。そのスキルを世界に広げませんか?知識やスキルをオンライン講座にして収益化しましょう。
          </div>
        </div>
        <div class="p-contentsTop__features__item">
          <img src="assets/images/contentsTop_features_02.png" alt="" class="p-contentsTop__features__item__thumb">
          <div
            class="p-contentsTop__features__item__title"
            [innerHTML]="'わかりやすい学びの\n進捗管理' | translate | nl2br"
          ></div>
          <div class="p-contentsTop__features__item__textBody" translate>
            時間も場所も選ばない。学習進捗に合わせたスケジュール管理機能で、学びの見える化が叶います。
          </div>
        </div>
        <div class="p-contentsTop__features__item">
          <img src="assets/images/contentsTop_features_03.png" alt="" class="p-contentsTop__features__item__thumb">
          <div
            class="p-contentsTop__features__item__title"
            [innerHTML]="'多様なコンテンツで\n学ぶ' | translate | nl2br"
          ></div>
          <div class="p-contentsTop__features__item__textBody" translate>
            ビデオ、スライド+オーディオファイル、それぞれのメリットを生かした学習方法で確かな成果が得られます。
          </div>
        </div>
        <div class="p-contentsTop__features__item">
          <img src="assets/images/contentsTop_features_04.png" alt="" class="p-contentsTop__features__item__thumb">
          <div
            class="p-contentsTop__features__item__title"
            [innerHTML]="'講義出席管理と\n理解度テスト' | translate | nl2br"
          ></div>
          <div class="p-contentsTop__features__item__textBody" translate>
            出席状況の把握とともに、習得度を確認できる理解度テストがセット。しっかり身につく知識を築き上げます。
          </div>
        </div>
        <div class="p-contentsTop__features__item">
          <img src="assets/images/contentsTop_features_05.png" alt="" class="p-contentsTop__features__item__thumb">
          <div class="p-contentsTop__features__item__title" translate>法人向け機能が充実</div>
          <div class="p-contentsTop__features__item__textBody" translate>
            一般ユーザとは隔離したセキュアなデータ管理。CSVでの一括処理により、管理工数を削減します。さらに、学習証明証の発行・電子署名添付で、一人ひとりの学びの成果を評価。
          </div>
        </div>

      </div>


      <div
        class="p-contentsTop__title"
        [innerHTML]="'講座を提供したい方・\n講師になりたい方' | translate | nl2br"
      ></div>
      <div class="p-contentsTop__invitation">
        <div class="p-contentsTop__invitation__kv">
          <div class="p-contentsTop__invitation__kv__bg"></div>
          <div class="p-contentsTop__invitation__kv__image">
            <img src="assets/images/contentsTop_invitation.png" alt="">
          </div>
        </div>
        <div class="p-contentsTop__invitation__textBody" translate>
          あなた自身がコンテンツを作成・販売することもできます。教えることも学ぶことも、あなた次第!
        </div>
        <div class="p-contentsTop__invitation__moreButton">
          <!--<button class="c-button c-button-primary">詳しくはこちら</button>-->
        </div>
      </div>



    </div>
  </div>
  <app-footer></app-footer>
</ng-container>
