export enum roleNames {
  deny = 'deny',
  educator = 'educator',
  viewer = 'viewer',
  creator = 'creator',
  manager = 'manager',
  admin = 'admin'
}
export interface RoleInterface {
  uid?: string;
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  [propertyNames: string]: any
}
export class Role implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = false;
    this.create = false;
    this.read = false;
    this.remove = false;
    this.update = false;
    this.export = false;
    this.import = false;
    this.manageUsers = false;
    this.roleName = roleNames.viewer;
  }
}
export class RoleDeny implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = false;
    this.create = false;
    this.read = false;
    this.remove = false;
    this.update = false;
    this.export = false;
    this.import = false;
    this.manageUsers = false;
    this.roleName = roleNames.deny;
  }
}
export class RoleViewer implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = false;
    this.create = false;
    this.read = true;
    this.remove = false;
    this.update = false;
    this.export = false;
    this.import = false;
    this.manageUsers = false;
    this.roleName = roleNames.viewer;
  }
}
export class RoleEducator implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = false;
    this.create = false;
    this.read = true;
    this.remove = true;
    this.update = true;
    this.export = false;
    this.import = false;
    this.manageUsers = false;
    this.roleName = roleNames.educator;
  }
}
export class RoleCreator implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = false;
    this.create = true;
    this.read = true;
    this.remove = true;
    this.update = true;
    this.export = false;
    this.import = false;
    this.manageUsers = false;
    this.roleName = roleNames.creator;
  }
}
export class RoleManager implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = true;
    this.create = true;
    this.read = true;
    this.remove = true;
    this.update = true;
    this.export = false;
    this.import = false;
    this.manageUsers = false;
    this.roleName = roleNames.manager;
  }
}
export class RoleAdmin implements RoleInterface {
  admin: boolean;
  create: boolean;
  read: boolean;
  remove: boolean;
  update: boolean;
  export: boolean;
  import: boolean;
  manageUsers: boolean;
  roleName: roleNames;
  constructor() {
    this.admin = true;
    this.create = true;
    this.read = true;
    this.remove = true;
    this.update = true;
    this.export = true;
    this.import = true;
    this.manageUsers = true;
    this.roleName = roleNames.admin;
  }
}
