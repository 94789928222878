import {Component, OnInit} from '@angular/core';
import {map} from "rxjs/operators";
import {Channel} from "../../models/channel";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {
  storeUrlState,
  switchFalseLoading, switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {VideoUploaderService} from "../../services/video-uploader/video-uploader.service";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {Subscription} from "rxjs";
import {CoursesService} from "../../services/courses/courses.service";
import {CourseSlideFramesService} from "../../services/course-slide-frames/course-slide-frames.service";
import {CourseOnlineFramesService} from "../../services/course-online-frames/course-online-frames.service";
import {CourseLiveFramesService} from "../../services/course-live-frames/course-live-frames.service";
import {CourseExaminationFramesService} from "../../services/course-examination-frames/course-examination-frames.service";
import {CourseVideoFramesService} from "../../services/course-video-frames/course-video-frames.service";
import {SlideUploaderService} from "../../services/slide-uploader/slide-uploader.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-remove',
  templateUrl: './manager-channel-remove.component.html',
  styleUrls: ['./manager-channel-remove.component.scss']
})
export class ManagerChannelRemoveComponent implements OnInit {
  channelId: string;
  channelSubscription: Subscription;
  currentChannel: Channel = new Channel();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private courseVideoFramesService: CourseVideoFramesService,
    private courseSlideFramesService: CourseSlideFramesService,
    private courseOnlineFramesService: CourseOnlineFramesService,
    private courseLiveFramesService: CourseLiveFramesService,
    private courseTestFramesService: CourseExaminationFramesService,
    private videoUploaderService: VideoUploaderService,
    private slideUploaderService: SlideUploaderService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await this.channelsService.connectSnapShot(this.channelId);
    this.channelSubscription = this.channelsService.currentChannel
      .pipe(
        map((channel: Channel) => channel)
      )
      .subscribe(async (channel) => {
        if (!channel.slug) {
          await this.router.navigate(['/manager/channels']);
          return;
        }
        this.currentChannel = channel as Channel;
        const urlState = new UrlState();
        urlState.channelId = channel.id;
        urlState.slug = channel.slug;
        this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
        this.uiStore.dispatch(switchFalseLoading());
      });
  }
  async back(): Promise<void> {
    await this.router.navigate([`/manager/channel/meta/${this.channelId}`]);
    return;
  }
  async remove(): Promise<void> {
    if (!window.confirm(this.translateService.instant('本当に削除しますか？'))) {
      return;
    }
    // 削除開始
    try {
      this.uiStore.dispatch(switchTrueLoading());
      await this.coursesService.fetchByChannelId(this.channelId);
      const courses = this.coursesService.fetched;
      for (let c of courses) {
        // コンテンツ削除
        const videoFrame = await this.courseVideoFramesService.fetch(
          this.currentChannel.accountId,
          c.id,
          '',
        );
        if (videoFrame) {
          await this.videoUploaderService.removeVideo(
            this.currentChannel.accountId,
            this.authService.idToken,
            videoFrame.videoFlame,
            `course-movies/${this.authService.user.currentAccountId}/${this.channelId}/${c.id}/videos/}`
          );
        }
        const slideFrame = await this.courseSlideFramesService.fetch(
          this.currentChannel.accountId,
          c.id,
          '',
        );
        if (slideFrame) {
          await this.slideUploaderService.removeAll(
            this.currentChannel.accountId,
            this.authService.idToken,
            slideFrame.slideFlame,
          );
        }
        // コースフレーム削除
        try {
          await this.courseVideoFramesService.remove(
            this.currentChannel.accountId,
            c.id,
            '',

          );
        } catch (e) {
          console.log(e);
        }
        try {
          await this.courseSlideFramesService.remove(
            this.currentChannel.accountId,
            c.id,
            ''
          );
        } catch (e) {
          console.log(e);
        }
        try {
          await this.courseOnlineFramesService.remove(
            this.currentChannel.accountId,
            c.id,
            ''
          );
        } catch (e) {
          console.log(e);
        }
        try {
          await this.courseLiveFramesService.remove(
            this.currentChannel.accountId,
            c.id,
            ''
          );
        } catch (e) {
          console.log(e);
        }
        try {
          await this.courseTestFramesService.remove(
            this.currentChannel.accountId,
            c.id,
            ''
          );
        } catch (e) {
          console.log(e);
        }
      }
      // コース削除 TODO バックエンド処理に移行する
      for (let c of courses) {
        await this.coursesService.remove(c);
      }
      // チャンネル削除 TODO バックエンド処理に移行する
      await this.channelsService.remove(this.currentChannel)
        .then(async () => {
          this.toasterService.info(this.translateService.instant('削除しました。'));
          await this.router.navigate(['/manager/channels']);
          this.uiStore.dispatch(switchFalseLoading());
          return;
        })
    } catch (e) {
      console.log(e);
      this.toasterService.warning(this.translateService.instant('削除中にエラーが発生しました。処理を中止しました。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
  }
}
