import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {BackendService} from "../../services/backend/backend.service";
import {
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {
  RoleAdmin,
  RoleCreator,
  RoleDeny,
  RoleEducator,
  RoleManager,
  roleNames,
  RoleViewer
} from "../../models/role";
import {AccountRole, User} from "../../models/user";
import { SessionState} from "../../store/sessions/sessions.reducer";
import {AccountsService} from "../../services/accounts/accounts.service";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-manager-users',
  templateUrl: './manager-users.component.html',
  styleUrls: ['./manager-users.component.scss']
})
export class ManagerUsersComponent implements OnInit, OnDestroy {
  forbidden: boolean = false;
  isOpenInvitationModal = false;
  roleName: FormControl[] = [];
  roleNamesOptions = roleNames;
  users: User[] = [];
  currentSession: SessionState;
  myRole: AccountRole;
  uid: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toasterService: ToastrService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private backendService: BackendService,
    private accountService: AccountsService,
    private translateService: TranslateService,
  )
  {
    window.scrollTo(0, 0);
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
  }
  email = new FormControl<string>(null, {
    validators: [
      Validators.required,
    ]
  });
  form = this.formBuilder.group({
    email: this.email,
  });
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    this.uid = this.authService.uid;
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.user?.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    const data = {
      accountId: this.authService.user.currentAccountId,
    }
    this.backendService.setIdToken(this.authService.idToken);
    this.backendService.post('accounts/account/fetch-users' , data)
      .then((result) => {
        if (result.error) {
          this.authService.logout();
          this.router.navigate(['/login']);
          alert(this.translateService.instant('ログインの有効期間が終了しました。もう一度ログインしてください。'))
        } else {
          result.users.map((u: User) => {
            if (u.roles && u.roles.account) {
              this.roleName.push( new FormControl(u.roles.account.roleName, []));
              if (u.id === this.authService.uid) {
                this.myRole = u.roles;
              }
            } else if (!u.roles) {
              u.roles = new AccountRole();
              u.roles.account = new RoleDeny();
              this.roleName.push( new FormControl(u.roles.account.roleName, []));
            }
          });
          this.users = result.users;
          console.log(this.users);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  ngOnDestroy(): void {}
  openInviteModal(): void {
    if (!this.myRole ||
      !this.myRole.account ||
      !this.myRole.account.manageUsers) {
      this.toasterService.error(this.translateService.instant('現在の権限ではユーザーを招待することは出来ません。'));
      return;
    }
    this.isOpenInvitationModal = true;
  }
  closeInviteModal(): void {
    this.isOpenInvitationModal = false;
  }
  invite(): void {
    if (!this.myRole ||
      !this.myRole.account ||
      !this.myRole.account.manageUsers) {
      this.toasterService.error(this.translateService.instant('現在の権限ではユーザーを招待することは出来ません。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.isOpenInvitationModal = false;
    const data = {
      accountId: this.authService.user?.currentAccountId,
      email: this.email.value
    }
    this.backendService.post('accounts/account/invite' , data)
      .then((result) => {
        alert(this.translateService.instant('招待メールを送信しました。'));
        this.uiStore.dispatch(switchFalseLoading());
        this.email.setValue('');
      })
      .catch(() => {
        alert(this.translateService.instant('招待メールを送信出来ませんでした。メールアドレスを確認してください。'));
        this.uiStore.dispatch(switchFalseLoading());
      })
  }
  remove(user: User): void {
    if (!this.myRole ||
      !this.myRole.account ||
      !this.myRole.account.manageUsers) {
      this.toasterService.error(this.translateService.instant('現在の権限ではユーザーを削除することは出来ません。'));
      return;
    }
    if (user.id !== this.authService.uid) {
      if (window.confirm(this.translateService.instant('削除してもよろしいですか？'))) {
        this.uiStore.dispatch(switchTrueLoading());
        this.backendService.setIdToken(this.authService.idToken);
        const data = {
          accountId: this.authService.user.currentAccountId,
          targetUserUid: user.id
        }
        this.backendService.post('accounts/account/remove-user' , data)
          .then(() => {
            this.toasterService.success(this.translateService.instant('削除しました。'));
            const i = this.users.findIndex(u => u.id === user.id);
            this.users.splice(i, 1);
            this.uiStore.dispatch(switchFalseLoading());
          })
          .catch(() => {
            this.toasterService.warning(this.translateService.instant('削除できませんでした。'));
            this.uiStore.dispatch(switchFalseLoading());
          })
      }
    } else {
      this.toasterService.warning(this.translateService.instant('自分自身を削除することは出来ません。'));
    }
  }
  changeRole(user: User, index: number): void {
    if (!this.myRole ||
      !this.myRole.account ||
      !this.myRole.account.manageUsers) {
      this.toasterService.error(this.translateService.instant('現在の権限ではユーザー権限を変更することは出来ません。'));
      return;
    }
    if (confirm(this.translateService.instant('権限を変更します。よろしいですか？'))) {
      this.uiStore.dispatch(switchTrueLoading());
      let changeRole;
      switch (this.roleName[index].value) {
        case roleNames.admin:
          changeRole = new RoleAdmin();
          break;
        case roleNames.manager:
          changeRole = new RoleManager();
          break;
        case roleNames.creator:
          changeRole = new RoleCreator();
          break;
        case roleNames.educator:
          changeRole = new RoleEducator();
          break;
        case roleNames.viewer:
          changeRole = new RoleViewer();
          break;
        default:
          changeRole = new RoleDeny();
          break;
      }
      const data = {
        accountId: this.authService.user.currentAccountId,
        targetUserUid: user.id,
        roles: changeRole,
      }
      this.backendService.post('accounts/roles/change' , data)
        .then((result) => {
          if (result !== false) {
            if (result.error) {
              console.log(result.error);
              this.toasterService.warning(this.translateService.instant('権限の設定に失敗しました。'));
            } else {
              this.toasterService.success(this.translateService.instant('権限を変更しました。'));
            }
            this.uiStore.dispatch(switchFalseLoading());
          } else {
            this.toasterService.warning(this.translateService.instant('変更に失敗しました。フォームの有効期限が切れている可能性があります。ページの再読み込みを行ってください。'));
            this.uiStore.dispatch(switchFalseLoading());
          }
        })
        .catch((e) => {
          console.log(e);
          this.toasterService.warning(this.translateService.instant('権限の設定に失敗しました。現在ログインしているユーザーアカウントがユーザーの権限を変更出来る権限を持っているかどうかお確かめください。'));
          this.uiStore.dispatch(switchFalseLoading());
        })
    }
  }
}
