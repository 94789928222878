import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument, CollectionReference,
  DocumentData, Query,
} from '@angular/fire/compat/firestore';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {CourseSubscription} from '../../models/course-subscription';
@Injectable({
  providedIn: 'root'
})
export class CourseSubscriptionsService {
  dbDocument: AngularFirestoreDocument<any>;
  collectionRef: AngularFirestoreCollection;
  doc: Observable<CourseSubscription | undefined>;
  docs: Observable<DocumentData[]>;
  myDocs: Observable<DocumentData[]>;
  subscriptions: CourseSubscription[] = [];
  constructor(
    private fireStore: AngularFirestore,
  ) { }
  create(
    accountId: string,
    channelId: string,
    courseId: string,
    uid: string,
  ): Promise<void> {
    const subscription = new CourseSubscription();
    subscription.channelAccountId = accountId;
    subscription.channelId = channelId;
    subscription.courseId = courseId;
    subscription.uid = uid;
    subscription.createdAt = new Date();
    subscription.createdBy = uid;
    subscription.updatedAt = new Date();
    subscription.updatedBy = uid;
    subscription.activated = true;
    subscription.activatedAt = new Date();
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courseSubscriptions')
        .add(subscription.deserialize())
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('create subscription reject');
          reject(e);
        });
    })
  }
  async fetch(
    courseId: string,
    uid: string
  ): Promise<CourseSubscription> {
    return new Promise((resolve, reject) => {
      if (!courseId) {
        resolve(undefined);
        return;
      }
      if (!uid) {
        resolve(undefined);
        return;
      }
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courseSubscriptions', ref => {
          let query: Query = ref;
          query = query.where('courseId', '==', courseId);
          query = query.where('uid', '==', uid);
          return query;
        })
        .get()
        .toPromise()
        .then(( querySnapshot ) => {
          if (querySnapshot.docs.length === 0) {
            resolve(undefined);
            return;
          }
          querySnapshot.forEach( d => {
            const newDoc = d.data() as CourseSubscription;
            newDoc.id = d.id;
            resolve(newDoc);
          })
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async fetchAll(
    courseId: string
  ): Promise<CourseSubscription[]> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courseSubscriptions', ref => {
          let query: Query = ref;
          query = query.where('courseId', '==', courseId);
          return query;
        })
        .get()
        .toPromise()
        .then(( querySnapshot ) => {
          const newDocs: CourseSubscription[] = [];
          querySnapshot.forEach( d => {
            const newDoc = d.data() as CourseSubscription;
            newDoc.id = d.id;
            newDocs.push(newDoc);
          })
          resolve(newDocs);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async update(
    uid: string,
    subscription: CourseSubscription
  ): Promise<void> {
    subscription.updatedAt = new Date();
    subscription.updatedBy = uid;
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courseSubscriptions')
        .doc(subscription.id)
        .set(subscription)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async remove(
    subscriptionId: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courseSubscriptions')
        .doc(subscriptionId)
        .delete()
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  connectSnapShots(
    courseId: string
  ): void {
    this.collectionRef = this.fireStore
      .collection<{}>('apps')
      .doc(environment.appId)
      .collection<{}>('courseSubscriptions' , ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('courseId', '==', courseId);
        query = query.orderBy('createdAt', 'asc');
        return query;
      });
    this.docs = this.collectionRef.valueChanges({ idField: "id" });
  }
  connectSnapShotsMy(
    courseId: string,
    uid: string,
  ): void {
    this.collectionRef = this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection<{}>('courseSubscriptions' , ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('courseId', '==', courseId);
        query = query.where('uid', '==', uid);
        return query;
      });
    this.myDocs = this.collectionRef.valueChanges({ idField: "id" });
  }
  connectSnapShotsMyAll(
    uid: string
  ): void {
    this.collectionRef = this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection<{}>('courseSubscriptions' , ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('uid', '==', uid);
        return query;
      });
    this.myDocs = this.collectionRef.valueChanges({ idField: "id" });
  }

}
