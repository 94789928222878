import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {Channel} from "../../models/channel";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {map} from "rxjs/operators";
import {categories, Category} from "../../models/categories";
import {MonetizeMethods} from "../../models/monetize-methods";
import {publishRanges, PublishRangesKeys} from "../../models/publish-range";
import {ImageConverterService} from "../../services/image-converter/image-converter.service";
import {environment} from "../../../environments/environment";
import {Tag} from "../../models/tag";
import {ContentTagsService} from "../../services/content-tags/content.tags.service";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {VideoUploaderService} from "../../services/video-uploader/video-uploader.service";
import {PublicImage} from "../../models/public-image";
import {AccountsService} from "../../services/accounts/accounts.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-meta',
  templateUrl: './manager-channel-meta.component.html',
  styleUrls: ['./manager-channel-meta.component.scss'],
})
export class ManagerChannelMetaComponent implements OnInit {
  forbidden: boolean = false;
  isNotFound = false;
  private tagsSubscription: Subscription;
  private channelSubscription: Subscription;
  channelId: string = '';
  formCategories: Category[]  = categories;
  currentChannel: Channel = new Channel();
  isDuplicate = false;
  formPublishRanges = publishRanges;
  eyeCatchThumbUrl: string = environment.noImageThumb;
  noImageThumbUrl: string = environment.noImageThumb;
  baseUrl: string = environment.hostName;
  prImagesIndex = 0;
  sampleVideoIndex = 0;
  isOpenEyeCatchImageUploadModal = false;
  isOpenPrImageImageUploadModal = false;
  isOpenSampleVideoUploadModal = false;
  isOpenProfileImageUploadModal = false;
  isOpenTagModal = false;
  tagListAsync = this.contentTagsService.tags;
  tagList: Tag[] = [];
  tagPayload: Tag[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private imageConverterService: ImageConverterService,
    private videoUploaderService: VideoUploaderService,
    private contentTagsService: ContentTagsService,
    private accountService: AccountsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
  }
  name = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.maxLength(100),
      Validators.minLength(4),
    ]
  });
  description = new FormControl<string|null>(null, {
    validators: []
  });
  slug = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.maxLength(32),
      Validators.minLength(6),
    ]
  });
  categoryId = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  published = new FormControl<boolean>(false, {
    validators: []
  });
  tagKeyword = new FormControl<string|null>(null, {
    validators: []
  });
  tags = new FormControl<string[]|null>(null, {
    validators: []
  });
  monetized = new FormControl<boolean>(false, {
    validators: []
  });
  monetizeMethod = new FormControl<MonetizeMethods|null>(null, {
    validators: []
  });
  unitPrice = new FormControl<number|null>(null, {
    validators: []
  });
  channelSubscriptionPrice = new FormControl<number|null>(null, {
    validators: []
  });
  channelSubscriptionPriceForPremium = new FormControl<number|null>(null, {
    validators: []
  });
  publishRange = new FormControl<PublishRangesKeys>(null, {
    validators: []
  });
  form = this.formBuilder.group({
    name: this.name,
    description: this.description,
    slug: this.slug,
    categoryId: this.categoryId,
    published: this.published,
    tags: this.tags,
    monetized: this.monetized,
    monetizeMethod: this.monetizeMethod,
    unitPrice: this.unitPrice,
    channelSubscriptionPrice: this.channelSubscriptionPrice,
    channelSubscriptionPriceForPremium: this.channelSubscriptionPriceForPremium,
    publishRange: this.publishRange,
  });
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.user.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }    this.breadcrumbStore.dispatch(switchTrueLoading());
    await this.channelsService.connectSnapShot(this.channelId);
    this.channelSubscription = this.channelsService.currentChannel
      .pipe(
        map((channel: Channel) => channel)
      )
      .subscribe(async (channel) => {
        if (!channel.slug) {
          await this.router.navigate(['/manager/channels']);
          return;
        }
        this.currentChannel = channel as Channel;
        if (this.currentChannel.accountId !== this.authService.user.currentAccountId) {
          this.isNotFound = true;
          this.uiStore.dispatch(switchFalseLoading());
          return ;
        }
        const urlState = new UrlState();
        urlState.channelId = channel.id;
        urlState.slug = channel.slug;
        this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
        this.tagPayload = await this.contentTagsService.gatheringTags(channel.tags);
        this.formReset();
        this.getImages().then();
        this.uiStore.dispatch(switchFalseLoading());
      });
    this.contentTagsService.tags
      .subscribe((tags => {
        this.tagList = tags;
      }))
  }
  ngOnDestroy(): void{
    if (this.channelSubscription) {
      this.channelSubscription.unsubscribe();
    }
    if (this.tagsSubscription) {
      this.tagsSubscription.unsubscribe();
    }
  }
  async getImages(): Promise<void> {}
  formReset(): void {
    this.categoryId.setValue(this.currentChannel.categoryId);
    this.name.setValue(this.currentChannel.name);
    this.slug.setValue(this.currentChannel.slug);
    this.description.setValue(this.currentChannel.description);
    this.tags.setValue(this.currentChannel.tags);
    this.published.setValue(this.currentChannel.published);
    this.monetized.setValue(this.currentChannel.monetized);
    this.monetizeMethod.setValue(this.currentChannel.monetizeMethod);
    this.unitPrice.setValue(this.currentChannel.unitPrice);
    this.channelSubscriptionPrice.setValue(this.currentChannel.channelSubscriptionPrice);
    this.channelSubscriptionPriceForPremium.setValue(this.currentChannel.channelSubscriptionPriceForPremium);
    this.publishRange.setValue(this.currentChannel.publishRange as unknown as PublishRangesKeys);
  }
  async uploadImageEyeCatch(evt: any): Promise<void> {
    const file: File = evt.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toasterService.warning(this.translateService.instant('jpg形式、またはpng形式の画像をアップロードしてください。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.currentChannel.eyeCatchImage = await this.imageConverterService.uploadPublicImage(
      this.channelId,
      this.currentChannel.accountId,
      file,
      this.authService.uid,
      this.authService.idToken
    );
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenEyeCatchImageUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeImageEyeCatch(): Promise<void> {
    if (!window.confirm(this.translateService.instant('画像を削除します。よろしいですか？'))) {
      return;
    }
    this.eyeCatchThumbUrl = environment.noImageThumb;
    this.uiStore.dispatch(switchTrueLoading());
    await this.imageConverterService.removePublicImage(
      this.currentChannel.eyeCatchImage,
      this.authService.idToken
    );
    this.currentChannel.eyeCatchImage = new PublicImage();
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async uploadImagePrImages(evt: any): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    if (!this.currentChannel?.prImages?.length) {
      this.currentChannel.prImages = [];
    }
    this.prImagesIndex = this.currentChannel.prImages.length;
    const files: PublicImage[] = [];
    for (const image of this.currentChannel.prImages) {
      if (image?.src) {
        files.push(image);
      }
    }
    this.currentChannel.prImages = files;
    const file: File = evt.target.files[0];
    await this.authService.checkIdTokenExpire();
    const newImage = await this.imageConverterService.uploadPublicImage(
      this.channelId,
      this.currentChannel.accountId,
      file,
      this.authService.uid,
      this.authService.idToken
    );
    this.currentChannel.prImages.push(newImage);
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenPrImageImageUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeImage(prImagesIndex: number): Promise<void> {
    if (!window.confirm(this.translateService.instant('画像を削除します。よろしいですか？'))) {
      return;
    }
    this.prImagesIndex = prImagesIndex;
    this.uiStore.dispatch(switchTrueLoading());
    await this.authService.checkIdTokenExpire();
    await this.imageConverterService.removePublicImage(
      this.currentChannel.prImages[this.prImagesIndex],
      this.authService.idToken
    );
    this.currentChannel.prImages = this.currentChannel
      .prImages
      .filter(p => p !== this.currentChannel.prImages[this.prImagesIndex]);
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async uploadImageProfile(evt: any): Promise<void> {
    const file: File = evt.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toasterService.warning(this.translateService.instant('jpg形式、またはpng形式の画像をアップロードしてください。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    await this.authService.checkIdTokenExpire();
    this.currentChannel.profileImage = await this.imageConverterService.uploadPublicImage(
      this.channelId,
      this.currentChannel.accountId,
      file,
      this.authService.uid,
      this.authService.idToken,
      true
    );
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenProfileImageUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeImageProfile(): Promise<void> {
    if (!window.confirm(this.translateService.instant('プロフィール画像を削除します。よろしいですか？'))) {
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    await this.authService.checkIdTokenExpire();
    await this.imageConverterService.removePublicImage(
      this.currentChannel.prImages[this.prImagesIndex],
      this.authService.idToken
    );
    this.currentChannel.profileImage = null;
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async uploadSampleVideo(evt: any): Promise<void> {
    if (!this.currentChannel?.samples?.length) {
      this.currentChannel.samples = [];
    }
    this.sampleVideoIndex = this.currentChannel.samples.length + 1;
    this.uiStore.dispatch(switchTrueLoading());
    const file: File = evt.target.files[0];
    if (file && file.size > 100000000) {
      this.toasterService.warning(this.translateService.instant('ファイルのサイズは最大100MBです。長さは60秒程度で解像度は720pを目安にしてください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const acceptMedias = ['video/mp4', 'video/m4v'];
    if (acceptMedias.indexOf(file.type) === -1) {
      this.toasterService.warning(this.translateService.instant('mp4またはm4vファイルをUPLOADしてください。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const newVideoFrame = await this.videoUploaderService.uploadVideo(
      this.channelId,
      this.currentChannel.accountId,
      'sample',
      file,
    );
    this.currentChannel.samples.push(newVideoFrame);
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenSampleVideoUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeSampleVideo(targetIndex: number): Promise<void> {
    if (!window.confirm(this.translateService.instant('サンプル動画を削除します。よろしいですか？'))) {
      return;
    }
    this.sampleVideoIndex = targetIndex;
    this.uiStore.dispatch(switchTrueLoading());
    await this.imageConverterService.removePublicImage(
      this.currentChannel.samples[targetIndex].eyeCatchImage,
      this.authService.idToken
    );
    await this.videoUploaderService.removeSampleVideo(
      this.channelId,
      this.currentChannel.accountId,
      this.currentChannel.samples[targetIndex]
    );
    this.currentChannel.samples = this.currentChannel.samples.filter(v => v.contentKey !== this.currentChannel.samples[this.sampleVideoIndex].contentKey);
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.toasterService.warning(this.translateService.instant('未入力または不足している項目があります。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.currentChannel.categoryId = this.categoryId.value;
    this.currentChannel.name = this.name.value;
    this.currentChannel.description = this.description.value;
    this.currentChannel.tags = this.tags.value;
    this.currentChannel.published = this.published.value;
    this.currentChannel.monetized = this.monetized.value;
    this.currentChannel.monetizeMethod = this.monetizeMethod.value;
    this.currentChannel.unitPrice = this.unitPrice.value;
    this.currentChannel.channelSubscriptionPrice = this.channelSubscriptionPrice.value;
    this.currentChannel.channelSubscriptionPriceForPremium = this.channelSubscriptionPriceForPremium.value;
    this.currentChannel.publishRange = this.publishRange.value;
    await this.channelsService.update(this.currentChannel);
    this.uiStore.dispatch(switchFalseLoading());
    this.toasterService.success(this.translateService.instant('保存しました。'));
  }
  async back(): Promise<void> {
    if (this.form.dirty) {
      if (!window.confirm(this.translateService.instant('編集内容を破棄して、前の画面に戻ります。よろしいですか？'))) {
        return;
      }
    }
    await this.router.navigate([`/manager/channel/${this.channelId}`]);
    return;
  }
  async save(): Promise<void> {
    await this.authService.checkIdTokenExpire();
    await this.channelsService.update(this.currentChannel);
  }
  async searchTags(): Promise<void> {
    if (!this.tagKeyword.value) {
      this.tagList = [];
      this.contentTagsService.tagList = [];
      return;
    }
    this.tagList = [];
    this.contentTagsService.tags = new BehaviorSubject<Tag[] | undefined>([]);
    this.contentTagsService.searchTagsByKeyword(this.tagKeyword.value);
    this.tagList = this.contentTagsService.tagList;
  }
  removeTag(tag: Tag): void {
    const tagIndex = this.tags.value.indexOf('id');
    if (tagIndex !== -1) {
      this.tags.value.splice(tagIndex, 1);
    }
  }
  canAddTag(): boolean {
    if (!this.tagKeyword.value) {
      return false;
    }
    if (!this.tagList) {
      return false;
    }
    const i = this.tagList.findIndex( t => t.name === this.tagKeyword.value);
    const n = this.tagPayload.findIndex( t => t.name === this.tagKeyword.value);
    return i == -1 && n == -1 && this.tagKeyword.value.length > 4;
  }
  closeTagModal(): void {
    this.tagList = [];
  }
  async addContentTag(): Promise<void> {
    if (!window.confirm(`${this.translateService.instant('ハッシュタグ')} ${this.tagKeyword.value} ${this.translateService.instant('を追加しますか？')}`)) {
      return;
    }
    const newTag = new Tag();
    newTag.name = this.tagKeyword.value;
    newTag.createdBy = this.authService.uid;
    const id = await this.contentTagsService.create(newTag);
    newTag.id = id;
    const tags = this.tags.value;
    tags.push(id);
    this.tags.setValue(tags);
    this.tagPayload.push(newTag);
  }
  async pushContentTag(tag: Tag): Promise<void> {
    const tags = this.tags.value;
    if (tags.indexOf(tag.id) !== -1) {
      return;
    }
    tags.push(tag.id);
    this.tags.setValue(tags);
    this.tagPayload.push(tag);
  }
  async removeContentTag(tag: Tag): Promise<void> {
    const tags = this.tags.value;
    const removed = tags.filter(t => t !== tag.id);
    this.tags.setValue(removed);
    this.tagPayload = this.tagPayload.filter(t => t !== tag);
  }
  openEyeCatchImageUploadModal(): void {
    this.isOpenEyeCatchImageUploadModal = true;
  }
  closeEyeCatchImageUploadModal(): void {
    this.isOpenEyeCatchImageUploadModal = false;
  }
  openPrImageUploadModal(): void {
    this.isOpenPrImageImageUploadModal = true;
  }
  closePrImageUploadModal(): void {
    this.isOpenPrImageImageUploadModal = false;
  }
  openProfileImageUploadModal(): void {
    this.isOpenProfileImageUploadModal = true;
  }
  closeProfileImageUploadModal(): void {
    this.isOpenProfileImageUploadModal = false;
  }
  openVideoUploadModal(): void {
    this.isOpenSampleVideoUploadModal = true;
  }
  closeVideoUploadModal(): void {
    this.isOpenSampleVideoUploadModal = false;
  }
  clickUploadFile(domId: string): void {
    const file = document
      .getElementById(domId);
    file.click();
  }
  dropSamples(event: CdkDragDrop<string[]>): void {
    console.log(event);
  }
}
