<section class="l-modalTopLayer" *ngIf="isOpenModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>ファイルダウンロード</div>
      <div class="c-modal__head__closeButton" (click)="closeModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__form">
        <div class="c-modal__main__form__input">
          <label class="c-form-label">
            <span translate>ユーザーリストのダウンロード</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <select
            [formControl]="downloadJobSelector"
            class="c-form-select"
            name="downloadJobSelector" id="downloadJobSelector"
          >
            <option [ngValue]="null" translate>選択してください</option>
            <option>CSV</option>
          </select>
          <div
            *ngIf="downloadJobSelector.invalid && downloadJobSelector.dirty"
            class="c-form-error"
            translate
          >選択してください。</div>
        </div>

        <div class="c-modal__main__form__input">
          <label class="c-form-label">
            <span translate>対象データ</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <select
            [formControl]="downloadTypeSelector"
            class="c-form-select"
            name="downloadTypeSelector" id="downloadTypeSelector"
          >
            <option [ngValue]="null" translate>選択してください</option>
            <option value="surveyAnswer" translate>アンケート回答</option>
            <option value="examAnswer" translate>テスト回答</option>
          </select>
          <div
            *ngIf="downloadTypeSelector.invalid && downloadTypeSelector.dirty"
            class="c-form-error"
            translate
          >選択してください。</div>
        </div>

        <ng-container>
          <div class="c-modal__main__form__input">
            <label class="c-form-label">
              <span translate>アンケート回答</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <select
              [formControl]="surveyAnswerSelector"
              class="c-form-select"
              name="surveyAnswer" id="surveyAnswer"
            >
              <option [ngValue]="null" translate>選択してください</option>
              <option value="surveyAnswer-1">アンケートタイトル1アンケートタイトル1</option>
              <option value="surveyAnswer-2">アンケートタイトル2アンケートタイトル2</option>
            </select>
            <div
              *ngIf="surveyAnswerSelector.invalid && surveyAnswerSelector.dirty"
              class="c-form-error"
              translate
            >選択してください。</div>
          </div>
        </ng-container>

        <ng-container>
          <div class="c-modal__main__form__input">
            <label class="c-form-label">
              <span translate>テスト回答</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <select
              [formControl]="examAnswerSelector"
              class="c-form-select"
              name="examAnswer" id="examAnswer"
            >
              <option [ngValue]="null" translate>選択してください</option>
              <option value="examAnswer-1">テストタイトル1テストタイトル1</option>
              <option value="examAnswer-2">テストタイトル2テストタイトル2</option>
            </select>
            <div
              *ngIf="examAnswerSelector.invalid && examAnswerSelector.dirty"
              class="c-form-error"
              translate
            >選択してください。</div>
          </div>
        </ng-container>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__button-center">
          <button
            (click)="download()"
            class="c-button c-button-primary"
          ><span translate="">ダウンロード</span></button>
        </div>
      </div>

    </div>
  </div>
</section>
