import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentFrame, ExamAnswerTypes, ExamQuestion, FrameTypes,} from "../../models/course-frames";
import {Subscription} from "rxjs";
import {ChannelSubscription} from "../../models/channel-subscription";
import {Channel} from "../../models/channel";
import {Account, AccountType} from "../../models/account";
import {Course} from "../../models/course";
import {ContentFragment, CourseProgress, Progress} from "../../models/course-progress";
import {ExamAnswer, ExamAnswers, ExamAnswerStatus} from "../../models/answer-exam";
import {FormControl} from "@angular/forms";
import {CourseSubscription} from "../../models/course-subscription";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {AccountsService} from "../../services/accounts/accounts.service";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {
  CourseExaminationFramesService
} from "../../services/course-examination-frames/course-examination-frames.service";
import {nanoid} from "nanoid";
import {CourseExamAnswersService} from "../../services/course-exam-answers/course-exam-answers.service";
import {environment} from "../../../environments/environment";
import {registerables} from "chart.js";
import Chart from 'chart.js/auto';
import {TranslateService} from "@ngx-translate/core";

Chart.register(...registerables);

@Component({
  selector: 'app-channel-course-examination',
  templateUrl: './channel-course-examination.component.html',
  styleUrls: ['./channel-course-examination.component.scss']
})
export class ChannelCourseExaminationComponent implements OnInit, OnDestroy {
  isSpView: boolean;
  channelId: string;
  slug: string;
  courseId: string;
  partId: string;
  questionIndex: number = -1;
  questionIndexName: string;
  frameTypes = FrameTypes;
  framesLoaded: boolean;
  sessionsSubscription: Subscription;
  channelSubscriptionStatus: ChannelSubscription;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  currentCourse: Course = new Course();
  examFrames: ContentFrame;
  currentQuestion: ExamQuestion;
  frameIndex = 0;
  currentProgress: CourseProgress;
  isNotFound = false;
  fragmentIndex: number;
  examFragmentLength: number;
  surveyFragments: ContentFragment[] = [];
  examAnswers: ExamAnswers[] = [];
  latestAnswers: ExamAnswers;
  forms: FormControl[] = [];
  isOpenHistoryModal = false;
  isHistoryMode = false;
  isDirty = false;
  courseSubscriptionStatus: CourseSubscription;
  accountType = AccountType;
  baseUrl: string;
  // time progress
  isUseTimeProgress: boolean = false;
  timeTarget: Date;
  isStartTimer: boolean = false;
  minutes = '0';
  second = '0';
  // チャート
  chart: any;
  colors: string[] = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 205, 86)'
  ];
  protected readonly answerTypes = ExamAnswerTypes;
  protected readonly answerStatus = ExamAnswerStatus;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToastrService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private accountService: AccountsService,
    private courseProgressesService: CourseProgressesService,
    private courseExaminationFramesService: CourseExaminationFramesService,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private courseExamAnswersService: CourseExamAnswersService,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0)
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.partId = this.route.snapshot.paramMap.get('partId');
    this.questionIndexName = this.route.snapshot.paramMap.get('questionIndex');
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    // 未ログイン時は見えない
    if (this.authService.isAnonymous) {
      console.log('404 isAnonymous');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return ;
    }
    this.currentChannel = await this.channelsService.fetchBySlug(this.slug);
    this.channelId = this.currentChannel?.id;
    this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel?.accountId);
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(this.currentChannel?.id, this.authService.uid);
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    this.courseSubscriptionStatus = await this.courseSubscriptionsService.fetch(this.currentCourse?.id, this.authService.uid);
    this.courseProgressesService.loadAllFrames(this.currentCourse, this.currentCourse.accountId).then();
    this.framesLoaded = this.courseProgressesService.framesLoaded;
    // コース権限チェック
    if (!this.coursesService.canShow(
      this.currentChannel,
      this.channelSubscriptionStatus,
      this.currentCourse,
      this.courseSubscriptionStatus,
      this.authService.user
    )) {
      console.log('404 コースを閲覧できません。');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return ;
    }
    // コンテンツデータ読み込み
    this.examFrames = await this.courseExaminationFramesService.fetch(
      this.currentChannel.accountId,
      this.courseId,
      this.partId
    );
    if (!this.examFrames) {
      console.log('404 コンテンツデータが読み込めない（存在しない）');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (this.examFrames.examinationFrame.questions.length === 0) {
      console.log('404 設問が未作成');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    console.log(this.examFrames.examinationFrame.questions[4]);
    // URL
    if (this.questionIndexName === 'start') {
      this.questionIndex = -1;
    } else if (this.questionIndexName === 'finished') {
      this.questionIndex = this.examFrames.examinationFrame.questions.length;
      this.buildChart('resultChart').then();
    } else if (this.questionIndexName === 'explain') {
      this.clickShowExplain();
    } else {
      this.questionIndexName = 'start';
      this.questionIndex = -1;
    }
    this.baseUrl = `/channel/course/examination/${this.currentChannel.slug}/${this.currentCourse.id}/${this.partId}/`;
    this.buildForm();
    this.createTimeProgress();
    // 回答データを取得
    this.examAnswers = await this.courseExamAnswersService.fetchByUser(
      this.courseId,
      this.examFrames.contentKey,
      this.authService.uid
    );
    console.log(this.examAnswers);
    if (this.examAnswers.length > 0) {
      this.latestAnswers = JSON.parse(JSON.stringify(this.examAnswers[0]));
      this.fillFormValue();
    } else {
      console.log('履歴なし')
      this.latestAnswers = new ExamAnswers();
      this.latestAnswers.courseId = this.courseId;
      this.latestAnswers.contentKey = this.examFrames.contentKey;
      this.latestAnswers.uid = this.authService.uid;
      this.latestAnswers.id = nanoid();
      this.buildAnswers();
    }
    // コース進捗データ取得
    this.courseProgressesService.fetchByMy(
      this.courseId,
      this.authService.uid
    )
      .then(async (courseProgress) => {
        if (courseProgress && courseProgress.progresses) {
          // 視聴進捗の準備
          this.currentProgress = courseProgress;
          const index = courseProgress.progresses.findIndex(item => item.contentKey === this.partId);
          let currentIndex = index;
          if (index === -1) {
            currentIndex = this.currentProgress.progresses.length;
            this.currentProgress.progresses[currentIndex] = new Progress();
            this.currentProgress.progresses[currentIndex].contentKey = this.partId;
            this.currentProgress.progresses[currentIndex] = this.fixProgress(courseProgress.progresses[currentIndex]);
          } else {
            if (this.examFrames.examinationFrame.questions.length !== courseProgress.progresses[currentIndex].fragment.length) {
              this.currentProgress.progresses[currentIndex] = this.fixProgress(courseProgress.progresses[currentIndex]);
            }
          }
          this.surveyFragments = this.currentProgress.progresses[currentIndex].fragment;
          this.examFragmentLength = this.currentProgress.progresses[currentIndex].fragment.length;
          this.fragmentIndex = currentIndex;
        } else {
          // 無ければ作る
          await this.courseProgressesService.create(
            this.currentCourse.id,
            this.authService.uid
          );
          this.currentProgress = await this.courseProgressesService.fetchByMy(
            this.currentCourse.id,
            this.authService.uid
          );
          this.currentProgress.progresses = [];
          this.currentProgress.progresses[0] = new Progress();
          this.currentProgress.progresses[0].contentKey = this.partId;
          this.currentProgress.progresses[0] = this.fixProgress(this.currentProgress.progresses[0]);
          await this.courseProgressesService.update(
            this.currentCourse.id,
            this.authService.uid,
            this.currentProgress
          );
          this.currentProgress = await this.courseProgressesService.fetchByMy(
            this.currentCourse.id,
            this.authService.uid
          );
          this.surveyFragments = this.currentProgress.progresses[0].fragment;
          this.fragmentIndex = 0;
        }
      })
    this.uiStore.dispatch(switchFalseLoading());
  }
  ngOnDestroy(): void {
    this.saveProgress();
    this.saveAnswers().then();
    // this.addBrowserBackEvent();
  }
  private createTimeProgress(): void {
    if (!this.examFrames.examinationFrame.timeLimit) {
      return;
    }
    this.isUseTimeProgress = true;
    this.timeTarget = new Date(new Date().getTime() + (this.examFrames.examinationFrame.timeLimit　* 60 * 1000));
  }
  private timerCountDown(): void {
    if (!(this.isUseTimeProgress && this.isStartTimer)) {
      return;
    }
    const now = new Date();
    let diff = this.timeTarget.getTime() - now.getTime();
    if (diff < 0) {
      // ゼロでテスト終了
      this.questionIndex = this.examFrames.examinationFrame.questions.length;
      this.buildChart('resultChart').then();
      return;
    }
    const minutes = Math.floor(diff / 60000);
    const minutesDiff = diff % 60000;
    const second = Math.floor(minutesDiff / 1000);
    if (minutes.toString().length === 1) {
      this.minutes = '0' + minutes.toString();
    } else {
      this.minutes = minutes.toString();
    }
    if (second.toString().length === 1) {
      this.second = '0' + second.toString();
    } else {
      this.second = second.toString();
    }
    setTimeout(() => {
      this.timerCountDown()
    },1000);
  }
  saveProgress(): void {
    if (!this.currentProgress) {
      return;
    }
    const index = this.currentProgress.progresses.findIndex((p) => p.contentKey === this.partId);
    this.currentProgress.progresses[index].progress = this.getCorrectRate() / 100;
    this.courseProgressesService.update(this.courseId,
      this.authService.uid,
      this.currentProgress
    )
      .then(() => {
        console.log('updated progress');
      })
      .catch((e) => {
        console.log(e);
      });
  }
  fixProgress(progress: Progress): Progress {
    progress.fragment = [];
    let c = 0;
    for (let i = 0; i < this.examFrames.examinationFrame.questions.length; i++) {
      const fragment = new ContentFragment();
      fragment.pointSec = 0;
      fragment.viewed = false;
      progress.fragment.push(fragment);
    }
    return progress;
  }
  async saveAnswers(): Promise<void> {
    if (!this.latestAnswers) {
      return;
    }
    this.latestAnswers.applied = true;
    this.latestAnswers.appliedAt = new Date();
    await this.courseExamAnswersService.update(
      this.courseId,
      this.authService.uid,
      this.latestAnswers
    )
  }
  // ページ遷移ナビゲーション
  async backToIndex(): Promise<void> {
    await this.router.navigate([`/channel/course/index/${this.currentChannel.slug}/${this.currentCourse.id}`]);
    return;
  }
  async backToFinished(): Promise<void> {
    this.questionIndex = this.examFrames.examinationFrame.questions.length;
    this.questionIndexName = 'finished';
    this.buildChart('resultChart').then();
    this.replaceUrl();
    return;
  }
  isExistRev(): boolean {
    if (!this.framesLoaded) {
      return false;
    }
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    return !!this.courseProgressesService.indexSeq[i - 1];
  }
  isExistFwd(): boolean {
    if (!this.framesLoaded) {
      return false;
    }
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    return !!this.courseProgressesService.indexSeq[i + 1];
  }
  clickRev(): void {
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    if (this.isExistRev()) {
      const part = this.courseProgressesService.indexSeq[i -1];
      if (part.frameType === FrameTypes.ExaminationFrame) {
        this.router.navigate([`/channel/course/${this.getUrlByFrameType(part.frameType)}/${this.slug}/${this.courseId}/${part.partId}/start`]).then();
        return;
      }
      this.router.navigate([`/channel/course/${this.getUrlByFrameType(part.frameType)}/${this.slug}/${this.courseId}/${part.partId}`]).then();
      return;
    }
    return;
  }
  clickFwd(): void {
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    if (this.isExistFwd()) {
      const part = this.courseProgressesService.indexSeq[i +1];
      if (part.frameType === FrameTypes.ExaminationFrame) {
        this.router.navigate([`/channel/course/${this.getUrlByFrameType(part.frameType)}/${this.slug}/${this.courseId}/${part.partId}/start`]).then();
        return;
      }
      this.router.navigate([`/channel/course/${this.getUrlByFrameType(part.frameType)}/${this.slug}/${this.courseId}/${part.partId}`]).then();
      return;
    }
    return;
  }
  addBrowserBackEvent(): void {
    history.pushState(null, null, location.href);
    window.addEventListener('popstate', (e) => {
      console.log('-------------------- back now ----------------------------');
      this.toasterService.warning(this.translateService.instant('テスト中はブラウザバックを行うことは出来ません。'));
      history.go(1);
    });
  }
  private getUrlByFrameType(type: string): string {
    switch (type) {
      case this.frameTypes.VideoFrame:
        return 'video';
      case this.frameTypes.SurveyFrame:
        return 'survey';
      case this.frameTypes.SlideFrame:
        return 'slide';
      case this.frameTypes.ExaminationFrame:
        return 'examination';
      case this.frameTypes.ReportFrame:
        return 'report';
      case this.frameTypes.FileFrame:
        return 'file';
      case this.frameTypes.LiveFrame:
        return 'live';
      default:
        return '';
    }
  }
  clickNextQuestion(): void {
    if (this.latestAnswers.answers[this.questionIndex]) {
      this.latestAnswers.answers[this.questionIndex].isCorrect = this.judgeAnswer(this.currentQuestion, this.latestAnswers.answers[this.questionIndex]?.answer);
    }
    this.questionIndex++;
    if (this.isUseTimeProgress && this.questionIndex === 0) {
      this.isStartTimer = true;
      this.timerCountDown();
    }
    if (this.questionIndex >= this.examFrames.examinationFrame.questions.length) {
      this.questionIndexName = 'finished';
      this.currentQuestion = undefined;
      this.buildChart('resultChart').then();
    } else {
      this.questionIndexName = String(this.questionIndex);
      this.currentQuestion = this.examFrames.examinationFrame.questions[this.questionIndex];
    }
    this.saveAnswers().then();
    this.replaceUrl();
    window.scrollTo(0, 0)
  }
  clickPreviousQuestion(): void {
    if (this.latestAnswers.answers[this.questionIndex]) {
      this.latestAnswers.answers[this.questionIndex].isCorrect = this.judgeAnswer(this.currentQuestion, this.latestAnswers.answers[this.questionIndex].answer);
    }
    this.questionIndex--;
    if (this.questionIndex === -1) {
      this.questionIndexName = 'start';
      this.currentQuestion = undefined;
    } else {
      this.questionIndexName = String(this.questionIndex);
      this.currentQuestion = this.examFrames.examinationFrame.questions[this.questionIndex];
    }
    this.saveAnswers().then();
    this.replaceUrl();
    window.scrollTo(0, 0)
  }
  clickRewindQuestion(): void {
    this.questionIndex = -1;
    this.questionIndexName = 'start';
    this.replaceUrl();
    for (let a of this.latestAnswers.answers){
      a.isCorrect = false;
      a.answer = [];
    }
    this.saveAnswers().then();
    this.currentQuestion = undefined;
    this.buildForm();
    this.createTimeProgress();
    window.scrollTo(0, 0)
  }
  clickShowExplain(): void {
    this.questionIndexName = 'explain';
    this.replaceUrl();
    this.questionIndex = -2;
    this.currentQuestion = undefined;
    this.buildChart('resultChartMini').then();
  }
  replaceUrl(): void {
    this.baseUrl = `/channel/course/examination/${this.currentChannel.slug}/${this.currentCourse.id}/${this.partId}/`;
    history.replaceState(null,null,this.baseUrl + this.questionIndexName);
  }
  pushHistory(): void {
    this.baseUrl = `/channel/course/examination/${this.currentChannel.slug}/${this.currentCourse.id}/${this.partId}/`;
    history.pushState(null, null, this.baseUrl + this.questionIndexName);
  }
  private buildForm(): void {
    this.forms = [];
    for (const q of this.examFrames.examinationFrame.questions) {
      const form = new FormControl<string|null>(null,[]);
      this.forms.push(form);
    }
  }
  private fillFormValue(): void {
    let c = 0;
    for (const q of this.examFrames.examinationFrame.questions) {
      switch (q.type) {
        case this.answerTypes.radio:
          this.forms[c].setValue(this.latestAnswers?.answers[c]?.answer[0]);
          break;
        case this.answerTypes.check:
          break;
      }
      c++;
    }
  }
  private buildAnswers(): void {
    for (const q of this.examFrames.examinationFrame.questions) {
      const answer = new ExamAnswer();
      answer.contentKey = q.contentKey;
      answer.type = q.type;
      answer.status = this.answerStatus.noAnswer;
      this.latestAnswers.answers.push(answer);
    }
  }
  checkboxIsChecked(index: number, value: string): boolean {
    if (!this.latestAnswers) {
      return false;
    }
    if (!this.latestAnswers.answers[index]?.answer) {
      return false;
    }
    return this.latestAnswers.answers[index]?.answer.findIndex(a => a === value) !== -1;
  }
  answerUpdate(contentKey: string, value: string, e: any): void {
    this.isDirty = true;
    const q = this.examFrames.examinationFrame.questions.find(q => q.contentKey === contentKey);
    let aIndex = this.latestAnswers.answers.findIndex(a => a.contentKey === contentKey);
    if (aIndex === -1) {
      const answer = new ExamAnswer();
      answer.contentKey = q.contentKey;
      answer.type = q.type;
      answer.status = this.answerStatus.noAnswer;
      this.latestAnswers.answers.push(answer);
      aIndex = this.latestAnswers.answers.findIndex(a => a.contentKey === contentKey);
    }
    switch (q.type) {
      case this.answerTypes.radio:
        this.latestAnswers.answers[aIndex].answer[0] = this.forms[aIndex].value;
        break;
      case this.answerTypes.check:
        if (e.currentTarget.checked && this.latestAnswers.answers[aIndex].answer.findIndex(o => o === value) === -1) {
          this.latestAnswers.answers[aIndex].answer.push(value);
        } else if (!e.currentTarget.checked) {
          const index = this.latestAnswers.answers[aIndex].answer.findIndex(o => o === value);
          if (index !== -1) {
            this.latestAnswers.answers[aIndex].answer.splice(index, 1);
          }
        }
        break;
    }
  }
  private countNumOfAnswered(): number {
    if (!this.latestAnswers) {
      return 0;
    }
    let c = 0;
    for (const a of this.latestAnswers.answers) {
      if (a.status !== this.answerStatus.noAnswer) {
        c++
      }
    }
    return c;
  }
  private judgeAnswer(question: ExamQuestion, answer: string[]): boolean {
    if (!question){
      return false;
    }
    switch (question?.type) {
      case 'radio':
        const correct = question.selectOptions.find(o => o.isCollect === true);
        return answer[0] === correct.name;
      case 'check':
        let numOfCorrect = 0;
        const corrects = question.selectOptions.filter(o => o.isCollect === true);
        for (let c of corrects) {
          for (let a of answer) {
            if (c.name === a) {
              numOfCorrect++;
            }
          }
        }
        return numOfCorrect === corrects.length;
    }
    return false;
  }
  private countNumOfAnsweredForHistory(index: number): number {
    if (!this.examAnswers[index]) {
      return 0;
    }
    let c = 0;
    for (const a of this.examAnswers[index].answers) {
      if (a.status !== this.answerStatus.noAnswer) {
        c++
      }
    }
    return c;
  }
  private checkDirty(): void {
    for (let f of this.forms) {
      if (f.dirty) {
        this.isDirty = true;
        break;
      }
    }
  }
  getImageUrl(src: string): string {
    let width = 2560;
    if(this.isSpView) {
      width = 960;
    }
    return `https://storage.googleapis.com/${environment.publicAssetsBucketName}/${src}/${width}`;
  }
  getProgress(): string {
    if (!this.examFrames?.examinationFrame?.questions) {
      return '--';
    }
    return Math.round(( (this.questionIndex ) / this.examFrames.examinationFrame.questions.length) * 100 ).toString();
  }
  getProgressStyle(): string {
    if (!this.getProgress) {
      return '';
    }
    const result = this.getProgress();
    return `width:${result}%`;
  }
  async buildChart(id: string): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, 1000));
    const element = document.getElementById(id) as HTMLCanvasElement;
    if (!element) {
      return;
    }
    const contextRadio = element.getContext('2d');
    const params: { labels: string[], counter: number[] } = {
      labels: ['正解','不正解','未回答'],
      counter: [
        this.latestAnswers.answers.filter(a => a.isCorrect === true).length,
        this.latestAnswers.answers.filter(a => (a.isCorrect === false && a.answer.length > 0)).length,
        this.latestAnswers.answers.filter(a => (a.isCorrect === false && a.answer.length === 0)).length,
      ]
    };
    this.chart = new Chart(
      contextRadio,
      {
        type: 'doughnut',
        data: {
          labels: params.labels,
          datasets: [{
            label: '',
            data: params.counter,
            backgroundColor: this.colors,
            hoverOffset: 4
          }]
        },
        options: {},
        plugins: []
      }
    );
  }
  getCorrectRate(): number {
    return Math.round((this.getNumOfCorrect() / this.examFrames.examinationFrame.questions.length) * 100);
  }
  getNumOfCorrect(): number {
    return  this.latestAnswers?.answers.filter(a => a.isCorrect === true).length;
  }
  isCorrect(qIndex: number, optionValue: string): boolean {
    return (this.latestAnswers?.answers[qIndex].answer.findIndex(a => a === optionValue) !== -1 && this.latestAnswers.answers[qIndex].isCorrect)
  }
  isIncorrect(qIndex: number): boolean {
    return (this.latestAnswers?.answers[qIndex].answer.length > 0 && !this.latestAnswers.answers[qIndex].isCorrect)
  }
  isNotAnswered(qIndex: number): boolean {
    return (this.latestAnswers?.answers[qIndex].answer.length === 0 && !this.latestAnswers.answers[qIndex].isCorrect)
  }
  getClassNameForSelectOption(qIndex: number, optionValue: string): string {
    if (!this.latestAnswers) {
      return '';
    }
    if (!this.examFrames) {
      return '';
    }
    const targetQuestion = this.examFrames.examinationFrame.questions[qIndex];
    const targetAnswer = this.latestAnswers?.answers[qIndex];
    if (this.isContainCorrectOption(targetQuestion, optionValue)) {
      // 正解スタイルを出す条件： オプションの値が一致すれば、ユーザーの回答は関係無く「正解表示」
      return 'p-explanation__choices__item-correct';
    } else if (
      // 不正解スタイルを出す条件： オプションの値が一致する && この問題の不正解　&& ユーザーの回答が間違い
      this.isContainOption(targetQuestion, optionValue)
      && !this.judgeAnswer(targetQuestion, [optionValue])
      && !this.judgeAnswer(targetQuestion, targetAnswer.answer)
      && this.isContainOptions(optionValue, targetAnswer.answer)
    ) {
      return 'p-explanation__choices__item-incorrect';
    } else {
      return '';
    }
  }
  private isContainCorrectOption(question: ExamQuestion, optionValue: string): boolean {
    if (!question){
      return false;
    }
    switch (question?.type) {
      case 'radio':
        const correct = question.selectOptions.find(o => o.isCollect === true);
        return optionValue === correct.name;
      case 'check':
        let c = 0;
        const corrects = question.selectOptions.filter(o => o.isCollect === true);
        for (let correct of corrects) {
       　   if (correct.name === optionValue) {
            c++;
          }
        }
        return c > 0;
    }
    return false;
  }
  private isContainOption(question: ExamQuestion, optionValue: string): boolean {
    if (!question){
      return false;
    }
    if (question.selectOptions.findIndex(o => o.name === optionValue) >-1) {
      return true;
    }
    return false;
  }
  private isContainOptions(optionValue: string, answers: string[]): boolean {
    if (!optionValue){
      return false;
    }
    let c = 0;
    for (let a of answers) {
      if (optionValue === a) {
        c++;
      }
    }
    return c > 0;
  }
  clickPause(): void {
    this.isStartTimer = false;
  }
  clickRestart(): void {
    this.isStartTimer = true;
    this.timerCountDown();
  }
}
