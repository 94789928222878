<ng-container>
  <div class="l-signup">
    <div class="p-signup">
      <div class="p-signup__head">
        <div class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div class="p-signup__main__title" translate>メールリンクログイン</div>
        <div class="p-signup__main__form">
          <div class="p-signup__main__form__input">
            <label>
              <span translate>メールアドレス</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <input
              [formControl]="email"
              type="text"
              name="email"
              id="email"
              [placeholder]="'メールアドレス' | translate"
            >
          </div>
          <div class="c-form-error" *ngIf="showError" translate>メールアドレスを入力してください。</div>
        </div>

        <div class="p-signup__main__buttons">
          <div class="p-signup__main__buttons__inner">
            <div class="p-signup__main__buttons__inner__button">
              <button
                type="button"
                class="c-button c-button-login"
                (click)="onSubmit()"
              ><span translate>ログインリンクを送信</span></button>
            </div>
            <div class="p-signup__main__notes">
              <p translate>「ログインリンクを送信」ボタンを押すと、ご入力されたメールアドレス宛にログインリンクURLのご案内メールが送信されます。</p>
              <p translate>「fire-rocket.jp」ドメインより届きます。 メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
              <p translate> メールアドレスの有効性確認がお済みではない場合はメールリンクログインはご利用頂けません。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
