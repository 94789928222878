import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {User} from "../../models/user";
import {Account} from "../../models/account";
import {Chat, ChatWays, Content, ContentTypes} from "../../models/chat";
import {FormControl, Validators} from "@angular/forms";
import {ChatService} from "../../services/chat/chat.service";
import {BackendService} from "../../services/backend/backend.service";
import {Store} from "@ngrx/store";
import {Channel} from "../../models/channel";
import {PublicImage} from "../../models/public-image";
import {Subscription} from "rxjs";
import {UiState} from "../../store/ui/ui.reducer";
import {Unread, unreadCategories} from "../../models/unread";
import {map} from "rxjs/operators";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-chat-user',
  templateUrl: './chat-user.component.html',
  styleUrls: ['./chat-user.component.scss']
})
export class ChatUserComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isOpenModal: boolean;
  @Input() onUser: boolean;
  @Input() channel: Channel;
  @Input() user: User;
  @Input() targetAccount: Account;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  @ViewChild('chatWindow') chatRef: ElementRef;
  chatSubscription: Subscription;
  chats: Chat[] = [];
  body = new FormControl<string>(null, {
    validators: [
      Validators.required,
    ]
  });
  title: string;
  isOpenSomeModal = {
    fileUpload: false,
  };
  targetImage: PublicImage;
  chatWays = ChatWays;
  contentTypes = ContentTypes;
  constructor(
    private chatService: ChatService,
    private backendService: BackendService,
    private authService: AuthService,
  ) {}
  async ngOnInit(): Promise<void> {}
  ngOnDestroy(): void {
    this.chatSubscription?.unsubscribe();
  }
  closeModal(): void {
    this.isOpenModal = false;
    this.isOpenModalEmitter.emit(false);
  }
  async ngOnChanges(changes: SimpleChanges) {
    this.chatSubscription?.unsubscribe();
    this.chats = [];
    if (this.isOpenModal) {
      this.title = this.channel.name + 'さん';
      this.chatService.connectSnapShots(
        this.targetAccount.id,
        this.user.id
      );
      this.chatSubscription = this.chatService.docs
        .pipe(
          map((chats) => chats)
        )
        .subscribe(async (chats) => {
          chats.forEach((chat) => {
            const newChat = chat as Chat;
            if (this.chats.findIndex(c => c.id === newChat.id) === -1) {
              this.chats.push(chat as Chat);
              this.chats.sort((a, b) => a.timestamp - b.timestamp);
            }
          });
        });
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.scrollToEnd();
    }
  }
  async sendChat(): Promise<void> {
    if (this.body.invalid) {
      return;
    }
    const chat = new Chat(ChatWays.received);
    chat.content = new Content(ContentTypes.text);
    chat.accountId = this.channel.accountId;
    chat.channelId = this.channel.id;
    chat.uid = this.user.id;
    chat.content.body = this.body.value;
    chat.createdAt = new Date();
    chat.timestamp = chat.createdAt.getTime();
    const unread = new Unread();
    unread.category = unreadCategories.chat;
    unread.accountId = this.channel.accountId;
    unread.channelId = this.channel.id;
    unread.uid = this.user.id;
    unread.body = this.body.value;
    unread.createdAt = chat.createdAt;
    // バックエンド経由でアカウントの未読を書き込む
    const postData = {
      chat: chat,
      unread: unread,
    };
    this.backendService.setIdToken(this.authService.idToken);
    this.backendService.post(
      'lms/chats/post_to_channel',
      postData
    )
      .then(async () => {
        this.body.setValue(null);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.scrollToEnd();
      })
      .catch();
  }
  openUploadModal(): void {
    this.isOpenSomeModal.fileUpload = true;
  }
  changeOpenUploadModal(status: boolean): void {
    this.isOpenSomeModal.fileUpload = status;
  }
  afterUploadImage(image: PublicImage): void {
    if (!image) {
      return;
    }
  }
  scrollToEnd(): void {
    const y = this.chatRef.nativeElement.scrollHeight;
    this.chatRef.nativeElement.scrollTop = y;
  }
}
