import {Component} from '@angular/core';
import {routes} from "../../app-routing.module";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  pageList = routes;
  // !env.prod の時だけ開けるようにする
  prod = environment.production;
}
