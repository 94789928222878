import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {ChannelsService} from "../../services/channels/channels.service";
import {Channel} from "../../models/channel";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {categories, Category} from "../../models/categories";
import {environment} from "../../../environments/environment";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {map} from "rxjs/operators";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Course} from "../../models/course";
import {CoursesService} from "../../services/courses/courses.service";
import {CountersService} from "../../services/counters/counters.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-course-create',
  templateUrl: './manager-channel-course-create.component.html',
  styleUrls: ['./manager-channel-course-create.component.scss']
})
export class ManagerChannelCourseCreateComponent implements OnInit, OnDestroy {
  private channelSubscription: Subscription;
  forbidden: boolean = false;
  currentChannel: Channel = new Channel();
  formCategories: Category[]  = categories;
  baseUrl: string = environment.hostName;
  showError = false;
  channelId: string = '';
  name = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.maxLength(100),
      Validators.minLength(4),
    ]
  });
  category = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  privacy = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
      Validators.requiredTrue
    ]
  });
  contract = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
      Validators.requiredTrue
    ]
  });
  form = this.formBuilder.group({
    name: this.name,
    category: this.category,
    privacy: this.privacy,
    contract: this.contract,
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private accountService: AccountsService,
    private courseService: CoursesService,
    private counter: CountersService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.uiStore.dispatch(switchTrueLoading());
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
  }
  async ngOnInit(): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.user.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    await this.channelsService.connectSnapShot(this.channelId);
    this.channelSubscription = this.channelsService.currentChannel
      .pipe(
        map((channel: Channel) => channel)
      )
      .subscribe(async (channel) => {
        if (!channel.slug) {
          await this.router.navigate(['/manager/channels']);
          return;
        }
        this.currentChannel = channel;
      });
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    this.uiStore
      .dispatch(storeUrlState({ urlState: urlState}));
    this.uiStore.dispatch(switchFalseLoading());
  }
  ngOnDestroy(): void{
    this.channelSubscription?.unsubscribe();
  }
  openContractsLink(): void {
    window.open('/contracts', '_blank');
  }
  openPrivacyLink(): void {
    window.open('https://www.grab-design.com/privacy/', '_blank');
  }
  async onSubmit(): Promise<void> {
    this.showError = false;
    this.uiStore.dispatch(switchTrueLoading());
    if (!this.form.valid) {
      this.showError = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (!this.authService.user.currentAccountId) {
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (!this.channelId) {
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (!this.currentChannel.slug) {
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const account = await this.accountService
      .fetchAccount(this.authService.user.currentAccountId);
    if (!account) {
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const course = new Course();
    course.name = this.name.value;
    course.accountId = this.authService.user.currentAccountId;
    course.channelId = this.channelId;
    course.categoryId = this.category.value;
    const now = new Date();
    course.createdAt = now;
    course.updatedAt = now;
    course.createdBy = this.authService.uid;
    course.updatedBy = this.authService.uid;
    await this.courseService.create(course);
    this.counter.inc(
      {
        accountId: course.accountId,
        countName: 'courses',
        term: '',
        addKey: '',
        value: 1,
      }
    );
    await this.router.navigate([`/manager/channel/courses/${this.channelId}/all`]);
    this.toasterService.success(this.translateService.instant('コースを作成しました。', {}));
    this.uiStore.dispatch(switchFalseLoading());
  }
}
