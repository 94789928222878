import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {AuthService} from "../../services/auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {TranslateService} from "@ngx-translate/core";
import {LanguagesService} from "../../services/languages/languages.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent {
  @Input() isOpenLoginModal = false;
  @Output() isOpenLoginModalEmitter = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private uiStore: Store<{ ui: UiState }>,
    private authService: AuthService,
    private toaster: ToastrService,
    private translateService: TranslateService,
    private languagesService: LanguagesService
  ) { }
  showError = false;
  showPassword = false;
  enableSso = environment.ssoEnableMicrosoft;
  email = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  password = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  loginForm = this.formBuilder.group({
    email: this.email,
    password: this.password,
  });
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
  showPasswordToggle(): void {
    this.showPassword = !this.showPassword
  }
  async onSubmit(): Promise<void> {
    this.showError = false;
    if (!this.loginForm.valid) {
      this.showError = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.showError = false;
    this.authService.login(this.email.value, this.password.value)
      .then(async () => {
        await this.authService.refresh();
        await this.languagesService.change(this.authService.user?.language, this.authService.uid);
        this.toaster.success(this.translateService.instant('ログインしました。', {}));
        this.isOpenLoginModal = false;
        this.uiStore.dispatch(switchFalseLoading());
        location.reload();
      })
      .catch((reason) => {
        console.log(reason);
        this.showError = true;
        this.uiStore.dispatch(switchFalseLoading());
      });
  }
  closeModal(): void {
    this.isOpenLoginModalEmitter.emit(false);
  }
}
