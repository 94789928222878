import { Component, OnInit } from '@angular/core';
import {Channel} from "../../models/channel";
import {Course} from "../../models/course";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {ContentFrame, SlideFrame, VideoFrame} from "../../models/course-frames";
import {storeHeaderLogoSrc, switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {Tag} from "../../models/tag";
import {ContentTagsService} from "../../services/content-tags/content.tags.service";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {ChannelInvitationsService} from "../../services/channel-invitations/channel-invitations.service";
import {ChannelSubscription} from "../../models/channel-subscription";
import {ChannelInvitation} from "../../models/channel-invitation";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {CourseSubscription} from "../../models/course-subscription";
import {MonetizeMethods} from "../../models/monetize-methods";
import {PublishRangesKeys} from "../../models/publish-range";
import {CourseProgress} from "../../models/course-progress";
import {Account, AccountType} from "../../models/account";
import {environment} from "../../../environments/environment";
import {AccountsService} from "../../services/accounts/accounts.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-channel-course-top',
  templateUrl: './channel-course-top.component.html',
  styleUrls: ['./channel-course-top.component.scss']
})
export class ChannelCourseTopComponent implements OnInit {
  isOpenModal = false;
  isOpenLoginModal = false;
  isOpenCreditModal = false;
  channelId: string;
  slug: string;
  courseId: string;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  currentCourse: Course = new Course();
  videoFrames: ContentFrame;
  slideFrames: ContentFrame;
  kvIndex = 0;
  protected readonly MonetizeMethods = MonetizeMethods;
  tagPayload: Tag[] = [];
  channelSubscriptionStatus: ChannelSubscription;
  channelInvitationStatus: ChannelInvitation;
  courseSubscriptionStatus: CourseSubscription;
  courseProgress: CourseProgress;
  isMyChannel = false;
  hasChannelPermission = false;
  monetizeMethods = MonetizeMethods;
  isPublished = false;
  isNotFound = false;
  accountType = AccountType;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToastrService,
    private accountService: AccountsService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private courseProgressesService: CourseProgressesService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private contentTagsService: ContentTagsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0)
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    // チャンネルを取得
    this.currentChannel = await this.channelsService.fetchBySlug(this.slug);
    this.channelId = this.currentChannel?.id;
    this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel?.accountId);
    if (this.channelOwnerAccount?.accountType === AccountType.oem
      && this.channelOwnerAccount?.logoImage?.src
    ) {
      this.uiStore.dispatch(storeHeaderLogoSrc(
        this.channelsService.getLogoImageUrl(this.channelOwnerAccount.logoImage.src)
      ));
    } else {
      this.uiStore.dispatch(storeHeaderLogoSrc(environment.productMainLogoSrc));
    }
    // コースを取得
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    this.getImages().then();
    this.tagPayload = await this.contentTagsService.gatheringTags(this.currentCourse?.tags);
    // チャンネルの公開範囲をチェックする
    await new Promise(resolve => setTimeout(resolve, 200));
    // 未ログイン時の公開範囲判定
    if (this.authService.isAnonymous) {
      // チャンネルの公開範囲をチェックする
      if (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.public) {
        this.isPublished = true;
        this.uiStore.dispatch(switchFalseLoading());
      } else {
        console.log('404');
        this.isNotFound = true;
        this.uiStore.dispatch(switchFalseLoading());
        return ;
      }
    }
    // チャンネルの購読者取得
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(
      this.currentChannel?.id,
      this.authService.uid
    )
    if (!this.channelSubscriptionStatus) {
      this.channelSubscriptionStatus = new ChannelSubscription();
    }
    // チャンネルの管理者かどうかをチェックする
    if (this.authService.user?.currentAccountId === this.currentChannel?.accountId) {
      this.isMyChannel = true;
    }
    // チャンネルの公開範囲をチェックする
    console.log(this.currentChannel);
    if (this.isMyChannel
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.public)
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.forInvitationOnly && this.channelSubscriptionStatus.activated)
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.forChannelMembershipOnly && this.channelSubscriptionStatus.activated)
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.forAccountUsersOnly && this.isMyChannel)
    )
    {
      this.isPublished = true;
    } else {
      console.log('404');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // チャンネルの権限があるかどうかを最終判断する
    if (this.isMyChannel
      || (this.currentChannel?.published && this.channelSubscriptionStatus?.activated)
      || (this.currentChannel?.published && this.channelInvitationStatus?.activated )
    )
    {
      this.hasChannelPermission = true;
    }
    // コースの権限を取得する
    this.courseSubscriptionStatus = await this.courseSubscriptionsService.fetch(
      this.currentCourse?.id,
      this.authService.uid
    )
    if (!this.courseSubscriptionStatus) {
      this.courseSubscriptionStatus = new CourseSubscription();
    }
    // コースの公開範囲をチェックする
    if (this.isMyChannel
      || (this.currentCourse?.published && this.currentCourse.publishRange === PublishRangesKeys.public)
      || (this.currentCourse?.published && this.currentCourse.publishRange === PublishRangesKeys.forInvitationOnly && this.channelInvitationStatus.activated)
      || (this.currentCourse?.published && this.currentCourse.publishRange === PublishRangesKeys.forChannelMembershipOnly && this.channelSubscriptionStatus.activated)
      || (this.currentCourse?.published && this.currentCourse.publishRange === PublishRangesKeys.forAccountUsersOnly && this.isMyChannel)
    )
    {
      this.isPublished = true;
    } else {
      console.log('404');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // 完了
    this.uiStore.dispatch(switchFalseLoading());
  }
  hasPermission(): boolean {
    if (this.currentCourse.monetized) {
      // TODO 課金状況を取得
      return  true;
    } else if (this.currentCourse.monetizeMethod === MonetizeMethods.freeTrial && this.courseSubscriptionStatus.activated) {
      return  true;
    } else {
      return  false;
    }
  }
  async getImages(): Promise<void> {}
  changeEyeCatch(index: number): void {
    this.kvIndex = index;
  }
  openModal(): void {
    this.isOpenModal = true;
  }
  closeModal(): void {
    this.isOpenModal = false;
  }
  changeLoginModal(status: boolean): void {
    this.isOpenLoginModal = status;
  }
  async applySubscriptionFree(): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    await this.courseSubscriptionsService.create(
      this.currentCourse.accountId,
      this.currentChannel.id,
      this.currentCourse.id,
      this.authService.uid
    )
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.courseSubscriptionStatus = await this.courseSubscriptionsService.fetch(
      this.currentCourse.id,
      this.authService.uid
    )
    await this.createProgress();
    this.toasterService.success(this.translateService.instant('ご登録ありがとうございます！'), this.currentCourse.name);
    this.uiStore.dispatch(switchFalseLoading());
    this.isOpenModal = false;
  }
  async createProgress(): Promise<void> {
    if (!this.courseProgress) {
      // 無ければ作る
      await this.courseProgressesService.create(
        this.currentCourse.id,
        this.authService.uid
      )
      this.courseProgress = await this.courseProgressesService.fetchByMy(
        this.currentCourse.id,
        this.authService.uid
      );
    }
  }
  moveContentVideo(frame: VideoFrame): void {
    if (!this.hasPermission) {
      this.toasterService.warning(this.translateService.instant('このコンテンツを閲覧するにはお申し込みが必要です。'));
      return;
    }
    const url=`/channel/course/video/${this.currentChannel.slug}/${this.currentCourse.id}/${frame.contentKey}`;
    this.router.navigate([url]).then();
    return;
  }
  moveContentSlide(frame: SlideFrame): void {
    if (!this.hasPermission) {
      this.toasterService.warning(this.translateService.instant('このコンテンツを閲覧するにはお申し込みが必要です。'));
      return;
    }
    const url=`/channel/course/slide/${this.currentChannel.slug}/${this.currentCourse.id}/${frame.contentKey}`;
    this.router.navigate([url]).then();
    return;
  }

  applySubscription(): void {
    // TODO 課金に進む
    this.toasterService.warning(this.translateService.instant('現在課金設定は無効になっています。'));
  }
  applySubscriptionPurchase(): void {
    // TODO 課金に進む
    this.toasterService.warning(this.translateService.instant('現在課金設定は無効になっています。'));
  }
}
