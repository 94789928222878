import {createReducer, on, createSelector, createFeatureSelector, Action} from '@ngrx/store';
import * as SessionsActions from './sessions.actions';
import {AccountRole, User} from "../../models/user";
import {AppConfig} from "../../models/app-config";

export const sessionFeatureKey = 'sessions';

export class SessionState {
  currentUser: CurrentUser;
  users: User[];
  isAnonymous: boolean;
  isOem: boolean;
  appConfig: AppConfig | null;
}
export class CurrentUser {
  id?: string;
  language?: 'ja' | 'en';
  displayName?: string;
  photoUrl?: string;
  currentAccountId?: string;
  roles?: AccountRole;
  emailValid?: boolean;
  constructor() {
    this.id = undefined;
    this.language = undefined;
    this.displayName = undefined;
    this.photoUrl = undefined;
    this.currentAccountId = undefined;
    this.roles = undefined;
    this.emailValid = undefined;
  }
}
export const initialState: SessionState = {
  currentUser: undefined,
  users: undefined,
  isAnonymous: undefined,
  isOem: undefined,
  appConfig: undefined,
};

const _sessionsReducer = createReducer(
  initialState,
       on(SessionsActions.storeUsers, (state, { payload }) => ({...state, users: payload })),
  on(SessionsActions.storeUser, (state, { payload }) => ({...state, currentUser: payload })),
  on(SessionsActions.storeIsAnonymous, (state, { payload }) => ({...state, isAnonymous: payload })),
  on(SessionsActions.storeAppConfig, (state, { payload }) => ({...state, appConfig: payload })),
);
export function sessionsReducer(state: SessionState, action: Action): SessionState {
  return _sessionsReducer(state, action);
}
const getState = createFeatureSelector<SessionState>(sessionFeatureKey);
export const setSession = createSelector(
  getState,
  state => state
);
export const getCurrentUser = createSelector(
  getState,
  state => state.currentUser
);
export const getUsers = createSelector(
  getState,
  state => state.users
);
export const getIsAnonymous = createSelector(
  getState,
  state => state.isAnonymous
);
export const getIsOem = createSelector(
  getState,
  state => state.isOem
);
export const getAppConfig = createSelector(
  getState,
  state => state.appConfig
);
