<app-header-contents-channel></app-header-contents-channel>
<app-not-found *ngIf="isNotFound"></app-not-found>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-course">
      <div class="p-course__head">
        <div class="p-course__head__player">
          <img
            *ngIf="!surveyFrames?.eyeCatchImage?.src"
            ngSrc="no-image"
            width="160"
            height="90"
            ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
            alt="">
          <img
            *ngIf="surveyFrames?.eyeCatchImage?.src"
            ngSrc="{{surveyFrames?.eyeCatchImage?.src}}"
            width="160"
            height="90"
            ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
            alt="">
        </div>
        <div class="p-course__head__courseMetaWrap">
          <div class="p-courseMeta">
            <div *ngIf="surveyFrames?.surveyFrame?.type === surveyType.checkList" class="p-courseMeta__lectureType" translate>チェックリスト</div>
            <div *ngIf="surveyFrames?.surveyFrame?.type === surveyType.survey" class="p-courseMeta__lectureType" translate>アンケート</div>
            <div class="p-courseMeta__title">{{surveyFrames?.name}}</div>

            <div class="p-courseMeta__surveyProgress">
              <div class="p-courseMeta__surveyProgress__details">
                <div class="p-courseMeta__surveyProgress__details__progress">
                  <div class="p-courseMeta__surveyProgress__details__progress__left">{{countNumOfAnswered()}}</div>
                  <div class="p-courseMeta__surveyProgress__details__progress__divider">/</div>
                  <div class="p-courseMeta__surveyProgress__details__progress__right">{{surveyFrames?.surveyFrame?.questions?.length}}</div>
                </div>
                <div class="p-courseMeta__surveyProgress__details__note">
                  {{surveyFrames?.surveyFrame?.questions?.length}}<span translate>問中</span>
                  {{countNumOfAnswered()}}<span translate>問に回答</span>
                </div>
                <div class="p-courseMeta__surveyProgress__details__updatedAt">
                  <span translate>更新日</span> {{latestAnswers?.updatedAt | datetimeFormatFirestore}}
                </div>
              </div>
              <div class="p-courseMeta__surveyProgress__buttons">
                <div class="p-courseMeta__surveyProgress__buttons__button">
                  <button
                    (click)="openHistoryModal()"
                    type="button" class="c-button c-button-monotone"><span translate>過去の回答を見る</span></button>
                </div>
              </div>
            </div>

            <div class="p-courseMeta__detail">{{surveyFrames?.note}}</div>
            <!--<button class="p-courseMeta__moreButton">もっと見る</button>-->
            <ng-container *ngIf="surveyFrames?.surveyFrame.displayChannelInfo">
              <div class="p-courseMeta__user">
                <div class="p-user">
                  <div class="p-user__avatar p-user__avatar-small">
                    <img
                      *ngIf="!currentChannel?.profileImage?.src"
                      ngSrc="no-image"
                      width="160"
                      height="90"
                      ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                      alt="">
                    <img
                      *ngIf="currentChannel?.profileImage?.src"
                      ngSrc="{{currentChannel?.profileImage?.src}}"
                      width="160"
                      height="90"
                      ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                      alt="">
                  </div>
                  <div class="p-user__profile">
                    <div class="p-user__profile__name">
                      {{currentChannel?.name}}
                    </div>
                    <div class="p-user__profile__id">
                      &#64;{{currentChannel?.slug}}
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="p-courseMeta__actionButtons">
                <button class="p-courseMeta__actionButtons__good">123</button>
                <button class="p-courseMeta__actionButtons__bookMark">ブックマーク</button>
                <button class="p-courseMeta__actionButtons__share">共有</button>
              </div>-->
            </ng-container>
          </div>
        </div>
      </div>
      <div class="p-course__cert">
        <!--<div class="p-course__cert__report">
          <div class="p-course__cert__report__heading">配点</div>
          <div class="p-course__cert__report__body">
            <div class="p-course__cert__report__body__score">100%</div>
            <div class="p-course__cert__report__body__result">合格</div>
            <div class="p-course__cert__report__body__pass-badge"></div>
          </div>
        </div>-->
        <!--<div class="p-course__cert__report">
          <div class="p-course__cert__report__heading">配点</div>
          <div class="p-course__cert__report__body">
            <div class="p-course__cert__report__body__score">76%</div>
            <div class="p-course__cert__report__body__result">不合格</div>
          </div>
        </div>-->
        <!--<div class="p-course__cert__passingLine">
          <div class="p-course__cert__passingLine__heading">配点と合格基準</div>
          <div class="p-course__cert__passingLine__body">正答率80%以上</div>
        </div>-->
        <!--<div class="p-course__cert__buttons">
          <div class="p-course__cert__buttons__button">
            <button class="c-button c-button-cancel">キャンセル</button>
          </div>
          <div class="p-course__cert__buttons__button">
            <button class="c-button c-button-primary">テストを開始する</button>
          </div>
        </div>-->
        <div class="p-course__cert__questions">
          <div class="p-questions">

            <ng-container *ngFor="let question of surveyFrames?.surveyFrame?.questions;index as qIndex">

                <div class="p-questions__item">
                  <div *ngIf="question.title" class="p-questions__item__heading" [innerHTML]="question.title"></div>
                  <div *ngIf="question.body" class="p-questions__item__detail" [innerHTML]="question.body | nl2br"></div>

                  <ng-container *ngIf="question?.images?.length > 0">
                    <div class="p-questions__item__imageWrap">
                      <div class="p-questions__item__imageWrap__head">
                        <img
                          ngSrc="{{getSelectedImageSrc(qIndex)}}"
                          width="1280"
                          height="720"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                      </div>

                      <div class="p-questions__item__imageWrap__gallery">
                        <ng-container *ngFor="let image of question?.images;index as imageIndex">
                          <div class="p-questions__item__imageWrap__gallery__item">
                            <img
                              (click)="changeSelectedImageSrc(qIndex, imageIndex)"
                              ngSrc="{{image?.src}}"
                              width="1280"
                              height="720"
                              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                              alt="">
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>


                  <ng-container *ngIf="!question?.images">
                    <div *ngIf="question?.image?.src" class="p-questions__item__imageWrap">
                      <div class="p-questions__item__imageWrap__head">
                        <img
                          *ngIf="question?.image?.src"
                          ngSrc="{{question?.image?.src}}"
                          width="1280"
                          height="720"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                      </div>
                    </div>
                  </ng-container>


                  <div *ngIf="question.note" class="p-questions__item__note" [innerHTML]="question.note | nl2br"></div>

                  <ng-container *ngIf="question.type === answerTypes.radio">
                    <div class="p-questions__item__answer">
                      <ng-container *ngFor="let s of question.selectOptions;index as sIndex">
                        <div class="p-questions__item__answer__radio">
                          <input
                            *ngIf="forms[qIndex]"
                            [formControl]="forms[qIndex]"
                            [checked]="latestAnswers?.answers[qIndex]?.answer[0] === s?.name"
                            (change)="answerUpdate(question.contentKey, s?.name, $event)"
                            type="radio" name="answer-{{qIndex}}" [value]="s?.name" id="answer-{{qIndex}}-{{sIndex}}">
                          <label for="answer-{{qIndex}}-{{sIndex}}">{{s?.name}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="question.type === answerTypes.check && forms[qIndex]">
                    <div class="p-questions__item__answer">
                      <ng-container *ngFor="let s of question.selectOptions;index as sIndex">
                        <div class="p-questions__item__answer__checkbox">
                          <input
                            [checked]="checkboxIsChecked(qIndex, s?.name)"
                            (change)="answerUpdate(question.contentKey, s?.name, $event)"
                            type="checkbox" name="answer-{{qIndex}}" [value]="s?.name" id="answer-{{qIndex}}-{{sIndex}}">
                          <label for="answer-{{qIndex}}-{{sIndex}}">{{s?.name}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="question.type === answerTypes.describe && forms[qIndex]">
                    <div class="p-questions__item__answer">
                      <div class="p-questions__item__answer__textArea">
                        <textarea
                          [formControl]="forms[qIndex]"
                          [readOnly]="isHistoryMode"
                          name="answer-{{qIndex}}"
                          id="answer-{{qIndex}}"
                          rows="10"
                          (change)="answerUpdate(question.contentKey, '', $event)"
                          (keydown)="answerUpdate(question.contentKey, '', $event)"
                        ></textarea>
                      </div>
                    </div>
                  </ng-container>


                </div>
            </ng-container>
          </div>
        </div>

        <div class="p-course__survey__buttons">
          <div class="p-course__survey__buttons__button">
            <button (click)="backToIndex()" class="c-button c-button-primary">
              <p *ngIf="!isDirty" translate>回答する</p>
              <p *ngIf="isDirty" translate>回答する</p>
            </button>
          </div>
        </div>

        <div class="p-course__head__buttons">
          <div class="p-course__head__buttons__inner">
            <button *ngIf="isExistRev()" (click)="clickRev()" class="p-course__head__buttons__inner__left"><span translate>前の目次へ</span></button>
            <div class="p-course__head__buttons__inner__center">
              <button (click)="backToIndex()" class="c-button c-button-primary"><span translate>目次へ戻る</span></button>
            </div>
            <button *ngIf="isExistFwd()" (click)="clickFwd()" class="p-course__head__buttons__inner__right"><span translate>次の目次へ</span></button>
          </div>
        </div>


        <!--<div class="p-course__cert__buttons">
          <div class="p-course__cert__buttons__button">
            <button class="c-button c-button-cancel">キャンセル</button>
          </div>
          <div class="p-course__cert__buttons__button">
            <button class="c-button c-button-primary">答えを提出する</button>
          </div>
        </div>-->



      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="channelOwnerAccount?.accountType === accountType.general"></app-footer>
<app-footer-business *ngIf="channelOwnerAccount?.accountType === accountType.oem"></app-footer-business>


<ng-container *ngIf="isOpenHistoryModal">
  <section class="l-modal">
    <div class="c-modal">
      <div class="c-modal__head">
        <div class="c-modal__head__title" translate>
          過去の回答
        </div>
        <div class="c-modal__head__closeButton" (click)="closeHistoryModal()"></div>
      </div>
      <div class="c-modal__main">
        <div class="c-modal__main__list">
          <div class="c-simpleList">
            <p *ngIf="surveyAnswers?.length === 0" translate>履歴はありません</p>
            <ng-container *ngFor="let a of surveyAnswers; index as i">
              <div (click)="showHistory(i)" class="c-simpleList__row">
                <div class="c-simpleList__row__date">{{a?.updatedAt | datetimeFormatFirestore}}</div>
                <div class="c-simpleList__row__status">{{countNumOfAnsweredForHistory(i)}}/{{a?.answers.length}}</div>
                <div class="c-simpleList__row__message">
                  {{a?.answers.length}}<span translate>問中</span>
                  {{countNumOfAnsweredForHistory(i)}}<span translate>問に回答</span></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

</ng-container>
