import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {Router, Routes} from "@angular/router";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {routes} from "../../app-routing.module";
import {breadCrumbs, BreadCrumbList} from "../../models/breadcrumb-list";
import {categories} from "../../models/categories";
import {getSideNavi, UiState} from "../../store/ui/ui.reducer";
import {
  storeHeaderLogoSrc,
  switchFalseShowSideNavigation,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {AuthService} from "../../services/auth/auth.service";
import {User} from "../../models/user";
import {ChannelsService} from "../../services/channels/channels.service";
import {Channel} from "../../models/channel";
import {FormControl} from "@angular/forms";
import {LanguagesService} from "../../services/languages/languages.service";
import {TranslateService} from "@ngx-translate/core";
import {CurrentUser, SessionState, setSession} from "../../store/sessions/sessions.reducer";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  isShowMenu = true;
  private sideNaviSubscription: Subscription;
  private breadcrumbSubscription: Subscription;
  private sessionsSubscription: Subscription;
  currentSession: SessionState | undefined = undefined;
  currentUser: CurrentUser = new CurrentUser();
  path: string;
  title: string;
  current: BreadCrumbList;
  tree: BreadCrumbList[] = [];
  isShow = true;
  isOpenNavi = true;
  pageList: Routes = routes;
  breadCrumbs = breadCrumbs;
  user: User;
  displayName: string = '';
  listOfChannels: Channel[] = [];
  protected readonly categories = categories;
  urlParams: string[];
  @Input() currentPageId: string;
  @Output() currentLanguageEmitter = new EventEmitter<'ja' | 'en'>();
  languages = new FormControl<'ja' | 'en'>(this.languagesService.getDefaultLanguage());
  constructor(
    private router: Router,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState}>,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private languagesService: LanguagesService,
    protected translateService: TranslateService,
    private sessionStore: Store<{ session: UiState}>,
  ) { }
  async ngOnInit(): Promise<void> {
    // ログイン状態取得
    this.sessionsSubscription = this.sessionStore
      .select(setSession)
      .pipe()
      .subscribe(async (sessionState) => {
        this.currentSession = sessionState;
        this.currentUser = sessionState?.currentUser;
        this.user = this.authService?.user;
        // 表示名設定
        if (this.currentUser?.displayName) {
          this.displayName = this.currentUser.displayName;
        } else if (this.user?.fullName) {
          this.displayName = this.user.fullName;
        } else if (this.user?.familyName) {
          this.displayName = this.user.familyName;
        } else {
          this.displayName = '';
        }
        // チャンネルリスト取得
        this.channelsService.fetched = [];
        if (this.authService?.user?.currentAccountId) {
          await this.channelsService.fetchByAccountId(this.currentUser?.currentAccountId);
          this.listOfChannels = this.channelsService.fetched;
        }
        //　言語
        if (this.currentUser?.language) {
          this.currentLanguageEmitter.emit(this.currentUser?.language);
          this.languages.setValue(this.currentUser.language);
          await this.languagesService.change(this.languages.value, this.currentUser?.id);
        }
        // パンくず処理
        this.buildBreadcrumb();
      });
    // ナビゲーション処理
    this.sideNaviSubscription = this.uiStore.select(getSideNavi)
      .pipe()
      .subscribe((state) => {
        this.isOpenNavi = state;
      });
  }
  ngOnDestroy(): void{
    this.breadcrumbSubscription?.unsubscribe();
    this.sessionsSubscription?.unsubscribe();
  }
  private buildBreadcrumb(): void {
    this.breadcrumbSubscription = this.breadcrumbStore
      .select('breadcrumb')
      .pipe()
      .subscribe(async (breadcrumbState) => {
        this.urlParams = breadcrumbState.urlParams;
        // パンくず処理
        this.isShow = breadcrumbState.showBreadcrumb;
        if (!this.isShow) {
          return;
        }
        this.path = location.pathname.replace('/', '');
        breadcrumbState.urlParams.map((p) => {
          this.path = this.path.replace(`/${p}`, '');
        });
        const tree = [];
        const current = this.breadCrumbs.find(b => b.path === this.path);
        if (!current) {
          return;
        }
        const route = this.pageList.find(r => r.path === this.path + current.paramString);
        if (!route) {
          return;
        }
        const split = route.title.toString().split('|');
        current.title = split[0];
        current.path = this.path;
        this.current = current;
        tree.push(current);
        if (!current.parentPath) {
          this.tree = tree.reverse();
          return;
        }
        // 親1を探す
        const parent1 = breadCrumbs.find(item => item.path === current.parentPath);
        if (!parent1) {
          this.tree = tree.reverse();
          return;
        }
        const route2 = this.pageList.find(r => r.path === current.parentPath + parent1.paramString);
        if (!route2) {
          this.tree = tree.reverse();
          return;
        }
        const split1 = route2.title.toString().split('|');
        parent1.title = split1[0];
        tree.push(parent1);
        if (!parent1.parentPath) {
          this.tree = tree.reverse();
          return;
        }
        // 親2を探す
        const parent2 = breadCrumbs.find(item => item.path === parent1.parentPath);
        if (!parent2) {
          this.tree = tree.reverse();
          return;
        }
        const route3 = this.pageList.find(r => r.path === parent1.parentPath + parent2.paramString);
        if (!route3) {
          this.tree = tree.reverse();
          return;
        }
        const split2 = route3.title.toString().split('|');
        parent2.title = split2[0];
        tree.push(parent2)
        this.tree = tree.reverse();
      });
  }
  move(page: BreadCrumbList): void {
    if (page.path !== this.current.path) {
      const paramString = page.paramString
        .replace(':channelId', this.urlParams[0])
        .replace(':courseId', this.urlParams[1]);
      this.router.navigate([page.path + paramString]);
    }
  }
  toggleNavi(): void {
    this.isOpenNavi = !this.isOpenNavi;
    if (this.isOpenNavi) {
      this.uiStore.dispatch(switchTrueShowSideNavigation());
      return;
    }
    this.uiStore.dispatch(switchFalseShowSideNavigation());
  }
  async changeLanguage(): Promise<void> {
    await this.languagesService.change(this.languages.value, this.user?.id);
    this.currentLanguageEmitter.emit(this.languages.value);
  }
}
