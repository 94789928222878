<div class="l-index" *ngIf="!prod">
  <div class="p-index">
    <div class="p-index__title">ページリスト（開発用画面）</div>
    <ul class="p-index__list">
      <li
        *ngFor="let page of pageList; index as i">
        <div
          routerLink="/{{page.path}}"
          class="p-index__list__title">{{page.title}} <br class="enabledOnSp"> <span style="color: #2647cb;font-weight: bold;">{{page.path}}</span></div>
        <button routerLink="{{page.path}}">open</button>
      </li>
    </ul>
  </div>
</div>
<app-not-found *ngIf="prod"></app-not-found>
