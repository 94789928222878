import {NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AngularFireModule} from "@angular/fire/compat";
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import {environment} from "../environments/environment";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {SharedModule} from "./shared.module";
import {StoreModule} from "@ngrx/store";
import {sessionsReducer} from "./store/sessions/sessions.reducer";
import {uiReducer} from "./store/ui/ui.reducer";
import {breadcrumbReducer} from "./store/breadcrumb/breadcrumb.reducer";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListComponent } from './pages/list/list.component';
import {NotFoundPageComponent} from "./pages/not-found-page/not-found-page.component";
import { TopComponent } from './pages/top/top.component';
import {InquiryComponent} from "./pages/inquiry/inquiry.component";
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { SignupComponent } from './pages/signup/signup.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { BusinessContractComponent } from './pages/business-contract/business-contract.component';
import { EmailLinkComponent } from "./pages/email-link/email-link.component";
import { EmailVerifyComponent } from "./pages/email-verify/email-verify.component";
import { ManagerChannelCourseUsersComponent } from "./pages/manager-channel-course-users/manager-channel-course-users.component";
import { ManagerChannelMetaComponent } from './pages/manager-channel-meta/manager-channel-meta.component';
import { ManagerChannelCourseMetaComponent } from './pages/manager-channel-course-meta/manager-channel-course-meta.component';
import { ManagerChannelCourseRemotesComponent } from './pages/manager-channel-course-remotes/manager-channel-course-remotes.component';
import { ManagerChannelCourseRemotesReservationsComponent } from './pages/manager-channel-course-remotes-reservations/manager-channel-course-remotes-reservations.component';
import { ManagerChannelCourseLivesComponent } from './pages/manager-channel-course-lives/manager-channel-course-lives.component';
import { ManagerChannelCourseLivesReservationsComponent } from './pages/manager-channel-course-lives-reservations/manager-channel-course-lives-reservations.component';
import { ManagerChannelCourseExaminationCreateComponent } from './pages/manager-channel-course-examination-create/manager-channel-course-examination-create.component';
import { ManagerChannelCreateComponent } from './pages/manager-channel-create/manager-channel-create.component';
import { ManagerChannelTopComponent } from './pages/manager-channel-top/manager-channel-top.component';
import { ManagerChannelCoursesComponent } from './pages/manager-channel-courses/manager-channel-courses.component';
import { ChannelCourseTopComponent } from './pages/channel-course-top/channel-course-top.component';
import { ChannelCourseRemoteReservationComponent } from './pages/channel-course-remote-reservation/channel-course-remote-reservation.component';
import { MyPageQuitComponent } from './pages/my-page-quit/my-page-quit.component';
import { MyPagePaymentCreditComponent } from './pages/my-page-payment-credit/my-page-payment-credit.component';
import { MyPageTopComponent } from './pages/my-page-top/my-page-top.component';
import { MyPagePaymentHistoryComponent } from './pages/my-page-payment-history/my-page-payment-history.component';
import { MyPageNotificationsComponent } from './pages/my-page-notifications/my-page-notifications.component';
import { MyPagePasswordComponent } from './pages/my-page-password/my-page-password.component';
import { MyPagePersonalComponent } from './pages/my-page-personal/my-page-personal.component';
import { ManagerChannelsComponent } from './pages/manager-channels/manager-channels.component';
import {
  ManagerChannelCourseCreateComponent
} from "./pages/manager-channel-course-create/manager-channel-course-create.component";
import { ChannelCourseSurveyComponent } from './pages/channel-course-survey/channel-course-survey.component';
import { MyPageAuthSetupComponent } from './pages/my-page-auth-setup/my-page-auth-setup.component';
import { ManagerChannelCourseInspectionApplyComponent } from './pages/manager-channel-course-inspection-apply/manager-channel-course-inspection-apply.component';
import { ManagerChannelCourseInspectionResultComponent } from './pages/manager-channel-course-inspection-result/manager-channel-course-inspection-result.component';
import { ManagerChannelRemoveComponent } from './pages/manager-channel-remove/manager-channel-remove.component';
import { ManagerChannelPaymentComponent } from './pages/manager-channel-payment/manager-channel-payment.component';
import { ManagerChannelReportsComponent } from './pages/manager-channel-reports/manager-channel-reports.component';
import { ManagerChannelPassbookComponent } from './pages/manager-channel-passbook/manager-channel-passbook.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoryTopComponent } from './pages/category-top/category-top.component';
import { ChannelTopComponent} from "./pages/channel-top/channel-top.component";
import { ChannelsComponent} from "./pages/channels/channels.component";
import { ManagerBusinessComponent } from './pages/manager-business/manager-business.component';
import { ManagerUsersComponent } from './pages/manager-users/manager-users.component';
import { ManagerAccountsComponent } from './pages/manager-accounts/manager-accounts.component';
import { ManagerChannelCourseCertComponent } from './pages/manager-channel-course-cert/manager-channel-course-cert.component';
import { ContentsTopComponent } from './pages/contents-top/contents-top.component';
import { ChannelCourseIndexComponent } from './pages/channel-course-index/channel-course-index.component';
import {ManagerChannelUsersComponent} from "./pages/manager-channel-users/manager-channel-users.component";
import {EditUserModalComponent} from "./components/edit-user-modal/edit-user-modal.component";
import {AddUserModalComponent} from "./components/add-user-modal/add-user-modal.component";
import {ChatUserComponent} from "./components/chat-user/chat-user.component";
import {
  ManagerChannelCourseSurveyViewComponent
} from "./pages/manager-channel-course-survey-view/manager-channel-course-survey-view.component";
import {ChannelCourseExaminationComponent} from "./pages/channel-course-examination/channel-course-examination.component";
import {
  ManagerChannelCourseRemoveComponent
} from "./pages/manager-channel-course-remove/manager-channel-course-remove.component";
import {RouteReuseStrategy} from "@angular/router";
import {CustomReuseStrategy} from "./route-reuse-strategy";
import { SystemRequirementsComponent } from './pages/system-requirements/system-requirements.component';
import { SloComponent } from './pages/slo/slo.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  ManagerChannelCourseExaminationViewComponent
} from "./pages/manager-channel-course-examination-view/manager-channel-course-examination-view.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule(
  {
    declarations: [
      AppComponent,
      ListComponent,
      ChannelsComponent,
      NotFoundPageComponent,
      TopComponent,
      InquiryComponent,
      LoginComponent,
      LogoutComponent,
      SignupComponent,
      PasswordResetComponent,
      ManagerChannelCourseMetaComponent,
      ManagerChannelCourseUsersComponent,
      ContractsComponent,
      PrivacyComponent,
      BusinessContractComponent,
      ManagerAccountsComponent,
      ManagerUsersComponent,
      ManagerChannelCourseRemotesComponent,
      ManagerChannelCourseRemotesReservationsComponent,
      ManagerChannelCourseLivesComponent,
      ManagerChannelCourseLivesReservationsComponent,
      ManagerChannelCourseExaminationCreateComponent,
      ManagerChannelCourseExaminationViewComponent,
      ManagerChannelCourseSurveyViewComponent,
      ManagerChannelCourseRemoveComponent,
      ManagerChannelCreateComponent,
      ManagerChannelTopComponent,
      ManagerChannelCoursesComponent,
      ManagerChannelMetaComponent,
      MyPagePaymentCreditComponent,
      MyPageTopComponent,
      MyPagePaymentHistoryComponent,
      MyPageNotificationsComponent,
      MyPagePasswordComponent,
      MyPagePersonalComponent,
      ChannelTopComponent,
      ChannelCourseTopComponent,
      ChannelCourseRemoteReservationComponent,
      MyPageQuitComponent,
      EmailLinkComponent,
      EmailVerifyComponent,
      ManagerChannelsComponent,
      ManagerChannelCourseCreateComponent,
      ChannelCourseSurveyComponent,
      MyPageAuthSetupComponent,
      ManagerChannelCourseInspectionApplyComponent,
      ManagerChannelCourseInspectionResultComponent,
      ManagerChannelRemoveComponent,
      ManagerChannelPaymentComponent,
      ManagerChannelReportsComponent,
      ManagerChannelPassbookComponent,
      ManagerChannelUsersComponent,
      CategoriesComponent,
      CategoryTopComponent,
      ManagerBusinessComponent,
      ManagerChannelCourseCertComponent,
      ContentsTopComponent,
      ChannelCourseIndexComponent,
      EditUserModalComponent,
      AddUserModalComponent,
      ChatUserComponent,
      ChannelCourseExaminationComponent,
      SystemRequirementsComponent,
      SloComponent,
    ],
    exports: [],
    bootstrap: [AppComponent],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      SharedModule,
      AppRoutingModule,
      AngularFireModule.initializeApp(environment.firebase),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      StoreModule.forRoot({
        sessions: sessionsReducer,
        ui: uiReducer,
        breadcrumb: breadcrumbReducer,
      }, {}),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: "ja",
      })],
    providers: [
      { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
      provideHttpClient(withInterceptorsFromDi()),
      provideFirebaseApp(() => {
        console.log('initializeApp');
        return initializeApp(environment.firebase);
      }),
      provideAuth(() => getAuth()),
    ]
  })
export class AppModule {
  constructor(translate: TranslateService) {
    translate.use("ja");
  }
}
