import {PublicImage} from "./public-image";
export class SelectOption {
  value: string;
  name: string;
}
export enum AccountType {
  general = 'general',
  oem = 'oem',
}
export class Account {
  id?: string
  accountType: AccountType;
  typeOfBusiness: string;
  juridicalPersonality?: string;
  juridicalPersonalityPosition: string;
  representativeTitle: string;
  representativeName: string;
  companyName: string;
  logoImage: PublicImage;
  country?: string;
  zip?: string;
  pref?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  typeOfBusinesses?: string;
  branchName?: string;
  divisionName?: string;
  personInCharge?: string;
  personInChargePhonetic?: string;
  promotionCode?: string;
  phoneNumber?: string;
  partnerId?: string;
  plan?: string;
  email?: string;
  emailSignature: string;
  bankAccount: BankAccount;
  quited?: boolean;
  quitedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  authKey?: string;
  protectByIpAddress?: boolean;
  acceptedIpAddresses?: string[];
  constructor() {
    this.id = '';
    this.accountType = AccountType.general;
    this.juridicalPersonality = '';
    this.juridicalPersonalityPosition = '';
    this.representativeTitle = '';
    this.representativeName = '';
    this.companyName = '';
    this.logoImage = new PublicImage();
    this.emailSignature = '';
    this.country = '';
    this.zip = '';
    this.pref = '';
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.typeOfBusinesses = '';
    this.personInCharge = '';
    this.personInChargePhonetic = '';
    this.promotionCode = '';
    this.phoneNumber = '';
    this.partnerId = '';
    this.plan = '';
    this.quited = false;
    this.quitedAt = null;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = new Date();
    this.updatedBy = null;
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
export class BankAccount {
  bankCode: string;
  bankName: string;
  branchCode: string;
  branchName: string;
  accountNumber?: string;
  transferTargetName: string;
  type: BankAccountTypes;
  constructor() {
    this.bankCode = '';
    this.bankName = '';
    this.branchCode = '';
    this.branchName = '';
    this.accountNumber = '';
    this.transferTargetName = '';
    this.type = BankAccountTypes.savings;
  }
}
export enum BankAccountTypes {
  savings = 'savings', // 普通
  checking = 'checking', // 当座
}
export const bankAccountType: SelectOption[] = [
  {
    value: 'savings',
    name: '普通'
  },
  {
    value: 'checking',
    name: '当座'
  },
]
export enum JuridicalPersonalityPositionValues {
  front = 'front',
  back = 'back',
}
export const JuridicalPersonalityPositions: SelectOption[] = [
  {
    value: 'front',
    name: '前'
  },
  {
    value: 'back',
    name: '後'
  },
  {
    value: 'none',
    name: 'なし'
  },
]
export const JuridicalPersonalities: SelectOption[] = [
  {
    value: '個人事業主',
    name: '個人事業主'
  },
  {
    value: '株式会社',
    name: '株式会社'
  },
  {
    value: '有限会社',
    name: '有限会社'
  },
  {
    value: '合同会社',
    name: '合同会社'
  },
  {
    value: '合資会社',
    name: '合資会社'
  },
  {
    value: '合名会社',
    name: '合名会社'
  },
]
// https://www.tkc.jp/tkcnf/bast/sample/
export const TypeOfBusinesses: SelectOption[] = [
  {
    value: '飲食サービス業',
    name: '飲食サービス業'
  },
  {
    value: '情報通信サービス業',
    name: '情報通信サービス業'
  },
  {
    value: 'コンサルティングサービス業',
    name: 'コンサルティングサービス業'
  },
  {
    value: 'ブライダルサービス業',
    name: 'ブライダルサービス業'
  },
  {
    value: '美容サービス業',
    name: '美容サービス業'
  },
  {
    value: '葬祭サービス業',
    name: '葬祭サービス業'
  },
  {
    value: 'デザインサービス業',
    name: 'デザインサービス業'
  },
  {
    value: '医療・治療サービス業',
    name: '医療・治療サービス業'
  },
  {
    value: '広告サービス業',
    name: '広告サービス業'
  },
  {
    value: '宿泊サービス業',
    name: '宿泊サービス業'
  },
  {
    value: '小売業',
    name: '小売業'
  },
  {
    value: '卸売業',
    name: '卸売業'
  },
  {
    value: '製造業',
    name: '製造業'
  },
  {
    value: '建設業',
    name: '建設業'
  },
  {
    value: 'その他',
    name: 'その他'
  },
]
