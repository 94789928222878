import {Component, OnInit} from '@angular/core';
import {Channel} from "../../models/channel";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {VideoUploaderService} from "../../services/video-uploader/video-uploader.service";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {Subscription} from "rxjs";
import {CoursesService} from "../../services/courses/courses.service";
import {CourseSlideFramesService} from "../../services/course-slide-frames/course-slide-frames.service";
import {CourseOnlineFramesService} from "../../services/course-online-frames/course-online-frames.service";
import {CourseLiveFramesService} from "../../services/course-live-frames/course-live-frames.service";
import {
  CourseExaminationFramesService
} from "../../services/course-examination-frames/course-examination-frames.service";
import {CourseVideoFramesService} from "../../services/course-video-frames/course-video-frames.service";
import {SlideUploaderService} from "../../services/slide-uploader/slide-uploader.service";
import {Course} from "../../models/course";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {FrameTypes} from "../../models/course-frames";
import {CourseSurveyFramesService} from "../../services/course-survey-frames/course-survey-frames.service";
import {Account} from "../../models/account";
import {AccountsService} from "../../services/accounts/accounts.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-course-remove',
  templateUrl: './manager-channel-course-remove.component.html',
  styleUrls: ['./manager-channel-course-remove.component.scss']
})
export class ManagerChannelCourseRemoveComponent implements OnInit {
  channelId: string;
  courseId: string;
  channelSubscription: Subscription;
  currentChannel: Channel = new Channel();
  currentCourse: Course = new Course();
  currentAccount: Account;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private courseVideoFramesService: CourseVideoFramesService,
    private courseSlideFramesService: CourseSlideFramesService,
    private courseOnlineFramesService: CourseOnlineFramesService,
    private courseLiveFramesService: CourseLiveFramesService,
    private courseTestFramesService: CourseExaminationFramesService,
    private courseSurveyFramesService: CourseSurveyFramesService,
    private videoUploaderService: VideoUploaderService,
    private slideUploaderService: SlideUploaderService,
    private courseProgressesService: CourseProgressesService,
    private accountsService: AccountsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await this.channelsService.connectSnapShot(this.channelId);
    this.currentChannel = await this.channelsService.fetch(this.channelId);
    if (!this.currentChannel.slug) {
      await this.router.navigate(['/manager/channels']);
      return;
    }
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    if (!this.currentCourse.name) {
      await this.router.navigate(['/manager/channels']);
      return;
    }
    this.currentAccount = await this.accountsService.fetchAccount(this.currentChannel.accountId);
    const urlState = new UrlState();
    urlState.channelId = this.currentChannel.id;
    urlState.slug = this.currentChannel.slug;
    urlState.courseId = this.currentCourse.id;
    await this.courseProgressesService.loadAllFrames(
      this.currentCourse,
      this.currentAccount.id,
    );
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    this.uiStore.dispatch(switchFalseLoading());
  }
  async back(): Promise<void> {
    await this.router.navigate([`/manager/channel/course/meta/${this.channelId}/${this.courseId}`]);
    return;
  }
  async remove(): Promise<void> {
    if (!window.confirm(this.translateService.instant('本当に削除しますか？'))) {
      return;
    }
    if (this.currentCourse.published) {
      this.toasterService.warning(this.translateService.instant('公開済のコースは削除することが出来ません。'));
      return;
    }
    // 削除開始
    try {
      this.uiStore.dispatch(switchTrueLoading());
      // コンテンツ削除
      this.toasterService.info(this.translateService.instant('コンテンツを削除しています。'));
      for (let frame of this.courseProgressesService.loader) {
        try {
          switch (frame.frameType) {
            case FrameTypes.VideoFrame:
              const videoFrame = await this.courseVideoFramesService.fetch(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId,
              );
              if (videoFrame) {
                await this.videoUploaderService.removeVideo(
                  this.currentChannel.accountId,
                  this.authService.idToken,
                  videoFrame.videoFlame,
                  `course-movies/${this.authService.user.currentAccountId}/${this.channelId}/${this.currentCourse.id}/videos/}`
                );
              }
              await this.courseVideoFramesService.remove(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId,
              );
              break;
            case FrameTypes.SlideFrame:
              const slideFrame = await this.courseSlideFramesService.fetch(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId,
              );
              if (slideFrame) {
                await this.slideUploaderService.removeAll(
                  this.currentChannel.accountId,
                  this.authService.idToken,
                  slideFrame.slideFlame,
                );
              }
              await this.courseSlideFramesService.remove(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId,
              );
              break;
            case FrameTypes.SurveyFrame:
              await this.courseSurveyFramesService.remove(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId,
              );
              break;
            case FrameTypes.ExaminationFrame:
              await this.courseTestFramesService.remove(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId,
              );
              break;
            case FrameTypes.FileFrame:
              break;
            case FrameTypes.CoverFrame:
              break;
            case FrameTypes.LiveFrame:
              await this.courseLiveFramesService.remove(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId
              );            break;
            case FrameTypes.ReportFrame:
              break;
            case FrameTypes.OnlineFrame:
              await this.courseOnlineFramesService.remove(
                this.currentChannel.accountId,
                this.currentCourse.id,
                frame.partId
              );
              break;
            default:
              break;
          }
        } catch (e) {
          continue;
        }
      }
      // コース削除
      this.toasterService.info(this.translateService.instant('コース本体を削除しています。'));
      await this.coursesService.remove(this.currentCourse);
      await this.router.navigate([`/manager/channel/courses/${this.currentChannel.id}`]);
      this.uiStore.dispatch(switchFalseLoading());
      this.toasterService.info(this.translateService.instant('削除しました。'));
      return;
    } catch (e) {
      console.log(e);
      this.toasterService.warning(this.translateService.instant('削除中にエラーが発生しました。処理を中止しました。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
  }
}
